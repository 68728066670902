import { v4 } from "uuid";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface attachmentsState {
  value: {
    id: string;
    caseId: string;
    name: string;
    extension: string;
    isAttachment: boolean;
  }[];
}

const initialState: attachmentsState = {
  value: [
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "1",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "1",
      extension: "pdf",
      isAttachment: true,
    },
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "2",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "2",
      extension: "pdf",
      isAttachment: true,
    },
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "3",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "3",
      extension: "pdf",
      isAttachment: true,
    },
  ],
};

export const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    addAttachment: (
      state,
      action: PayloadAction<{
        id: string;
        caseId: string;
        extension: string;
        name: string;
      }>
    ) => {
      state.value = [...state.value, { ...action.payload, isAttachment: true }];
    },
    editAttachment: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? { ...item, name: action.payload.name }
          : item
      );
    },
    removeAttachment: (state, action: PayloadAction<{ id: string }>) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
  },
});

export const { addAttachment, editAttachment, removeAttachment } =
  attachmentsSlice.actions;

export const selectAttachments = (state: RootState) => state.attachments.value;

export default attachmentsSlice.reducer;
