import * as Yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  firmName: "",
  streetNumber: "",
  unitType: "",
  unitNumber: "",
  city: "",
  country: "",
  phoneNumber: "",
  emailAddress: "",
  state: "",
  postalCode: "",
};

export const validationSchema = Yup.object({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  email: Yup.string().optional(),
  firmName: Yup.string().optional(),
  streetNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  unitNumber: Yup.string()
    .optional()
    .matches(/^(\d+)?$/, "unit number must be a number"),
  city: Yup.string().optional(),
  country: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  emailAddress: Yup.string().optional(),
  state: Yup.string().optional(),
  postalCode: Yup.string().optional(),
});
