import { StyledFormWrap, StyledRowWrap } from "../../components/Input/styled";
import { StyledText } from "../../components/Text/styled";
import React, { useEffect, useState } from "react";
import OTPInput from "../../components/OTPInput";
import { StyledButton } from "../../components/Button/styled";

import { toast } from "react-toastify";

import { addNewData, getAllListData } from "src/services/commonServices";
import { MASTER_ENDPOINT } from "src/utils/Constant";
import { alertOptions } from "src/utils/helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_RESETPASSWORD } from "./../../routes";

const Verification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);
  const [countDown, setCountDown] = useState(60);
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();

  const handleRequestCode = async () => {
    setCountDown(60);
  };
  const reSend = () => {
    getAllListData(
      `${String(MASTER_ENDPOINT.ResendOtp)}?hash=${searchParams.get("hash")}`
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    if (countDown === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countDown]);
  const submitHandler = () => {
    if (otp.length > 6) {
      setIsError(true);
    } else {
      const body = {
        otp: Number(otp),
      };
      addNewData(
        body,
        `${String(MASTER_ENDPOINT.VerifyOtp)}?hash=${searchParams.get("hash")}`
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("OTP Verified Successfully", alertOptions);
            navigate(ROUTE_RESETPASSWORD, {
              state: {
                hashKey: searchParams.get("hash"),
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <StyledFormWrap isFixed>
      <div>
        <StyledText size={36} weight={600} padding="0 0 16px">
          Verify your email
        </StyledText>
        <StyledText
          size={14}
          weight={400}
          padding="0 0 48px 0"
          textAlign={"left"}
        >
          Please check your email for a 6-digit verification code. Enter the
          code below to complete the verification process and gain access to
          your account.
        </StyledText>
        <OTPInput
          value={otp}
          valueLength={6}
          onChange={onChange}
          isError={isError}
        />
        <StyledButton hasBg onClick={submitHandler}>
          Verify Email
        </StyledButton>
        <StyledRowWrap
          justifyContent={"center"}
          gap={"8px"}
          alignItems={"baseline"}
        >
          <StyledText size={16} weight={400} padding="24px 0 0">
            Resend code in:
          </StyledText>
          {countDown === 0 ? (
            <div onClick={reSend}>
              <StyledText
                size={14}
                weight={600}
                padding="24px 0 0"
                onClick={handleRequestCode}
                cursor={"pointer"}
              >
                Resend
              </StyledText>
            </div>
          ) : (
            <StyledText size={16} weight={600} padding="24px 0 0">
              {countDown}s
            </StyledText>
          )}
        </StyledRowWrap>
      </div>
    </StyledFormWrap>
  );
};

export default Verification;
