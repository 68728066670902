import { StyledTab, StyledTabs } from "../styled";
import { TestData } from "../QuestionnaireTestData";
import { Dispatch, SetStateAction } from "react";

const Tabs = ({
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <StyledTabs>
      {TestData.map((item, index) => (
        <StyledTab
          key={item.stepTitle + index}
          style={{ cursor: "pointer" }}
          active={index === currentStep}
          passed={index < currentStep}
          onClick={() => setCurrentStep(index)}
        >
          {item.stepTitle}
        </StyledTab>
      ))}
    </StyledTabs>
  );
};

export default Tabs;
