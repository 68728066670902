import React, { ChangeEvent, useRef } from "react";
import { StyledButton } from "../Button/styled";
import { StyledText } from "../Text/styled";
import { StyledFileUploadButton } from "./styled";

type PropsType = {
  onUploadHandler: (file: any, fileName: string) => void;
};

const FileUploadButton = ({ onUploadHandler }: PropsType) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    inputFile?.current?.click();
  };

  const uploadHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      onUploadHandler(file, file.name);
    }
  };

  return (
    <StyledFileUploadButton>
      <StyledButton hasBg onClick={handleButtonClick} type={"button"}>
        Upload file
      </StyledButton>
      <StyledText>
        Supported files: PDF, Word, PowerPoint. Max size: 3MB
      </StyledText>
      <input
        type={"file"}
        ref={inputFile}
        onChange={uploadHandler}
        accept="application/pdf, .doc, .docx,.ppt, .pptx"
      />
    </StyledFileUploadButton>
  );
};

export default FileUploadButton;
