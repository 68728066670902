import { StyledCommunityBar } from "./styled";
import { CommunityItem } from "./CommunityItem";
import { useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";

const Community = () => {
  const answers = useAppSelector((state) => state.communityAnswers.answers);
  const [copyValue, setCopyValue] = useState({
    value: false,
    index: 0,
  });

  const handleUpdateCopyValue = (text: string, index: number) => {
    setCopyValue({
      value: true,
      index,
    });
    return navigator.clipboard.writeText(text);
  };
  useEffect(() => {
    setCopyValue({
      value: false,
      index: 0,
    });
  }, [answers]);

  return (
    <StyledCommunityBar>
      {answers.map((item: any, index: number) => (
        <CommunityItem
          author={item.author}
          company={item.company}
          contentPreview={item.contentPreview}
          contentFull={item.contentFull}
          // likeCount={item.likeCount}
          // dislikeCount={item.dislikeCount}
          index={index}
          updateCopyValue={handleUpdateCopyValue}
          copiedValue={copyValue}
        />
      ))}
    </StyledCommunityBar>
  );
};

export default Community;
