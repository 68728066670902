/* eslint-disable array-callback-return */
import { StyledTabs, StyledTabContainer } from "./styled";
import { TabsBarData } from "./TabsBarData";
import { Tab } from "./Tab";
import { useState } from "react";

export const TabsBar = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <StyledTabContainer>
      <StyledTabs>
        {TabsBarData.map((item, index) => (
          <Tab
            icon={item.icon}
            active={activeTab === index}
            key={"tab-key" + index}
            setActiveTab={setActiveTab}
            index={index}
          />
        ))}
      </StyledTabs>
      {TabsBarData.map((item, index) => {
        if (index === activeTab) {
          return item.component;
        }
      })}
    </StyledTabContainer>
  );
};

export default TabsBar;
