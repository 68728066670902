import * as CryptoJS from "crypto-js";

const isEncryptLocalStorage = true;
const key = "f74808fb03f94ef8f64350a37918c1b0518c";

export const encrypt = (value: string): string =>
  isEncryptLocalStorage ? CryptoJS.AES.encrypt(value, key).toString() : value;

export const decrypt = (value: string | null): string =>
  value
    ? isEncryptLocalStorage
      ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
      : value
    : "";
