import styled, { css } from "styled-components";

import Checkmark from "src/assets/images/Checkmark.svg";
import Warning from "src/assets/images/Warning.svg";

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<{
  hasError: boolean;
  hasLabel?: boolean;
  bgColor?: string;
}>`
  padding: ${({ hasLabel = true }) =>
    hasLabel ? "17px 16px 10px" : "12px 16px"};
  background: ${({ bgColor = "#162632" }) => bgColor};
  color: #fff;
  border: ${({ hasError }) =>
    hasError ? "1px solid #D13F50" : "1px solid transparent"};
  outline: none;
  margin-bottom: ${({ hasError }) => `${hasError ? 0 : 24}px`};
  width: 100%;
  font-family: LibreFranklin;
  font-size: 16px;

  &[name="createPassword"] {
    margin-bottom: ${({ hasError }) => `${hasError ? 0 : 8}px`};
  }

  &:focus {
    border: ${({ hasError }) => (hasError ? "" : "1px solid #0080FF")};
    outline: ${({ hasError }) => (hasError ? "" : "3px solid #002C69")};
  }

  &::placeholder {
    color: #acbdca;
  }
`;

export const StyledPlaceholder = styled.label<{
  focused: boolean;
  value?: string;
}>`
  position: absolute;
  top: ${({ focused, value }) => (focused || value ? "3px" : "14px")};
  left: 16px;
  color: #acbdca;
  font-size: ${({ focused, value }) => (focused || value ? "12px" : "16px")};
  transition: all 0.3s ease-in-out;
  font-family: LibreFranklin;
  cursor: text;
`;

export const StyledFormWrap = styled.div<{ isFixed?: boolean }>`
  width: 30%;
  max-width: 368px;
  margin: ${({ isFixed }) => (isFixed ? 0 : "0 auto")};

  & > form > label {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 32px;
  }

  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;
export const StyledFormWrap2 = styled(StyledFormWrap)<{
  isFixed?: boolean;
  isSignin?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isSignin ? "center" : "normal")};
  & > form {
    // margin-top: 70px;
    max-width: ${(props) => (props.isSignin ? "368px" : "auto")};
    width: ${(props) => (props.isSignin ? "100%" : "100%")};
  }
`;

export const StyledRowWrap = styled.div<{
  justifyContent?: string;
  gap?: string;
  alignItems?: string;
  background?: string;
  padding?: string;
  margin?: string;
}>`
  display: flex;
  gap: ${({ gap }) => gap || "20px"};
  justify-content: ${({ justifyContent }) =>
    justifyContent || "justify-between"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  background: ${({ background }) => background || "transparent"};
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};
  margin: 0 auto;

  & > form > label {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 32px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 32px;

  & > div {
    width: -webkit-fill-available;
  }
`;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #3c5262;
  background: #162632;
  position: relative;
  cursor: pointer;
  margin: 0;

  &:checked::before {
    content: url(${Checkmark});
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledError = styled.div`
  padding: 4px 40px;
  background: #d13f50;
  color: #fff;
  margin-bottom: 24px;
  font-size: 14px;
  background: #d13f50 url(${Warning}) no-repeat 16px center;
  font-family: LibreFranklin;
`;

export const StyledLabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
