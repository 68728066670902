/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactNode, useState } from "react";
import Tabs from "../Tabs";
import AssembleHeader from "./AssembleHeader";
import { useAppSelector } from "src/hooks";
import { StyledText } from "src/components/Text/styled";
import { StyledArea, StyledAssembleFooter, StyledBtnsWrap } from "../styled";
import { useDrop } from "react-dnd";
import { addNewData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import { initialValues, validationSchema } from "./helpers";
import { useFormik } from "formik";

interface IAssembleArea {
  title?: string;
  hasSearch?: boolean;
  hasTabs?: boolean;
  isDroppable?: boolean;
  children?: ReactNode;
  overflow?: boolean;
  addExhibit?: boolean;
  getData?: any;
  documents?: any;
  isAssemble?: boolean;
  searchData?: any;
}

const AssembleArea: FC<IAssembleArea> = ({
  title,
  hasSearch,
  hasTabs,
  isDroppable,
  addExhibit,
  children,
  overflow,
  getData,
  documents,
  searchData,
}) => {
  const activeTab = useAppSelector((state) => state.activeTab.value);
  // const dispatch = useAppDispatch();
  const params = useParams();
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resetForm();
    setOpenModel(false);
  };
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "doc",
      drop: (item: {
        name: string;
        caseId: string;
        id: string;
        extension: string;
      }) => {
        setLoading(true);
        const { id, extension, caseId, name } = item;
        const bodyData = {
          uploadId: params.id,
          fileId: id,
          documentType: "doc",
          type:
            activeTab === "Attachments"
              ? "attachment"
              : activeTab.toLowerCase(),
        };
        addNewData(bodyData, COMMON_ENDPOINT.assemble)
          .then((res: any) => {
            getData();
            setLoading(false);
            // toast.success("Document added successfully", alertOptions);
          })
          .catch((e: any) => {
            setLoading(false);
            toast.error(e?.error?.message, alertOptions);
          });
        // id && onDropHandler(caseId, id, name, extension, activeTab, dispatch);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [activeTab]
  );
  const submitHandler = () => {
    if (!name && !id) {
      toast.error("Add header", alertOptions);
    } else {
      setLoading(true);
      const bodyData = {
        uploadId: params.id,
        documentType: "header",
        type: "exhibit",
        headingName: name,
        romanName: id,
        // fileId: "06efb9d9-d280-4ac6-982e-0fed7dce6f26",
      };
      addNewData(bodyData, COMMON_ENDPOINT.assembleHeader)
        .then((res) => {
          getData();
          handleClose();
          setLoading(false);
          // window.location.reload();
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  const {
    values: { name, id },
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <StyledArea>
        {hasTabs ? (
          <Tabs setOpenModel={setOpenModel} openModel={openModel} />
        ) : (
          <AssembleHeader
            getData={getData}
            documents={documents}
            title={title}
            hasSearch={hasSearch}
            searchData={searchData}
          />
        )}
        <div
          style={{
            overflowX: "hidden",
            height: "320px",
          }}
        >
          {children}
        </div>
        {isDroppable && (
          <StyledAssembleFooter onDragOver={(e) => e.preventDefault()}>
            <StyledText color="#111" size={14} ref={drop}>
              Drag and drop here
            </StyledText>
          </StyledAssembleFooter>
        )}
      </StyledArea>
      {openModel && (
        <form onSubmit={handleSubmit}>
          <Modal handleClose={handleClose} Prepareinfo={true}>
            <StyledText textAlign="left" size={22} padding="0 0 20px">
              Add new header
            </StyledText>
            <div>
              <Input
                id="id"
                placeholder="Exhibit ID"
                handleBlur={handleBlur}
                handleValue={(e) => setFieldValue("id", e.currentTarget.value)}
                value={id}
                error={touched.id && errors.id}
              />
            </div>
            <div>
              <Input
                id="name"
                placeholder="Name"
                handleBlur={handleBlur}
                handleValue={(e) =>
                  setFieldValue("name", e.currentTarget.value)
                }
                value={name}
                error={touched.name && errors.name}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <button
                style={{
                  background: "transparent",
                  color: "#fff",
                  fontSize: "14px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  border: "1px solid #fff",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "#FFF",
                  fontSize: "14px",
                  padding: "8px 28px",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                type="submit"
              >
                Save
              </button>
            </div>
          </Modal>
        </form>
      )}
    </>
  );
};

export default AssembleArea;
