import * as Yup from "yup";

export const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

export const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required("Input your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must have 8+ characters, including at least one number, lowercase and uppercase letter, and special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords do not match")
    .required("Repeat your password"),
});
