import {
  StyledButtonsWrapper,
  StyledCopyButton,
  StyledMessageText,
} from "./styled";
import { StyledShowMore } from "../Community/styled";
import Icons from "../Icons";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Tooltip, Typography } from "@mui/material";

interface IProps {
  title: string;
  text: string;
  indexValue: number;
  copyOnClickHandle: any;
  textCopied: {
    value: boolean;
    index: number;
  };
}

const CustomTooltip = ({ title, fontSize, children }: any) => {
  const customStyle = {
    fontSize: fontSize,
  };

  return (
    <Tooltip arrow title={<Typography style={customStyle}>{title}</Typography>}>
      {children}
    </Tooltip>
  );
};

const ChatMessage = ({
  title,
  text,
  indexValue,
  copyOnClickHandle,
  textCopied,
}: IProps) => {
  const [showFullContent, setShowFullContent] = useState(false);
  let displayedText = showFullContent ? text : text.substring(0, 90) + "...";

  return (
    <StyledMessageText>
      {!!title && <h3>{title}</h3>}
      {!!title && <span>Use this info:</span>}
      {displayedText}
      <StyledButtonsWrapper>
        <StyledShowMore
          active={showFullContent}
          onClick={() => setShowFullContent((prevState) => !prevState)}
        >
          Show more {Icons.ArrowDownIcon}
        </StyledShowMore>
        {textCopied.value && indexValue === textCopied.index ? (
          <CustomTooltip title={"Copied"} fontSize={"15px"}>
            <CheckIcon sx={{ fill: "greenyellow !important" }} />
          </CustomTooltip>
        ) : (
          <CustomTooltip title={"Copy"} fontSize={"15px"}>
            <StyledCopyButton
              onClick={() => copyOnClickHandle(text, indexValue)}
            >
              {Icons.CopyIcon}
            </StyledCopyButton>
          </CustomTooltip>
        )}
      </StyledButtonsWrapper>
    </StyledMessageText>
  );
};

export default ChatMessage;
