import styled from "styled-components";

export const StyledHeader = styled.header<{ hasAvatar?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 22px 18px 16px;
  padding: 10px 22px 10px 16px;
  background-color: rgb(0, 5, 13);
  z-index: 5;
  &.header {
    position: relative;
    z-index: 1000;
  }

  &.header_home {
    position: absolute;
    top: 5%;
    width: 100%;
    a {
      margin: 0 auto;
    }
  }
`;
