import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface exhibitsState {
  value: { id: string; caseId: string; name: string; extension: string; isExhibit: boolean }[];
}

const initialState: exhibitsState = {
  value: [],
};

export const exhibitsSlice = createSlice({
  name: "exhibits",
  initialState,
  reducers: {
    addExhibit: (
      state,
      action: PayloadAction<{ id: string; caseId: string; name: string; extension: string }>
    ) => {
      state.value = [...state.value, { ...action.payload, isExhibit: true }];
    },
    editExhibit: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? { ...item, name: action.payload.name }
          : item
      );
    },
    removeExhibit: (state, action: PayloadAction<{ id: string }>) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
  },
});

export const { addExhibit, editExhibit, removeExhibit } = exhibitsSlice.actions;

export default exhibitsSlice.reducer;
