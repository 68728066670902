const Icons = {
  WhiteArrowLeft: () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.25 5.25H2.355L5.0775 1.98C5.3426 1.66106 5.29894 1.18759 4.98 0.9225C4.66106 0.657403 4.18759 0.701056 3.9225 1.02C3.9225 1.02 3.9225 1.02 3.9225 1.02L0.1725 5.52H0.1725C0.14727 5.55579 0.12471 5.59339 0.105 5.6325C0.105 5.67 0.105 5.6925 0.0525 5.73H0.0525C0.0185051 5.81599 0.000705825 5.90753 0 6L9.91087e-09 6C0.000706011 6.09247 0.0185054 6.18401 0.0525005 6.27C0.0525005 6.3075 0.0525005 6.33 0.105 6.3675C0.12471 6.40661 0.147271 6.44421 0.1725 6.48L3.9225 10.98C4.06527 11.1514 4.27693 11.2504 4.5 11.25C4.67524 11.2503 4.84507 11.1893 4.98 11.0775C5.29888 10.8131 5.34307 10.3403 5.0787 10.0214C5.0783 10.021 5.0779 10.0205 5.0775 10.02L2.355 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25V5.25Z"
            fill="white"/>
    </svg>
  ),
  BlackArrowRight: () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.75 6.75H9.645L6.9225 10.02C6.6574 10.3389 6.70106 10.8124 7.02 11.0775C7.33894 11.3426 7.8124 11.2989 8.0775 10.98L11.8275 6.48C11.8527 6.44421 11.8753 6.40661 11.895 6.3675C11.895 6.33 11.9325 6.3075 11.9475 6.27C11.9815 6.18401 11.9993 6.09247 12 6V6C11.9993 5.90753 11.9815 5.81599 11.9475 5.73C11.9475 5.6925 11.91 5.67 11.895 5.6325C11.8753 5.59339 11.8527 5.55579 11.8275 5.52L8.0775 1.02V1.02C7.93473 0.848595 7.72307 0.749637 7.5 0.75V0.75C7.32476 0.749658 7.15493 0.81069 7.02 0.9225L7.02 0.922501C6.70112 1.18687 6.65693 1.65968 6.9213 1.97856C6.9217 1.97904 6.9221 1.97952 6.9225 1.98L9.645 5.25H0.75C0.335786 5.25 0 5.58579 0 6C1.81058e-08 6.41422 0.335786 6.75 0.75 6.75L0.75 6.75Z"
            fill="#070D12"/>
    </svg>
  ),
  SuccessIcon: () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.0001 0.666656C6.63628 0.666657 0.666748 6.63619 0.666748 14C0.666748 21.3638 6.63628 27.3333 14.0001 27.3333C21.3639 27.3333 27.3334 21.3638 27.3334 14C27.3334 6.63619 21.3639 0.666657 14.0001 0.666657V0.666656ZM19.735 10.8133L13.6417 18.8133C13.39 19.1403 13.001 19.3323 12.5884 19.3333C12.178 19.3355 11.7895 19.1486 11.535 18.8266L8.28169 14.6799C7.82882 14.0982 7.93328 13.2595 8.51502 12.8066C9.09676 12.3537 9.93548 12.4582 10.3884 13.0399L12.5617 15.8133L17.6017 9.14659C18.0509 8.55749 18.8926 8.44407 19.4817 8.89326C20.0708 9.34245 20.1842 10.1842 19.735 10.7733V10.8133Z"
            fill="#4CAF50"/>
    </svg>
  ),
  DenialIcon: () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.0001 0.666656C6.63628 0.666657 0.666748 6.63619 0.666748 14C0.666748 21.3638 6.63628 27.3333 14.0001 27.3333C21.3639 27.3333 27.3334 21.3638 27.3334 14C27.3334 6.63619 21.3639 0.666657 14.0001 0.666657V0.666656ZM17.6159 15.7199C18.1388 16.2385 18.1422 17.0827 17.6237 17.6055C17.6211 17.6081 17.6185 17.6107 17.6159 17.6132C17.0974 18.1361 16.2532 18.1395 15.7303 17.621C15.7277 17.6184 15.7252 17.6158 15.7226 17.6132L14.0026 15.8799L12.2826 17.6132C11.764 18.1361 10.9198 18.1395 10.397 17.621C10.3944 17.6184 10.3918 17.6158 10.3893 17.6132C9.86644 17.0947 9.86298 16.2505 10.3815 15.7276C10.3841 15.7251 10.3867 15.7225 10.3893 15.7199L12.1226 13.9999L10.3893 12.2799C9.86644 11.7571 9.86644 10.9094 10.3893 10.3866C10.9121 9.86375 11.7598 9.86375 12.2826 10.3866L14.0026 12.1199L15.7226 10.3866C16.2454 9.86375 17.0931 9.86375 17.6159 10.3866C18.1388 10.9094 18.1388 11.7571 17.6159 12.2799L15.8826 13.9999L17.6159 15.7199Z"
            fill="#D32F2F"/>
    </svg>
  ),
  BackArrow: () => (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clip-rule="evenodd"
            d="M10.6665 4.33331H2.75984L5.17984 1.42664C5.41548 1.14314 5.37668 0.722282 5.09317 0.486642C4.80966 0.251 4.38881 0.289802 4.15317 0.573308V0.573308L0.819837 4.57331C0.797411 4.60512 0.777357 4.63855 0.759837 4.67331C0.759837 4.70664 0.759837 4.72664 0.713171 4.75998H0.713171C0.682953 4.83641 0.667131 4.91778 0.666504 4.99998L0.666504 4.99998C0.667131 5.08217 0.682953 5.16354 0.713171 5.23998C0.713171 5.27331 0.713171 5.29331 0.759838 5.32664C0.777357 5.3614 0.797411 5.39483 0.819838 5.42664L4.15317 9.42664C4.28008 9.579 4.46822 9.66696 4.6665 9.66664C4.82227 9.66695 4.97323 9.6127 5.09317 9.51331C5.37662 9.27832 5.4159 8.85804 5.1809 8.57459C5.18055 8.57416 5.18019 8.57373 5.17984 8.57331L2.75984 5.66664H10.6665C11.0347 5.66664 11.3332 5.36816 11.3332 4.99997C11.3332 4.63178 11.0347 4.33331 10.6665 4.33331V4.33331Z"
            fill="white"/>
    </svg>
  ),
  RatingPositiveIcon: (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00033 13.6666H12.0003C12.5537 13.6666 13.027 13.3333 13.227 12.8533L15.2403 8.15331C15.3003 7.99998 15.3337 7.83998 15.3337 7.66665V6.33331C15.3337 5.59998 14.7337 4.99998 14.0003 4.99998H9.79366L10.427 1.95331L10.447 1.73998C10.447 1.46665 10.3337 1.21331 10.1537 1.03331L9.44699 0.333313L5.05366 4.72665C4.81366 4.96665 4.66699 5.29998 4.66699 5.66665V12.3333C4.66699 13.0666 5.26699 13.6666 6.00033 13.6666ZM6.00033 5.66665L8.89366 2.77331L8.00033 6.33331H14.0003V7.66665L12.0003 12.3333H6.00033V5.66665ZM0.666992 5.66665H3.33366V13.6666H0.666992V5.66665Z"
        fill="#00C853"/>
    </svg>
  ),
  RatingNegativeIcon: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 1.33335H3.99967C3.44634 1.33335 2.97301 1.66669 2.77301 2.14669L0.759675 6.84669C0.699675 7.00002 0.666342 7.16002 0.666342 7.33335V8.66669C0.666342 9.40002 1.26634 10 1.99968 10H6.20634L5.57301 13.0467L5.55301 13.26C5.55301 13.5334 5.66634 13.7867 5.84634 13.9667L6.55301 14.6667L10.9463 10.2734C11.1863 10.0334 11.333 9.70002 11.333 9.33335V2.66669C11.333 1.93335 10.733 1.33335 9.99967 1.33335ZM9.99967 9.33335L7.10634 12.2267L7.99967 8.66669H1.99968V7.33335L3.99967 2.66669H9.99967V9.33335ZM15.333 9.33335H12.6663V1.33335H15.333V9.33335Z"
        fill="#FF1744"/>
    </svg>
  ),
  CopyIcon: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10 4H8V1.78V1.78001C7.99635 0.798467 7.20156 0.00366667 6.22001 2.38515e-10H1.78V0C0.798453 0.00365568 0.00365337 0.798453 3.89191e-08 1.78V6.22L0 6.21999C0.00364993 7.20154 0.79844 7.99634 1.77999 8H4V10C4 11.1046 4.89543 12 6 12H10C11.1046 12 12 11.1046 12 10V6C12 4.89544 11.1046 4 10 4V4ZM4 6V6.66667H1.78C1.53331 6.66667 1.33333 6.46669 1.33333 6.22V1.78C1.33333 1.53332 1.53331 1.33334 1.78 1.33334H6.22C6.46669 1.33334 6.66667 1.53332 6.66667 1.78V4H6C4.89543 4 4 4.89544 4 6Z"
            fill="white"/>
    </svg>
  ),
  ArrowDownIcon: (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.99985 5.91662V5.91662C5.22135 5.9179 5.43425 5.83094 5.59151 5.67495L8.92485 2.34162V2.34162C9.25161 2.01485 9.25161 1.48505 8.92485 1.15828C8.59808 0.831516 8.06828 0.831516 7.74151 1.15828L4.99985 3.91662L2.24985 1.26662V1.26662C1.94209 0.924411 1.4152 0.896482 1.07299 1.20424C0.730781 1.51199 0.702852 2.03889 1.01061 2.3811C1.0382 2.41177 1.06802 2.44036 1.09985 2.46662L4.43318 5.68328V5.68328C4.58558 5.83021 4.78818 5.91364 4.99985 5.91662L4.99985 5.91662Z"
            fill="white"/>
    </svg>
  ),
  ChatIcon: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.60492 17.2112C6.14321 17.5321 5.5281 17.1832 5.5281 16.6005V14.1896C2.69774 13.3869 0.666992 10.693 0.666992 7.55159C0.666992 3.74771 3.62261 0.666687 7.26421 0.666687H10.7364C14.378 0.666687 17.3337 3.74771 17.3337 7.55159C17.3337 11.3555 14.378 14.4365 10.7364 14.4365H10.5976L6.60492 17.2112Z"
            fill="white"/>
    </svg>
  ),
  CommunityIcon: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.00033 0.666656C4.39795 0.666657 0.666992 4.39761 0.666992 8.99999C0.666992 13.6024 4.39795 17.3333 9.00033 17.3333C13.6027 17.3333 17.3337 13.6024 17.3337 8.99999C17.3337 4.39761 13.6027 0.666657 9.00033 0.666657V0.666656ZM9.00033 2.33332H9.00033C9.50247 2.3366 10.0027 2.39529 10.492 2.50832L10.492 2.50832C10.3471 2.83532 10.1239 3.12154 9.84199 3.34166C9.65866 3.48332 9.45866 3.59999 9.25866 3.72499V3.72499C8.62551 4.03672 8.09168 4.51861 7.71699 5.11666H7.71699C7.30652 5.97213 7.12833 6.92054 7.20033 7.86666C7.20033 8.99999 7.20033 9.66666 6.40866 10.2583C5.26699 11.15 3.52533 10.65 2.44199 10.2L2.44199 10.2C2.37117 9.80389 2.33492 9.40238 2.33366 9V9C2.33366 5.3181 5.31843 2.33333 9.00033 2.33333L9.00033 2.33332ZM13.0753 14.2667C12.9312 13.9339 12.7554 13.6158 12.5503 13.3167C12.4587 13.1833 12.367 13.05 12.2837 12.9083C11.9587 12.3417 12.0753 12.075 12.6003 11.2417L12.6837 11.1C13.0218 10.4799 13.1886 9.78094 13.167 9.07499V9.07495C13.1662 8.79545 13.1913 8.51648 13.242 8.24162C13.3753 7.63329 14.667 7.46662 15.467 7.40829C16.128 9.97811 15.1898 12.6942 13.0837 14.3083L13.0753 14.2667Z"
            fill="white"/>
    </svg>
  ),
  EyeIcon: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16.4016 8.62503C15.9216 7.79253 13.2816 3.61503 8.79657 3.75003C4.64907 3.85503 2.24907 7.50003 1.59657 8.62503C1.4626 8.85708 1.4626 9.14298 1.59657 9.37503C2.06907 10.1925 4.59657 14.25 9.01407 14.25H9.20157C13.3491 14.145 15.7566 10.5 16.4016 9.37503C16.5355 9.14298 16.5355 8.85708 16.4016 8.62503ZM9.16309 12.7501C5.93059 12.8251 3.82309 10.0576 3.16309 9.00009C3.91309 7.79259 5.87059 5.32509 8.87059 5.25009C12.0881 5.16759 14.2031 7.94259 14.8706 9.00009C14.0981 10.2076 12.1631 12.6751 9.16309 12.7501ZM8.99907 6.37503C7.54932 6.37503 6.37407 7.55028 6.37407 9.00003C6.37407 10.4498 7.54932 11.625 8.99907 11.625C10.4488 11.625 11.6241 10.4498 11.6241 9.00003C11.6241 7.55028 10.4488 6.37503 8.99907 6.37503ZM8.99907 10.125C8.37775 10.125 7.87407 9.62135 7.87407 9.00003C7.87407 8.37871 8.37775 7.87503 8.99907 7.87503C9.62039 7.87503 10.1241 8.37871 10.1241 9.00003C10.1241 9.62135 9.62039 10.125 8.99907 10.125Z"
        fill="white"/>
    </svg>

  )
}

export default Icons;
