import { useState } from "react";
import Icons from "../../../components/Icons";
import {
  StyledHead,
  StyledNumber,
  StyledText,
  StyledTitle,
  StyledButtonsWrapper,
  StyledShowMore,
} from "../styled";

const QuestionnaireHead = ({ data, index }: { data: any; index: number }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  let displayedText = showFullContent
    ? data.description
    : data.description.substring(0, 900) + "...";
  if (data) {
    return (
      <StyledHead>
        <StyledNumber>Issue #{index}</StyledNumber>
        <StyledTitle>{data.title}</StyledTitle>
        <StyledText>{displayedText}</StyledText>
        <StyledButtonsWrapper>
          {data.description.length ? (
            <StyledShowMore
              active={showFullContent}
              onClick={() => setShowFullContent((prevState) => !prevState)}
            >
              Show more {Icons.ArrowDownIcon}
            </StyledShowMore>
          ) : (
            ""
          )}
        </StyledButtonsWrapper>
      </StyledHead>
    );
  }

  return <></>;
};

export default QuestionnaireHead;
