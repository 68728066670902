import { FC, useState } from "react";
import { StyledText } from "src/components/Text/styled";
import {
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionWrapper,
} from "./styled";

interface IAccordionItem {
  title: string;
  content: any;
}

const AccordionItem: FC<IAccordionItem> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExhibitSubOpen, seIisExhibitSubOpen] = useState(false);
  return (
    <StyledAccordionWrapper>
      <StyledAccordionHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <StyledText size={14} weight={600} color="#111" cursor="pointer">
          {title}
        </StyledText>
      </StyledAccordionHeader>
      <StyledAccordionContent isOpen={isOpen}>
        {Array.isArray(content) && content?.length ? (
          content?.map(
            ({ fileName, assembleExhibitChildDocs, romanName, heading }) => (
              <StyledText
                className={
                  title !== "Exhibit" && !assembleExhibitChildDocs.length
                    ? "cross_icon"
                    : ""
                }
                size={14}
                color="#111"
                textAlign="left"
                padding="0 0 8px"
              >
                {title === "Exhibit" ? (
                  Array.isArray(assembleExhibitChildDocs) &&
                  assembleExhibitChildDocs.length ? (
                    assembleExhibitChildDocs.map(({ fileName }) => (
                      <>
                        <StyledAccordionHeader
                          isOpen={isExhibitSubOpen}
                          onClick={() => seIisExhibitSubOpen(!isExhibitSubOpen)}
                        >
                          <StyledText
                            size={14}
                            weight={600}
                            color="#111"
                            cursor="pointer"
                          >
                            {`[${romanName}] ${heading}`}
                          </StyledText>
                        </StyledAccordionHeader>
                        <StyledAccordionContent isOpen={isExhibitSubOpen}>
                          <StyledText
                            className={"cross_icon"}
                            size={14}
                            weight={600}
                            color="#111"
                            cursor="pointer"
                            textAlign="left"
                          >
                            {fileName}
                          </StyledText>
                        </StyledAccordionContent>
                      </>
                    ))
                  ) : (
                    <StyledText
                      className={!heading ? "cross_icon" : ""}
                      size={14}
                      weight={600}
                      color="#111"
                      cursor="pointer"
                      textAlign="left"
                    >
                      {fileName}
                    </StyledText>
                  )
                ) : (
                  fileName
                )}
              </StyledText>
            )
          )
        ) : (
          <StyledText
            className="cross_icon"
            size={14}
            color="red"
            textAlign="left"
          >
            No Record(s) found.
          </StyledText>
        )}
      </StyledAccordionContent>
    </StyledAccordionWrapper>
  );
};

export default AccordionItem;
