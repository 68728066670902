import styled from "styled-components";

const headerHeight = "88px";

export const StyledChatAreaContainer = styled.div`
  // position: fixed;
  // top: 88px;
  // right: 2.1vw;
  width: 13.5vw;
  min-width: 240px;
  height: calc(100vh - ${headerHeight});
  height: calc(100vh - 67px);
  font-family: Libre Franklin, sans-serif;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  align-items: flex-end;
  gap: 6px;
  background: #162632;
  border-top: 1px solid #1f323f;
  border-left: 1px solid #1f323f;
  // z-index: 1;
  overflow-y: scroll;
`;

export const StyledMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 208px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const StyledMessageText = styled.div`
  & h3 {
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 20px;
  }

  & span {
    display: block;
    font-style: italic;
  }

  color: #fff;
  padding: 8px 12px;
  background: #12356b;
  border: 1px solid #124082;
  border-radius: 8px 8px 0 8px;
`;
export const StyledButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

export const StyledCopyButton = styled.div`
  position: relative;
  cursor: pointer;
  top: 4px;
  width: 24px;
  height: 24px;
  background: #124082;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;

  &:hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
`;
