import { FC } from "react";
import Header from "../Header";
import { StyledContainer } from "./styled";

const MainLayout: FC<{
  Component: FC;
  hasAvatar?: boolean;
  hasBg?: boolean;
}> = ({ Component, hasAvatar, hasBg = true }) => {
  return (
    <StyledContainer hasBg={hasBg}>
      <Header hasAvatar={hasAvatar} />
      <Component />
    </StyledContainer>
  );
};

export default MainLayout;
