import instance from "../services/api";

interface PostRequestProps {
  url: string;
  data?: any;
  token?: string;
}

interface GetRequestProps {
  url: string;
  token?: string;
}
const postRequest = async (props: PostRequestProps, options?: any) => {
  return await instance
    .post(process.env.REACT_APP_APIURL + props.url, props.data, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response) {
        throw error && error.response && error.response.data
          ? error.response.data
          : "";
      }
    });
};
const deleteRequest = async (props: PostRequestProps) => {
  return await instance
    .delete(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : "";
    });
};
const putRequest = async (props: PostRequestProps) => {
  return await instance
    .put(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : "";
    });
};
const getRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(
      process.env.REACT_APP_APIURL + props.url,
      props.token !== "" && {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
        ...options,
      }
    )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        return {
          data: response,
        };
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : "";
    });
};

const getPdfRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(process.env.REACT_APP_APIURL + props.url, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : "";
    });
};
export { postRequest, getPdfRequest, getRequest, deleteRequest, putRequest };
