import Storage from "../../utils/Storage";

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILES_FAIL,
  USER_PROFILES_SUCCESS,
  USER_REFRESH_TOKEN,
} from "../types/UserTypes";

const user = Storage.isTokenValidDetails();
export interface UserState {
  loading?: boolean;
  error?: any;
  userInfo?: any;
  isLoggedIn?: boolean;
  sideMenu?: any;
  profile?: any;
}

export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: UserState = {
  error: null,
  isLoggedIn: Storage.isTokenValidDetails() ? true : false,
  loading: false,
  profile: {},
  sideMenu: {},
  userInfo: Storage.isTokenValidDetails()
    ? Storage.isTokenValidDetails()
    : null,
};

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_LOGOUT:
      return {
        error: null,
        isLoggedIn: false,
        loading: false,
        sideMenu: {},
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };

    case USER_PROFILES_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case USER_PROFILES_FAIL:
      return {
        error: action.payload,
        profile: {},
      };
    default:
      return state;
  }
};
