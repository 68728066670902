import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { TempFilesType } from "src/components/EvidenceForm/EvidenceForm";

export interface IQuestion {
  name: string;
  question: string;
  answer:
    | string
    | { fileUrl?: string; fileName: string; description?: string }[];
}

interface answersState {
  value: { id: number; questions: IQuestion[] }[];
}

const initialState: answersState = {
  value: [
    {
      id: 0,
      questions: [
        {
          name: "hasAdditionalFacts",
          question:
            "Are there facts and additional facts to respond to the issue?",
          answer: "",
        },
        {
          name: "files",
          question:
            "What evidence do you have to support the facts or additional facts?",
          answer: [],
        },
      ],
    },
    {
      id: 1,
      questions: [
        {
          name: "hasWitness",
          question: "Do you have a witness to attest to facts?",
          answer: "",
        },
        {
          name: "firstName",
          question: "What is the first name of the witness ?",
          answer: "",
        },
        {
          name: "lastName",
          question: "What is the last name of the witness ?",
          answer: "",
        },
        {
          name: "phone",
          question: "What is the witness phone?",
          answer: "",
        },
        {
          name: "email",
          question: "What is the witness email?",
          answer: "",
        },
        {
          name: "primaryTextarea",
          question: "Please describe relative background of witness?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question: "What is the witness testimony or statement?",
          answer: "",
        },
      ],
    },
    {
      id: 2,
      questions: [
        {
          name: "hasExpert",
          question:
            "Do you need an expert to provide analysis to the facts or law?",
          answer: "",
        },
        {
          name: "firstName",
          question: "What is the first name of the expert ?",
          answer: "",
        },
        {
          name: "lastName",
          question: "What is the last name of the expert?",
          answer: "",
        },
        {
          name: "phone",
          question: "What is the experts' phone?",
          answer: "",
        },
        {
          name: "email",
          question: "What is the experts' email?",
          answer: "",
        },
        {
          name: "primaryTextarea",
          question: "What is the biography of expert?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question:
            "What is expert's analysis in response to the issue in the RFE, NOID, NOIR, Decision?",
          answer: "",
        },
      ],
    },
    {
      id: 3,
      questions: [
        {
          name: "primaryTextarea",
          question:
            "What is/are the relevant regulation(s) that apply(ies) to the issue?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question:
            "The plain language of the regulation(s) support(s) the present case as follows",
          answer: "",
        },
      ],
    },
    {
      id: 4,
      questions: [
        {
          name: "primaryTextarea",
          question: "How do policy memos apply to the issue?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question:
            "The plain language of the regulation(s) support(s) the present case as follows",
          answer: "",
        },
      ],
    },
    {
      id: 5,
      questions: [
        {
          name: "primaryTextarea",
          question: "How do AAO/BALCA decisions apply to the issue?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question:
            "The plain language of the regulation(s) support(s) the present case as follows",
          answer: "",
        },
      ],
    },
    {
      id: 6,
      questions: [
        {
          name: "primaryTextarea",
          question: "How does Federal Caselaw apply to the issue?",
          answer: "",
        },
        {
          name: "secondaryTextarea",
          question:
            "The plain language of the regulation(s) support(s) the present case as follows",
          answer: "",
        },
      ],
    },
    {
      id: 7,
      questions: [
        {
          name: "summary",
          question: "Response Generator",
          answer: "",
        },
      ],
    },
  ],
};

export const answersSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    editAnswer: (
      state,
      action: PayloadAction<{
        beId?: any;
        id: number;
        sectionMappingId?: any;
        name: string;
        answer: string | TempFilesType[];
      }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              questions: item.questions.map((i) =>
                i.name === action.payload.name
                  ? {
                      ...i,
                      answer: action.payload.answer,
                      beId: action.payload.beId ? action.payload.beId : "",
                      sectionMappingId: action.payload.sectionMappingId
                        ? action.payload.sectionMappingId
                        : "",
                    }
                  : i
              ),
            }
          : item
      );
    },
    clearAnswer: () => initialState,
    removeAnswerFile: (
      state,
      action: PayloadAction<{
        beId?: any;
        sectionMappingId?: any;
        id: number;
        name: string;
        files: { fileUrl?: string; fileName: string; description?: string }[];
      }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              questions: item.questions.map((i) =>
                i.name === action.payload.name
                  ? {
                      ...i,
                      answer: action.payload.files,
                    }
                  : i
              ),
            }
          : item
      );
    },
  },
});

export const { editAnswer, removeAnswerFile, clearAnswer } =
  answersSlice.actions;

export const selectAnswers = (state: RootState) => state.answers.value;

export default answersSlice.reducer;
