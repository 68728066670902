import { FC } from "react";
import { IQuestion } from "src/slices/answersSlice";
import { TempFilesType } from "src/components/EvidenceForm/EvidenceForm";
import { StyledQuestion, StyledRow } from "./styled";
import { StyledText } from "src/components/Text/styled";

interface IFilesTable {
  title: IQuestion;
  tableHeader: string;
  files: TempFilesType[];
  num: number;
}

const FilesTable: FC<IFilesTable> = ({ title, tableHeader, files, num }) => {
  const { question, answer } = title;

  return (
    <StyledQuestion padding={files.length ? "0 0 16px" : "0"}>
      <div>
        <StyledText
          weight={600}
          textAlign="left"
          lineHeight={24}
          padding="0 0 4px"
        >
          {question}
        </StyledText>
        {typeof answer === "string" && (
          <StyledText
            color="#ACBDCA"
            lineHeight={21}
            textAlign="left"
            padding="0 16px 16px 0"
          >
            {answer !== ""
              ? answer === "true"
                ? "Yes"
                : answer === "false"
                ? "No"
                : answer
              : "-"}
          </StyledText>
        )}
      </div>
      <StyledText
        weight={600}
        textAlign="left"
        lineHeight={24}
        padding="0 16px 16px 0"
      >
        {tableHeader}
      </StyledText>
      <ul>
        {files.length ? (
          <StyledRow isHeader>
            <StyledText
              weight={500}
              size={12}
              color="#ACBDCA"
              padding="11px 16px"
              textAlign="left"
            >
              EVIDENCE FILE
            </StyledText>
            <StyledText
              weight={500}
              size={12}
              color="#ACBDCA"
              padding="11px 16px 11px"
            >
              EVIDENCE DESCRIPTION
            </StyledText>
          </StyledRow>
        ) : (
          <StyledText
            color="#ACBDCA"
            lineHeight={21}
            textAlign="left"
            padding="0 0 16px"
          >
            -
          </StyledText>
        )}
        {files.map(({ fileName, description }, i) => (
          <li>
            <StyledRow hasBorderBottom={i + 1 !== files.length}>
              <StyledText size={12} padding="20px 16px" textAlign="left">
                {fileName}
              </StyledText>
              <StyledText size={12} padding="20px 16px">
                {description}
              </StyledText>
            </StyledRow>
          </li>
        ))}
      </ul>
    </StyledQuestion>
  );
};

export default FilesTable;
