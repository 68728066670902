// import axios from "axios";
// import fileDownload from "js-file-download";
import { Color, PDFDocument } from "pdf-lib";
// import { ICoordinates, IIssue } from "src/types";

// function base64ToArrayBuffer(data: any) {
//   // const base64WithoutPrefix = data.substr(
//   //   "data:application/pdf;base64,".length
//   // );
//   // const binaryString = atob(base64WithoutPrefix);
//   const bytes = new Uint8Array(data.length);
//   for (let i = 0; i < data.length; i++) {
//     bytes[i] = data.charCodeAt(i);
//   }
//   return bytes.buffer;
// }

export async function modifyPdf(
  setPdfDataUri: (pdfDataUriValue: string) => void,
  rects: Array<[number, number, number, number, number, Color | undefined]>,
  pdfString: string
  // pageCoordinates: ICoordinates,
  // pdfPath: string | null,
  // canvasCoordinates: ICoordinates | null,
  // scale: any,
  // issuesList: Array<IIssue>,
  // setIssuesList: (issuesList: Array<IIssue>) => void,
  // color: RGB | null,
  // setActivation: any,
  // operation: any
): Promise<void> {
  const existingPdfBytes = pdfString;
  if (existingPdfBytes) {
    const mergedPdf = await PDFDocument.create();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const copiedPages = await mergedPdf.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices()
    );
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
    const pages = mergedPdf.getPages();
    rects.forEach(async (el) => {
      await pages[el[0] - 1].drawRectangle({
        x: el[1],
        y: el[2],
        width: el[3],
        height: el[4],
        borderColor: el[5],
        borderWidth: 2,
      });
    });

    const pdfDataUriValue = await mergedPdf.saveAsBase64({ dataUri: true });
    setPdfDataUri(pdfDataUriValue);
  }

  // if (operation.type === "deletion") {
  //   setIssuesList([
  //     ...issuesList.filter((el) => el.number !== operation.payload),
  //   ]);
  // }

  // if (pageCoordinates && canvasCoordinates) {
  //   const { minX, maxX } = pageCoordinates;
  //   const { minY, maxY } = canvasCoordinates;

  //   axios
  //     .post(
  //       "http://ec2-34-205-203-211.compute-1.amazonaws.com:8000/select_text",
  //       {
  //         bounding_box: {
  //           x0: minX,
  //           y0: minY / scale,
  //           x1: maxX,
  //           y1: maxY / scale,
  //         },
  //         document_path: pdfPath,
  //         page_number: 1,
  //       }
  //     )
  //     .then((res) => {
  //       if (operation.type === "assignToIssue") {
  //         setIssuesList([
  //           ...issuesList.map((el) =>
  //             el.number === operation.payload && el.isIssue
  //               ? {
  //                   ...el,
  //                   text: `${el.text} ${res.data.selected_text}`,
  //                 }
  //               : el
  //           ),
  //         ]);
  //       } else if (operation.type === "addNewSubissue") {
  //         setIssuesList([
  //           ...issuesList.map((el) =>
  //             el.number === operation.payload && el.isIssue
  //               ? {
  //                   ...el,
  //                   subissues: el.subissues
  //                     ? [
  //                         ...el.subissues,
  //                         {
  //                           number: el.subissues.length + 1,
  //                           isIssue: false,
  //                           toIssue: operation.payload,
  //                           title: "Subissue Title",
  //                           text: res.data.selected_text,
  //                           progress: 0,
  //                           color,
  //                         },
  //                       ]
  //                     : [
  //                         {
  //                           number: 1,
  //                           isIssue: false,
  //                           toIssue: operation.payload,
  //                           title: "Subissue Title",
  //                           text: res.data.selected_text,
  //                           progress: 0,
  //                           color,
  //                         },
  //                       ],
  //                 }
  //               : el
  //           ),
  //         ]);
  //       } else if (!operation.type || operation.type === "addNewIssue") {
  //         setIssuesList([
  //           ...issuesList,
  //           {
  //             number: issuesList.filter((el) => el.isIssue).length + 1,
  //             isIssue: true,
  //             title: "Issue Title",
  //             text: res.data.selected_text,
  //             progress: 0,
  //             color,
  //           },
  //         ]);
  //       }

  //       setActivation(false);
  //     });
  // }
}
