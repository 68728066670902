import { FC, ReactElement, useEffect, useState } from "react";
import {
  StyledDocumentWorkspace,
  StyledInput,
  StyledLabel,
  StyledTextarea,
  StyledWorkspaceContainer,
} from "./styled";
import { StyledIcon } from "../DocumentReview/styled";
import Paper from "./Paper";
// import { inputsData } from "./inputs";
import ArrowBackward from "../../assets/images/ArrowBackward.svg";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TabsBar from "../TabsBar";
import Icons from "../Icons";
import { addNewData, getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { PreparerInfo } from "./PreparerInfo";
import fileDownload from "js-file-download";
import { ROUTE_RESPONDER_ASSEMBLE } from "src/routes";
import { toast } from "react-toastify";

const DocumentWorkspace: FC = () => {
  const [searchParams]: any = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState<
    Array<{
      answer: string;
      label: string;
      id: number;
      textArea: boolean;
      questionId: string;
    }>
  >([]);
  const [fileblob, setFileblob] = useState("");
  const Params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState<number | null>(null);
  const [modelOpen, setmodelOpen] = useState<boolean>(false);
  const text = (number: number): ReactElement<HTMLLabelElement> => (
    <label
      htmlFor={`${number}`}
      style={{
        cursor: "pointer",
        background: clicked === number ? "yellow" : "none",
      }}
      onClick={() => setClicked(number)}
    >
      {inputs.find((e: any) => e.id === number)?.answer ? (
        <span style={{ color: "blue" }} data-type="text">
          {inputs.find((e: any) => e.id === number)?.answer}
        </span>
      ) : (
        <span style={{ color: "red" }} data-type="text">
          {inputs.find((e: any) => e.id === number)?.label}
        </span>
      )}
    </label>
  );

  useEffect(() => {
    document.onclick = (e: MouseEvent) => {
      (e.target as HTMLElement).dataset.type !== "text" && setClicked(null);
    };
  }, []);
  const findData = (id: any) => {
    const newData = inputs.find((en) => en.id === id);
    return newData?.answer || ("" as any);
  };
  const bodyData = async () => {
    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.data.templateValue === "p1ExpertModel"
    ) {
      return {
        expertTitle: await findData(2),
        expertCompany: await findData(3),
        expertOpinion: await findData(5),
        expertEmail: await findData(6),
        expertName: await findData(1),
        expertBio: await findData(4),
      };
    } else {
      return {
        benefeciaryFirstName: await findData(1),
        benefeciaryLastName: await findData(2),
        petitionerCompany: await findData(3),
        receiptNumber: await findData(4),
        documetTitle: await findData(5),
        requestType: await findData(6),
        issueOn: await findData(7),
        issueDue: await findData(8),
        responseEvidence: await findData(9),
        responseIssue: await findData(10),
        factsStatement: await findData(11),
        witnessName: await findData(12),
        witnessBackground: await findData(13),
        witnessStatement: await findData(14),
        nameExpert: await findData(15),
        bioExpert: await findData(16),
        expertAnalysisIssue: await findData(17),
        rags: await findData(18),
        applicationRegs: await findData(19),
        applyCaselaw: await findData(20),
        applyAgencyDecisions: await findData(21),
        applyAgencyPolicy: await findData(22),
        applySummationIssueArgument: await findData(23),
        attorneyFirstName: await findData(24),
        attorneyLastName: await findData(25),
        lawFirmName: await findData(26),
        attorneyStreetNumberAndName: await findData(27),
        attorneyUnitType: await findData(28),
        attorneyUnitNumber: await findData(29),
        attorneyCity: await findData(30),
        attorneyState: await findData(34),
        attorneyPostalCode: await findData(35),
        attorneyCountry: await findData(31),
        attorneyPhone: await findData(32),
        attorneyEmail: await findData(33),
      };
    }
  };

  const buildDocument = async () => {
    setLoading(true);
    const localvar = await bodyData();
    addNewData(
      {
        uploadId: Params.id,
        documentTemplateId: location?.state?.data?.id,
        issueId: searchParams?.get("issueId"),
        ...(searchParams?.get("subIssueId")
          ? {
              subIssueId:
                searchParams?.get("subIssueId") &&
                searchParams?.get("subIssueId"),
            }
          : {}),
        ...localvar,
      },
      COMMON_ENDPOINT.buildDocument,
      {
        responseType: "blob",
      }
    )
      .then((res: any) => {
        fileDownload(res, location.state.data.fileName);
        setLoading(false);
        navigate(
          `${ROUTE_RESPONDER_ASSEMBLE}/${id}?issueId=${
            searchParams?.get("issueId") ? searchParams?.get("issueId") : ""
          }${
            searchParams?.get("subIssueId")
              ? `&subIssueId=${searchParams?.get("subIssueId")}`
              : ""
          }`
        );
      })
      .catch((err: any) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const onBlurUpdate = (id: any) => {
    const particularAnswerUpdate = inputs.filter((re) => re.id === id);
    if (particularAnswerUpdate) {
      addNewData(
        {
          uploadId: Params.id,
          documentTemplateId: location?.state?.data?.id,
          form: particularAnswerUpdate,
          issueId: searchParams?.get("issueId"),
          ...(searchParams?.get("subIssueId")
            ? {
                subIssueId:
                  searchParams?.get("subIssueId") &&
                  searchParams?.get("subIssueId"),
              }
            : {}),
        },
        COMMON_ENDPOINT.attorneyDetails
      )
        .then((res: any) => {
          // console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const PreviewDocument = async () => {
    const localvar = await bodyData();
    try {
      setLoading(true);
      const res = await addNewData(
        {
          uploadId: Params.id,
          documentTemplateId:
            location &&
            location.state &&
            location.state.data &&
            location.state.data.id
              ? location.state.data.id
              : null,
          ...localvar,
        },
        COMMON_ENDPOINT.previewDocument
      );
      setFileblob(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const ArrowBackwardIcon = (): ReactElement<HTMLImageElement> => (
    <StyledIcon src={ArrowBackward} margin="0 6px 0 0" />
  );
  const getList = () => {
    if (
      Params.id &&
      location &&
      location.state &&
      location.state.data &&
      location.state.data.id
    ) {
      setLoading(true);
      getAllListData(
        `${COMMON_ENDPOINT.attorneyDetails}/${Params.id}?documentTemplateId=${
          location.state.data.id
        }&templateName=${
          location.state.data.templateValue
        }&issueId=${searchParams?.get("issueId")}${
          searchParams?.get("subIssueId")
            ? `&subIssueId=${searchParams?.get("subIssueId")}`
            : ""
        }`
      )
        .then((res) => {
          setInputs(
            Array.isArray(res.data)
              ? res.data.map(
                  (
                    er: Partial<{
                      answer: string;
                      label: string;
                      id: number;
                      textArea: boolean;
                      questionId: string;
                    }>
                  ) => ({ ...er, questionId: er.id })
                )
              : []
          );
          setLoading(false);
          // setInputs(Array.isArray(res.data) ? res.data : []);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllListData(`${COMMON_ENDPOINT.preparerGet}`)
      .then((res) => {
        if (res && res.data && res.data.length) {
          setmodelOpen(false);
        } else {
          setmodelOpen(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (fileblob) {
      const winHtml = `<!DOCTYPE html>
  <html>
    <body>
      <iframe
        style="width: 100%; height: 100vh"
        src="data:application/pdf;base64,${fileblob}"
      />
    </body>
  </html>
  `;
      const winUrl = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
      );
      window.open(
        winUrl,
        "win",
        "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500"
      );
    }
  }, [fileblob]);

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <StyledWorkspaceContainer>
        <StyledDocumentWorkspace
          style={{
            display: "flex",
            flexDirection: "column",
            width: "84.5%",
            height: "calc(100vh - 70px)",
            backgroundColor: "#0A1720",
          }}
        >
          <div
            style={{
              height: "48px",
              background: "#2B404F",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <button
              style={{
                background: "none",
                color: "#FFF",
                fontSize: "14px",
                padding: "8px 28px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackwardIcon />
              Back
            </button>
            <div
              style={{
                display: "flex",
                gap: 8,
              }}
            >
              <button
                style={{
                  background: "transparent",
                  color: "#fff",
                  fontSize: "14px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  border: "1px solid #fff",
                }}
                onClick={() => {
                  PreviewDocument();
                }}
              >
                Preview
                {Icons.EyeIcon}
              </button>
              <button
                style={{
                  background: "#FFF",
                  fontSize: "14px",
                  padding: "8px 28px",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={
                  () => buildDocument()
                  // () => navigate(`${ROUTE_RESPONDER_ASSEMBLE}/${Params.id}`)
                  // navigate(`${ROUTE_RESPONDER}/1/assemble`, {
                  //   state: {
                  //     name: CASES[0].name,
                  //     subfolders: CASES[0].subfolders,
                  //   },
                  // })
                }
              >
                Build
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              height: "calc(100% - 48px)",
            }}
          >
            <Paper
              text={text}
              docTempName={location.state.data.templateValue}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                overflowY: "scroll",
                padding: "32px 20px",
                width: "25%",
                backgroundColor: "#0a1720",
                scrollbarWidth: "auto",
              }}
            >
              {inputs.map(({ id, label, answer, textArea }) => (
                <StyledLabel>
                  {id}. {label}
                  {textArea ? (
                    <StyledTextarea
                      id={`${id}`}
                      value={answer}
                      onClick={() => setClicked(id)}
                      onChange={
                        (e) =>
                          setInputs((prev) => [
                            ...prev.map((el) => {
                              if (el.id === id) {
                                el.answer = e.target.value;
                              }
                              return el;
                            }),
                          ])
                        // setInputValues((prev) => ({
                        //   ...prev,
                        //   [id]: e.target.value,
                        // }))
                      }
                      onBlur={(e) => {
                        setInputs((prev) => [
                          ...prev.map((el) => {
                            if (el.id === id) {
                              el.answer = e.target.value;
                            }
                            return el;
                          }),
                        ]);
                        onBlurUpdate(id);
                      }}
                      clicked={clicked === id}
                      data-type="text"
                    />
                  ) : (
                    <StyledInput
                      id={`${id}`}
                      value={answer}
                      onClick={() => setClicked(id)}
                      onBlur={(e) => {
                        setInputs((prev) => [
                          ...prev.map((el) => {
                            if (el.id === id) {
                              el.answer = e.target.value;
                              el.questionId = `${el.id}`;
                              return el;
                            }
                            return el;
                          }),
                        ]);
                        onBlurUpdate(id);
                      }}
                      onChange={(e) =>
                        // setInputValues((prev) => ({
                        //   ...prev,
                        //   [id]: e.target.value,
                        // }))
                        // setInputs((prev) => [
                        //   ...prev.map((el) => {
                        //     if (el.id === id) {
                        //       el.answer = e.target.value;
                        //     }
                        //     return el;
                        //   }),
                        // ])
                        setInputs((prev) => [
                          ...prev.map((el) => {
                            if (el.id === id) {
                              el.answer = e.target.value;
                              el.questionId = `${el.id}`;
                              return el;
                            }
                            return el;
                          }),
                        ])
                      }
                      clicked={clicked === id}
                      data-type="text"
                    />
                  )}
                </StyledLabel>
              ))}
            </div>
          </div>
        </StyledDocumentWorkspace>
        <TabsBar />
      </StyledWorkspaceContainer>

      {modelOpen && <PreparerInfo setmodelOpen={setmodelOpen} />}
    </>
  );
};

export default DocumentWorkspace;
