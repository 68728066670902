import styled, { css } from "styled-components";

interface IstyledbuttonProps {
  hasBg?: boolean;
  weight?: number;
  disabled?: boolean;
  label?: boolean;
}

export const StyledButton = styled.button<IstyledbuttonProps>`
  color: #fff;
  border: none;
  background: none;
  font-family: LibreFranklin;
  font-weight: ${({ weight }) => weight || 500};

  ${({ hasBg }) =>
    hasBg &&
    css`
      padding: 10px;
      background: #0080ff;
      width: 100%;
      cursor: pointer;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #0080ff9c;
      color: #ffffffb2;
      cursor: not-allowed;
    `}
    &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  cursor: ${(props) => (props.label ? "pointer" : "auto")};
`;
