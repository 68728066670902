import styled from "styled-components";
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
`;

export const SelectLabel = styled.label`
  font-size: 1rem;
  /* margin-bottom: 0.5rem; */
`;
export const Select = styled.select<{
  error?: boolean;
  label?: boolean;
  bgColor?: string;
}>`
  padding: ${({ label = true }) => (label ? "12px 16px 10px" : "12px 16px")};
  background: ${({ bgColor = "#162632" }) => bgColor};
  color: #fff;
  border: ${({ error }) =>
    error ? "1px solid #D13F50" : "1px solid transparent"};
  outline: none;
  margin-bottom: ${({ error }) => `${error ? 0 : 24}px`};
  font-size: 1rem;
  font-family: LibreFranklin;
  width: 100%;
`;

export const SelectError = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;
