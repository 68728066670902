import styled, { css } from "styled-components";

export const StyledModal = styled.div<{ active?: boolean }>`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ active }) =>
    active
      ? css`
          width: 800px;
          top: 50%;
        `
      : css`
          width: 442px;
          top: 50%;
        `};
  background: #1f323f;
  padding: 32px;
  z-index: 1000;

  .close_btn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 26px;
    transform: rotate(45deg);
  }
`;

export const StyledDarkBg = styled.div<{ hasBg?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(8px);
  z-index: 999;
`;

// -----------

export const StyledModalContainer2 = styled.div<{ hasBg?: boolean }>`
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-color: rgba(0, 0, 0, ${({ hasBg }) => (hasBg ? "1" : ".5")});
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .popovercontainer {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 442px; */
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;
export const StyledModal2 = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* width: 442px;
  background: #1f323f;
  padding: 32px;
  z-index: 1000;
  overflow: auto;
  height: 100%; */
  background-color: #1f323f;
  padding: 32px;
  background: rgb(31, 50, 63);
  width: 442px;
  margin-top: 16%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .close_btn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 26px;
    transform: rotate(45deg);
  }
`;

export const StyledDarkBg2 = styled.div<{ hasBg?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${({ hasBg }) => (hasBg ? "1" : ".5")});
  z-index: 999;
`;
