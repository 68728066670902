import { Route, Routes, useNavigate } from "react-router-dom";
import LawFirm from "src/assets/images/folderp.png";
import { ReactComponent as Home } from "src/assets/images/Home.svg";
import { ReactComponent as Logout } from "src/assets/images/ArrowBackward.svg";

import CasesList from "./CaseList/CaseList";
import CaseDetail from "./CaseDetail/CaseDetail";
import CaseAssemble from "./CaseAssemble";
// import Storage from "../../utils/Storage";
import { StyledText } from "src/components/Text/styled";

import {
  StyledAside,
  StyledContainer,
  StyledContent,
  StyledSidebarBtn,
  StyledSubContainer,
} from "./styled";
import { logout } from "src/store/actions/UserAction";
import { useAppDispatch } from "src/store/hooks";
import { useAppSelector } from "src/store/hooks";
import { deleteData } from "src/services/commonServices";
import { MASTER_ENDPOINT } from "src/utils/Constant";
// import { toast } from "react-toastify";
// import { alertOptions } from "src/utils/helper";
import { ROUTE_RESPONDER } from "src/routes";
import { useState } from "react";
import { alertOptions } from "src/utils/helper";
import { toast } from "react-toastify";
import LawFirmForm from "./../LawFirm";

interface IResponder {
  sidebasasdropdown?: boolean;
}

const Responder = (props: IResponder) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo
  );
  const loaderState: boolean = useAppSelector(
    (store: any) => store.userLogin.loading
  );
  const dispatch = useAppDispatch();
  const logOut = () => {
    deleteData(userDetail.refreshTokenId, MASTER_ENDPOINT.logout)
      .then(() => {
        setLoading(true);
        dispatch(logout());
        if (loaderState) {
          setLoading(loaderState);
        }
        toast.success("Logged out successfully", alertOptions);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e.error.message ? e.error.message : "Oops!!! Something went wrong",
          alertOptions
        );
      });
  };

  return (
    <>
      {loading && <div className="liberty-loader" />}
      {props.sidebasasdropdown ? (
        <>
          <StyledSubContainer>
            <div>
              <Home />
            </div>
            <StyledText
              onClick={() => navigate(ROUTE_RESPONDER)}
              textAlign="left"
              size={14}
              style={{ transform: "translateY(-1.5px)", cursor: "pointer" }}
            >
              Responder
            </StyledText>
          </StyledSubContainer>
          <StyledSubContainer
            onClick={() => logOut()}
            style={{ paddingBottom: "16px" }}
          >
            <Logout />
            <StyledText
              textAlign="left"
              size={14}
              style={{ gap: "12px", marginLeft: "2px", cursor: "pointer" }}
            >
              Logout
            </StyledText>
          </StyledSubContainer>
        </>
      ) : (
        <StyledContainer>
          <StyledAside>
            <StyledSidebarBtn>
              <Home />
              <StyledText
                onClick={() => navigate(ROUTE_RESPONDER)}
                textAlign="left"
                size={14}
              >
                Responder
              </StyledText>
            </StyledSidebarBtn>
            <StyledSidebarBtn>
              <img
                src={LawFirm}
                alt="Lawfirm"
                style={{ height: "20px", width: "20px" }}
              />
              <StyledText
                onClick={() => navigate(`lawFirm/${userDetail.id}`)}
                textAlign="left"
                size={14}
              >
                Law Firm
              </StyledText>
            </StyledSidebarBtn>
            <StyledSidebarBtn onClick={() => logOut()}>
              <Logout />
              <StyledText textAlign="left" size={14}>
                Logout
              </StyledText>
            </StyledSidebarBtn>
          </StyledAside>

          <StyledContent>
            <Routes>
              <Route path="/" element={<CasesList />} />
              <Route path="/:id" element={<CaseDetail />} />
              <Route path="/assemble/:id" element={<CaseAssemble />} />
              <Route path="/lawFirm/:id" element={<LawFirmForm />} />
            </Routes>
          </StyledContent>
        </StyledContainer>
      )}
    </>
  );
};

export default Responder;
