import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledTextInput = styled.input`
  color: #ACBDCA;
  background: #2B404F;
  padding: 12px 16px;
  margin-bottom: 24px;
  border: none;
  outline: none;

  ::placeholder {
    color: #ACBDCA;
  }
`

export const StyledLabel = styled.label`
  color: #fff;
  background: #0F5AB7;
  padding: 8px 18px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 14px;
  font-family: LibreFranklin;
`

export const StyledUploadArea = styled.div`
  padding: 24px 0;
  margin-bottom: 24px;
  text-align: center;
  border: 1px dashed #2B404F;

  & > svg {
    margin-bottom: 20px;
  }

  & > button {
    width: fit-content;
    padding: 8px 18px;
  }
`

export const StyledUploadedArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #111;
  background: #fff;
  padding: 4px 8px;
  border-radius: 2px;
  margin-bottom: 24px;

  & > button {
    background: transparent;
    margin-left: auto;
    border: none;
    cursor: pointer;
    font-size: 26px;
    transform: rotate(45deg);
  }
`

export const StyledFileInput = styled.input`
  display: none;
`
