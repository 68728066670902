/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";
import { ReactComponent as Edit } from "src/assets/images/Edit.svg";
import { ReactComponent as Pdf } from "src/assets/images/Pdf.svg";
import { ReactComponent as MicrosoftWord } from "src/assets/images/MicrosoftWord.svg";
import HandleDocModal from "src/components/HandleDocModal/HandleDocModal";
import { StyledText } from "src/components/Text/styled";
import {
  // StyledAssembleDrag,
  // StyledAssembleFooter,
  StyledBtnsWrap,
  StyledDoc,
  StyledLabel,
} from "../styled";
import { useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { addNewData, deleteData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { alertOptions } from "src/utils/helper";
import Modal from "src/components/Modal";

export type DocType = "brief" | "attachment" | "exhibit" | "document" | "cover";

interface IDocument {
  name: string;
  id: string;
  extension: string;
  docType: DocType;
  caseId?: string;
  isAttachment?: boolean;
  isExhibit?: boolean;
  isBrief?: boolean;
  isDraggable?: boolean;
  isCover?: any;
  getData?: any;
  isAssemble?: boolean;
  data?: any;
  activeTab?: any;
  value?: string;
  disableClose?: boolean;
}

const Document: FC<IDocument> = ({
  name,
  id,
  extension,
  isAttachment,
  isExhibit,
  isBrief,
  isCover,
  docType,
  caseId,
  getData,
  isAssemble,
  isDraggable = false,
  data,
  activeTab,
  value,
  disableClose,
}) => {
  // const [parentId, setParentId] = useState("");
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [openModal, setModelOpen] = useState<boolean>(false);
  const [
    { isOpen, operationType, isHeader, child, exhibitChildId, documentType },
    setModal,
  ] = useState<{
    isOpen: boolean;
    id?: any;
    tabType?: string;
    isHeader?: boolean;
    child?: boolean;
    exhibitChildId?: string;
    operationType?: "edit" | "remove";
    documentType?: string;
  }>({ isOpen: false, isHeader: false });
  const hasLabel = !!(isAttachment || isExhibit || isBrief || isCover);

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "doc",
      item: { caseId, id, name, extension },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name]
  );

  const closeModal = () => {
    setModelOpen(false);
  };

  const deleteAssembleFile = () => {
    setLoading(true);
    deleteData(id, "/assemble")
      .then((res) => {
        setModelOpen(false);
        getData();
        setLoading(false);
        toast.success(res.message);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const [{ isOver }, drop1] = useDrop(
    () => ({
      accept: "doc",
      drop: (item: {
        name: string;
        caseId: string;
        id: string;
        extension: string;
        parentDocumentId: string;
      }) => {
        setLoading(true);
        const { id } = item;
        const bodyData = {
          uploadId: params.id,
          fileId: id,
          documentType: "doc",
          type: "exhibit",
          parentDocumentId: data.id,
        };
        addNewData(bodyData, `${COMMON_ENDPOINT.assemble}/exhibit`)
          .then((res: any) => {
            setLoading(false);
            // toast.success("Document added successfully", alertOptions);
            getData();
            // setParentId("");
          })
          .catch((e: any) => {
            setLoading(false);
            toast.error(e?.error?.message, alertOptions);
            // setParentId("");
          });
        // id && onDropHandler(caseId, id, name, extension, activeTab, dispatch);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [activeTab, data]
  );

  return (
    <div>
      {loading && <div className="liberty-loader" />}
      <StyledDoc
        hasBg
        isDraggable={isDraggable}
        ref={drag}
        data-testid="box"
        opacity={opacity}
      >
        {data?.documentType !== "header" && (
          <>
            {extension === "pdf" ? <Pdf /> : <MicrosoftWord />}
            <StyledText size={14} color="#111" textAlign="left" weight={500}>
              {name?.length > 38
                ? name.slice(0, 38) +
                  "..." +
                  name?.slice(name?.length - 6, name?.length)
                : name}
            </StyledText>
            {hasLabel && (
              <div className="labels_wrap">
                {isAttachment && <StyledLabel>Attachment</StyledLabel>}
                {isExhibit && <StyledLabel>Exhibit</StyledLabel>}
                {isBrief && <StyledLabel>Brief</StyledLabel>}
              </div>
            )}
          </>
        )}
        {data?.documentType === "header" && (
          <div style={{ width: "100%" }}>
            <StyledText size={14} color="#111" textAlign="left" weight={500}>
              <div style={{ padding: "0px 0px 5px 5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {data?.romanName ? `[${data?.romanName}] ` : ""}
                  {data?.documentType === "header"
                    ? data?.heading?.length > 38
                      ? data.heading.slice(0, 38) +
                        "..." +
                        data?.heading?.slice(
                          data?.heading?.length - 6,
                          data?.heading?.length
                        )
                      : data.heading
                    : ""}
                  <StyledBtnsWrap hasLabel={hasLabel}>
                    {
                      <Edit
                        onClick={() =>
                          setModal((prevState) => ({
                            ...prevState,
                            isOpen: true,
                            operationType: "edit",
                            id: id,
                          }))
                        }
                      />
                    }
                    <button
                      className="close_btn"
                      onClick={() => {
                        setModal((prevState) => ({
                          ...prevState,
                          isOpen: true,
                          operationType: "remove",
                          id: data?.id,
                          isHeader: true,
                          child: false,
                          exhibitChildId: data.id,
                          documentType: "header",
                        }));
                      }}
                    >
                      <StyledText
                        size={8}
                        color="#111"
                        weight={500}
                        lineHeight={10}
                        cursor="pointer"
                      >
                        ✖
                      </StyledText>
                    </button>
                  </StyledBtnsWrap>
                </div>
                {data?.assembleExhibitChildDocs.length > 0 &&
                  data?.assembleExhibitChildDocs?.map((res: any) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px 20px 5px 10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <StyledText
                        size={14}
                        color="#111"
                        textAlign="left"
                        weight={500}
                        style={{ padding: "5px" }}
                      >
                        <span style={{ marginRight: "5px" }}>
                          {res.extension === "pdf" ? (
                            <Pdf />
                          ) : (
                            <MicrosoftWord />
                          )}
                        </span>
                        {res.fileName?.length > 38
                          ? res.fileName.slice(0, 38) +
                            "..." +
                            res.fileName?.slice(
                              res.fileName?.length - 6,
                              res.fileName?.length
                            )
                          : res.fileName}
                      </StyledText>
                      <StyledBtnsWrap hasLabel={hasLabel}>
                        <button
                          className="close_btn"
                          onClick={() => {
                            setModal((prevState) => ({
                              ...prevState,
                              isOpen: true,
                              operationType: "remove",
                              id: id,
                              isHeader: true,
                              exhibitChildId: res.id,
                              child: true,
                              doucumentType: "header",
                            }));
                          }}
                        >
                          <StyledText
                            size={8}
                            color="#111"
                            weight={500}
                            lineHeight={10}
                            cursor="pointer"
                          >
                            ✖
                          </StyledText>
                        </button>
                      </StyledBtnsWrap>
                    </div>
                  ))}

                <div
                  style={{ marginTop: "10px" }}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {data?.documentType === "header" ? (
                    <StyledText
                      color="#111"
                      size={14}
                      style={{
                        border: "2px dashed rgb(17, 77, 156)",
                        padding: "4px",
                      }}
                      ref={drop1}
                    >
                      Drag and drop here
                    </StyledText>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </StyledText>
          </div>
        )}
        <StyledBtnsWrap hasLabel={hasLabel}>
          {!isAssemble && (
            <Edit
              onClick={() =>
                setModal((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  operationType: "edit",
                  id: id,
                }))
              }
            />
          )}
          {data?.documentType !== "header" && value !== "assembleUpload" ? (
            <button
              className="close_btn"
              onClick={() =>
                setModal((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  operationType: "remove",
                  id: id,
                }))
              }
            >
              <StyledText
                size={8}
                color="#111"
                weight={500}
                lineHeight={10}
                cursor="pointer"
              >
                ✖
              </StyledText>
            </button>
          ) : value === "assembleUpload" && !disableClose ? (
            <button className="close_btn" onClick={() => setModelOpen(true)}>
              <StyledText
                size={8}
                color="#111"
                weight={500}
                lineHeight={10}
                cursor="pointer"
              >
                ✖
              </StyledText>
            </button>
          ) : (
            ""
          )}
        </StyledBtnsWrap>
        {isOpen && (
          <HandleDocModal
            getData={getData}
            operationType={operationType}
            docType={docType}
            tabType={activeTab}
            name={name}
            isHeader={isHeader}
            ids={id}
            extension={extension}
            isChild={child}
            exhibitChildId={exhibitChildId}
            documentType={documentType}
            handleClose={() =>
              setModal((prevState) => ({
                ...prevState,
                isOpen: false,
                operationType: undefined,
              }))
            }
          />
        )}
      </StyledDoc>
      {openModal && (
        <Modal handleClose={closeModal}>
          <div>
            <div style={{ marginTop: "15px" }}>
              <StyledText size={18} padding="0 0 20px">
                Are you sure you want to delete this file ?
              </StyledText>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  width: "78px",
                  height: "30px",
                  padding: "8px 16px 8px 16px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                style={{
                  width: "78px",
                  height: "30px",
                  padding: "8px 16px 8px 16px",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                onClick={deleteAssembleFile}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Document;
