import { useFormik } from "formik";
import { initialValues, validationSchema } from "./helpers";
import Form from "src/components/Form";
import Input from "src/components/Input/Input";
import CheckBox from "src/components/CheckBox/checkBox";
import { StyledRow } from "src/components/Input/styled";
import { StyledText } from "src/components/Text/styled";
import { addNewData } from "src/services/commonServices";
import { MASTER_ENDPOINT } from "src/utils/Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { alertOptions } from "src/utils/helper";
import { ROUTE_SIGN_IN } from "src/routes";

const SignUp = () => {
  const navigate = useNavigate();
  const submitHandler = ({
    firstName,
    lastName,
    email,
    createPassword,
    lawyer,
    repeatPassword,
    firmName,
  }: any) => {
    const body = {
      firstName: firstName,
      lastName: lastName,
      firmName: firmName,
      email: email,
      password: createPassword,
      repeatPassword: repeatPassword,
      isLawyer: lawyer ? true : false,
    };
    addNewData(body, MASTER_ENDPOINT.Users).then((res) => {
      if (res.status === 200) {
        toast.success("User added successfully", alertOptions);
        navigate(ROUTE_SIGN_IN);
      }
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  const {
    values: {
      firstName,
      lastName,
      email,
      createPassword,
      repeatPassword,
      lawyer,
      firmName,
    },
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Form type="sign-up" isFixed onSubmit={handleSubmit}>
      <StyledRow>
        <div>
          <Input
            id="firstName"
            placeholder="First name"
            value={firstName}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("firstName", e.currentTarget.value)
            }
            error={touched.firstName && errors.firstName}
          />
        </div>
        <div>
          <Input
            id="lastName"
            placeholder="Last name"
            value={lastName}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("lastName", e.currentTarget.value)
            }
            error={touched.lastName && errors.lastName}
          />
        </div>
      </StyledRow>
      <div>
        <Input
          id="firmName"
          placeholder="Firm name"
          value={firmName}
          handleBlur={handleBlur}
          handleValue={(e) => setFieldValue("firmName", e.currentTarget.value)}
          error={touched.firmName && errors.firmName}
        />
      </div>
      <div>
        <Input
          id="email"
          placeholder="Email"
          value={email}
          handleBlur={handleBlur}
          handleValue={(e) => setFieldValue("email", e.currentTarget.value)}
          error={touched.email && errors.email}
        />
      </div>
      <label>
        <CheckBox
          id="lawyer"
          type="checkbox"
          value={lawyer}
          handleBlur={handleBlur}
          handleValue={(e) => {
            setFieldValue("lawyer", e.currentTarget.checked);
          }}
          error={touched.lawyer && errors.lawyer}
        />
        <StyledText
          family="Inter"
          weight={500}
          style={{ paddingBottom: "5px" }}
        >
          I am lawyer{" "}
        </StyledText>
      </label>
      <Input
        id="createPassword"
        placeholder="Create password"
        type="password"
        value={createPassword}
        handleBlur={handleBlur}
        handleValue={(e) =>
          setFieldValue("createPassword", e.currentTarget.value)
        }
        error={touched.createPassword && errors.createPassword}
      />
      {formik.errors.createPassword && touched.createPassword ? null : (
        <StyledText
          size={12}
          textAlign="left"
          padding="0 0 24px"
          family="Inter"
          lineHeight={20}
        >
          Password with 8+ characters, including at least one number, lowercase
          and uppercase letter, and special character.
        </StyledText>
      )}
      <Input
        id="repeatPassword"
        placeholder="Repeat password"
        type="password"
        value={repeatPassword}
        handleBlur={handleBlur}
        handleValue={(e) =>
          setFieldValue("repeatPassword", e.currentTarget.value)
        }
        error={touched.repeatPassword && errors.repeatPassword}
      />
    </Form>
  );
};

export default SignUp;
