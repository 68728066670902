import { StyledFormWrap } from "../../components/Input/styled";
import { StyledText } from "../../components/Text/styled";
import { StyledButton } from "../../components/Button/styled";
import Input from "src/components/Input";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./helpers";
import { ROUTE_SIGN_IN } from "src/routes";
import { toast } from "react-toastify";
import { addNewData } from "src/services/commonServices";
import { MASTER_ENDPOINT } from "src/utils/Constant";
import { alertOptions } from "src/utils/helper";
import { useNavigate } from "react-router";

const Forgot = () => {
  const navigate = useNavigate();
  const submitHandler = ({ email }: any) => {
    const body = {
      email: email,
    };
    addNewData(body, MASTER_ENDPOINT.Otp)
      .then((res: any) => {
        toast.success(
          "OTP send to your email address successfully",
          alertOptions
        );
        navigate(ROUTE_SIGN_IN);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });
  const {
    values: { email },
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <StyledFormWrap isFixed>
        <div>
          <StyledText size={36} weight={600} padding="0 0 16px">
            Forgot Password ?
          </StyledText>
          <StyledText
            size={14}
            weight={400}
            padding="0 0 48px 0"
            textAlign={"left"}
          >
            Enter the email to which your account is assigned and we will send
            verification code.
          </StyledText>
          <Input
            id="Email"
            placeholder="Email"
            value={email}
            handleBlur={handleBlur}
            handleValue={(e) => {
              setFieldValue("email", e.currentTarget.value);
            }}
            error={touched.email && errors.email}
          />
          <StyledButton hasBg type="submit">
            Send code
          </StyledButton>
        </div>
      </StyledFormWrap>
    </form>
  );
};

export default Forgot;
