import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const UseFocus = () => {
  const htmlElRef: any = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: any) => useEffect(fun, []);

export const useCustomNavigation = (routePath: string, options: any) => {
  const navigate = useNavigate();
  navigate(routePath, options);
};

export const instantState = async (
  setState: any,
  newData?: any,
  callBack?: any
): Promise<any> => {
  let data = "";
  await new Promise((resolve) => {
    if (newData) {
      setState((pre: any) => newData);
      data = newData;
      resolve(data);

      if (callBack) callBack();
      return newData;
    } else {
      setState((pre: any) => {
        data = pre;
        resolve(data);
        return pre;
      });
    }
  });
  return data;
};

export const useSetState = <State = any>(initialState: any) => {
  const [state, setState] = useState<State>(initialState);

  const getState = async (): Promise<State> => {
    let state: unknown;

    await setState((currentState: State) => {
      state = currentState;

      return currentState;
    });

    return state as State;
  };

  return [state, setState, getState] as [
    State,
    typeof setState,
    typeof getState
  ];
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
