import axios from "axios";
import { useCustomNavigation } from "../hooks/index";
import { toast } from "react-toastify";
import store from "../store/store";
// import { refreshToken } from 'store/actions/UserAction';
import { USER_LOGOUT } from "../store/types/UserTypes";
import { alertOptions } from "../utils/helper";
import Storage from "../utils/Storage";
import { logout, refreshToken } from "src/store/actions/UserAction";

const { dispatch } = store;

let id: any = "";

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const TokenStatus: any = Storage.getTokens();
    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : "";
    if (token) {
      config.headers["Authorization"] = "Bearer ".concat(token);
      config.headers["ClientDate"] = String(new Date());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "login" && err.response) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error?.message &&
        err.response.data.error?.message === "Invalid Token!"
      ) {
        dispatch({
          type: USER_LOGOUT,
        });
        Storage.clear();
        if (!toast.isActive(id)) {
          id = toast.error(
            "Session expired! Please login again. ",
            alertOptions
          );
        }
        useCustomNavigation("/sign-in", { replace: true });
        return;
      }
      if (err.response.status === 401) {
        // useCustomNavigation("/sign-in", { replace: true });
        originalConfig._retry = true;
        try {
          const TokenStatus: any = Storage.getTokens();
          const rs = await instance.post('tokens/exchange', {
            refreshToken:
              TokenStatus && TokenStatus.refreshToken
                ? TokenStatus.refreshToken
                : '',
          });
  
          const { token } = rs.data;
          dispatch(refreshToken(token));
          Storage.updateAccessToken(token);
          return instance(originalConfig);
        } catch (_error) {
          dispatch(logout());
          Storage.clear();
          useCustomNavigation('/sign-in', { replace: true });
        }
      }
      
      if (err.response.status === 403 && err.response.data) {
        dispatch(logout());
        Storage.clear();
        useCustomNavigation('/sign-in', { replace: true });
      }
    }

  

    return Promise.reject(err);
  }
);



export default instance;
