import styled, { css } from "styled-components";

export const EvidenceFormStyled = styled.form<{ isSummary?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${({ isSummary }) => (isSummary ? "100%" : "80%")};

  & > button {
    display: none;
  }
`;

export const EvidenceElementsHead = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  justify-content: space-between;
  gap: ${({ flexDirection }) => (flexDirection ? "0" : "24px")};

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      & > div:nth-child(2) {
        margin-bottom: 16px;
      }

      & > div:nth-child(3) {
        margin-bottom: 8px;
      }
    `}

  span {
    width: 50%;
    text-align: left;
  }

  .labels_wrap {
    display: flex;
    gap: 24px;
    padding-bottom: 8px;
  }
`;

export const EvidenceElement = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;

  input {
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 16px;
  }
`;

export const AttachmentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
