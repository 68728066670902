import * as Yup from "yup";

export const initialValues = {
  name: "",
  id: "",
};

export const validationSchema = Yup.object({
  name: Yup.string().optional(),
  id: Yup.string().optional(),
});
