import { FC } from "react";
import { IQuestion } from "src/slices/answersSlice";
import { TempFilesType } from "src/components/EvidenceForm/EvidenceForm";
import { StyledText } from "src/components/Text/styled";
import { StyledQuestion } from "./styled";
import FilesTable from "./FilesTable";

const Question: FC<{ num: number; questions: IQuestion[] }> = ({
  num,
  questions,
}) => {
  const isFirstStep = num === 0;

  return (
    <StyledQuestion>
      <StyledText
        color="#0080FF"
        weight={500}
        textAlign="left"
        padding="0 16px 16px 0"
      >
        QUESTION {num + 1}
      </StyledText>
      {isFirstStep ? (
        <FilesTable
          title={questions[0]}
          tableHeader={questions[1].question}
          files={questions[1].answer as TempFilesType[]}
          num={num}
        />
      ) : (
        <div>
          {questions.map(({ question, answer }) => (
            <div>
              <StyledText
                weight={600}
                textAlign="left"
                lineHeight={24}
                padding="0 0 4px"
              >
                {question}
              </StyledText>
              {typeof answer === "string" && (
                <StyledText
                  color="#ACBDCA"
                  lineHeight={21}
                  textAlign="left"
                  padding="0 0 16px"
                >
                  {answer !== ""
                    ? answer === "true"
                      ? "Yes"
                      : answer === "false"
                      ? "No"
                      : answer
                    : "-"}
                </StyledText>
              )}
            </div>
          ))}
        </div>
      )}
    </StyledQuestion>
  );
};

export default Question;
