import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTE_DOCUMENT_WORKSPACE, ROUTE_RESPONDER_ASSEMBLE } from "src/routes";
import { ReactComponent as File } from "src/assets/images/File.svg";
import { ReactComponent as Assemble } from "src/assets/images/Assemble.svg";
import CaseNav from "../CaseNav";
import { StyledText } from "src/components/Text/styled";
import Badge from "@mui/material/Badge";
// import saveAs from "file-saver";
import {
  StyledAssembleBtn,
  StyledCaseDetail,
  StyledList,
  StyledWrap,
} from "../styled";
import {
  deleteData,
  getAllListData,
  getSingleData,
} from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { Table } from "./styled";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";

const CaseDetail = () => {
  const [searchParams]: any = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const [caseDetail, setCasedetail]: any = useState();
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState({ open: false, data: [] });
  // const {
  //   state: { name, subfolders },
  // } = useLocation();
  const getData = () => {
    getAllListData(
      `${
        COMMON_ENDPOINT.buildDocument
      }?uploadId=${id}&issueId=${searchParams?.get("issueId")}${
        searchParams?.get("subIssueId")
          ? `&subIssueId=${searchParams?.get("subIssueId")}`
          : ""
      }`
    )
      .then((res) => {
        setCasedetail(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  const deleteBuild = (id: any) => {
    setLoading(true);
    deleteData(id, COMMON_ENDPOINT.buildDocument)
      .then((data: any) => {
        toast.success("Deleted successfully", alertOptions);
        setLoading(false);
        getData();
        close();
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  const openFile = (id: string, fileName: any) => {
    setLoading(true);
    getSingleData(
      "",
      `${COMMON_ENDPOINT.buildDocumentFile}?id=${id}&type=buildFiles`,
      { responseType: "arraybuffer" }
    )
      .then((data: any) => {
        // window.open(data);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toast.success(`${fileName} File Downloaded`, alertOptions);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  const close = () => {
    setModelOpen((pre) => ({
      ...pre,
      open: false,
    }));
  };
  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <CaseNav />
      <StyledWrap padding="0 0 22px" alignItems="center">
        <StyledText textAlign="left" size={18} weight={700} lineHeight={24}>
          Case {id}
        </StyledText>
        <StyledAssembleBtn
          onClick={() =>
            navigate(
              `${ROUTE_RESPONDER_ASSEMBLE}/${id}?issueId=${searchParams?.get(
                "issueId"
              )}${
                searchParams?.get("subIssueId")
                  ? `&subIssueId=${searchParams?.get("subIssueId")}`
                  : ""
              }`
            )
          }
        >
          <Assemble />
          <StyledText size={14} cursor="pointer">
            Assemble
          </StyledText>
        </StyledAssembleBtn>
      </StyledWrap>
      <StyledList isFlexGrow>
        {caseDetail?.map((data: any, index: any, value: any) => {
          return (
            <li key={id}>
              <StyledCaseDetail
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `${ROUTE_DOCUMENT_WORKSPACE}/${id}?issueId=${searchParams?.get(
                      "issueId"
                    )}${
                      searchParams?.get("subIssueId")
                        ? `&subIssueId=${searchParams?.get("subIssueId")}`
                        : ""
                    }`,
                    {
                      state: {
                        data: data,
                      },
                    }
                  )
                }
              >
                <File />
                <StyledText size={14}>{data.fileName}</StyledText>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModelOpen((pre) => ({
                      ...pre,
                      open: true,
                      data: data.buildDocuments,
                    }));
                  }}
                >
                  <Badge
                    badgeContent={
                      data.buildDocuments ? data.buildDocuments.length : 0
                    }
                    color="primary"
                  />
                </div>
              </StyledCaseDetail>
            </li>
          );
        })}
        {/* {subfolders.map((caseDetail: { id: number; name: string }) => ( */}
        {/* <li key={id}>
          <StyledCaseDetail>
            <File />
            <StyledText size={14}>{"caseDetail.name"}</StyledText>
          </StyledCaseDetail>
        </li>

        <li key={id}>
          <StyledCaseDetail>
            <File />
            <StyledText size={14}>{"caseDetail.name"}</StyledText>
          </StyledCaseDetail>
        </li>
        <li key={id}>
          <StyledCaseDetail>
            <File />
            <StyledText size={14}>{"caseDetail.name"}</StyledText>
          </StyledCaseDetail>
        </li> */}
        {/* ))} */}
      </StyledList>
      {modelOpen.open && (
        <Modal handleClose={close} buildModel={true}>
          <StyledText textAlign="left" size={22} padding="0 0 20px">
            Previous version
          </StyledText>
          <Table>
            <thead>
              <th style={{ width: "50%" }}>Document Name</th>
              <th style={{ width: "30%" }}>Created At</th>
              <th style={{ width: "20%" }}>Action</th>
            </thead>
            <tbody>
              {modelOpen.data &&
                modelOpen.data.map((res: any) => (
                  <tr>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => openFile(res.id, res.fileName)}
                    >
                      {res.fileName}
                    </td>
                    <td>{moment(res.createdAt).format("DD-MM-YYYY")}</td>
                    <td>
                      <div style={{ color: "red", cursor: "pointer" }}>
                        <DeleteForeverIcon
                          onClick={() => deleteBuild(res.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal>
      )}
    </>
  );
};

export default CaseDetail;
