import EvidenceForm from "../../components/EvidenceForm";
import { Dispatch, SetStateAction } from "react";

export type TestDataType = {
  mainQuestion?: string;
  question?: string;
  stepTitle: string;
  stepForm: (
    hasFilesLengthCallBack: Dispatch<SetStateAction<boolean>>
  ) => JSX.Element;
};

export const TestData: Array<TestDataType> = [
  {
    mainQuestion:
      "Are there facts and additional facts to respond to the issue?",
    question:
      "What evidence do you have to support the facts or additional facts?",
    stepTitle: "Additional facts",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={0}
        hasFileUpload
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion: "Do you have a witness to attest to facts?",
    stepTitle: "Witness testimony",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={1}
        inputs={[
          {
            label: "What is the name of the witness?",
            fields: [
              { fieldName: "firstName", placeholder: "First Name" },
              { fieldName: "lastName", placeholder: "Last Name" },
            ],
          },
          {
            label: "What is the witness contact info?",
            fields: [
              { fieldName: "phone", placeholder: "Phone" },
              { fieldName: "email", placeholder: "Email" },
            ],
          },
        ]}
        textareas={[
          {
            label: "Please describe relative background of witness",
            fieldName: "primaryTextarea",
          },
          {
            label: "What is the witness testimony or statement?",
            fieldName: "secondaryTextarea",
            askOvieType: "Witness",
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion:
      "Do you need an expert to provide analysis to the facts or law?",
    stepTitle: "Expert analysis",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={2}
        inputs={[
          {
            label: "What is the name of the expert?",
            fields: [
              { fieldName: "firstName", placeholder: "First Name" },
              { fieldName: "lastName", placeholder: "Last Name" },
            ],
          },
          {
            label: "What is the expert's contact info?",
            fields: [
              { fieldName: "phone", placeholder: "Phone" },
              { fieldName: "email", placeholder: "Email" },
            ],
          },
        ]}
        textareas={[
          {
            label: "What is the biography of expert?",
            fieldName: "primaryTextarea",
          },

          {
            label:
              "What is expert's analysis in response to the issue in the RFE, NOID, NOIR, Decision?",
            fieldName: "secondaryTextarea",
            askOvieType: "Expert",
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion:
      "What is/are the relevant regulation(s) that apply(ies) to the issue?",
    stepTitle: "Regulations",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={3}
        textareas={[
          {
            label: "",
            fieldName: "primaryTextarea",
            hasCheckbox: true,
            askOvieType: "RegulationsPrimary",
          },
          {
            label:
              "The plain language of the regulation(s) support(s) the present case as follows",
            fieldName: "secondaryTextarea",
            hasCheckbox: true,
            askOvieType: "RegulationsSecondary",
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion: "How do policy memos apply to the issue?",
    stepTitle: "Policy memos",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={4}
        textareas={[
          {
            label: "",
            fieldName: "primaryTextarea",
            hasCheckbox: true,
            askOvieType: "Policy",
          },
          {
            label: "The policy of USCIS supports the present case as follows:",
            fieldName: "secondaryTextarea",
            hasCheckbox: true,
            askOvieType: "PolicySecondary",
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion: "How do AAO/BALCA decisions apply to the issue?",
    stepTitle: "Agency decisions",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={5}
        textareas={[
          {
            label: "",
            fieldName: "primaryTextarea",
            hasCheckbox: true,
            askOvieType: "DecisionsIssue",
          },
          {
            label:
              "The AAO/Balca Decision(s) supports the present case as follows",
            fieldName: "secondaryTextarea",
            hasCheckbox: true,
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion: "How does Federal Caselaw apply to the issue?",
    stepTitle: "Federal caselaw",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={6}
        textareas={[
          {
            label: "",
            fieldName: "primaryTextarea",
            hasCheckbox: true,
            askOvieType: "FederalCaselaw",
          },
          {
            label: "The Federal Caselaw supports the present case as follows",
            fieldName: "secondaryTextarea",
            hasCheckbox: true,
          },
        ]}
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
  {
    mainQuestion: "Response Generator",
    stepTitle: "Response Generator",
    stepForm: (hasFilesLengthCallBack) => (
      <EvidenceForm
        id={7}
        isSummary
        isFormSubmitting={false}
        isFormSubmittedCallBack={() => console.log("isFormSubmittedCallBack")}
        hasFilesLengthCallBack={hasFilesLengthCallBack}
      />
    ),
  },
];

export const TestBotMesages = [
  {
    title: "",
    text: "Add additional facts and evidence or indicate facts or evidence included in the previously submitted petition.",
  },
  {
    title: "",
    text: "Add a witness who has first-hand knowledge that can provide input on how the facts and evidence support the beneficiary’s argument that they meet the criteria of the visa.",
  },
  {
    title: "",
    text: "Add a witness who has first-hand knowledge that can provide input on how the facts and evidence support the beneficiary’s argument that they meet the criteria of the visa.",
  },
  {
    title: "",
    text: "Ask Ovie to pull the relevant regulations for the issue. Then ask Ovie to generate how the plain language of the regulations support the beneficiary’s argument that they meet the criteria of the visa. You may edit as you see fit.",
  },
  {
    title: "",
    text: "Ask Ovie to pull the relevant USCIS Policy memorandum for the issue. Then ask Ovie to generate how USCIS instructed its adjudicators to process visas that supports the beneficiary’s argument that they meet the criteria of the visa. You may edit as you see fit.",
  },
  {
    title: "",
    text: "Ask Ovie to pull the relevant AAO or BALCA decisions for the issue. Then ask Ovie to generate how AAO or BALCA  decisions support the beneficiary’s argument that they meet the criteria of the visa. You may edit as you see fit.",
  },
  {
    title: "",
    text: "Ask Ovie to pull the relevant Federal caselaaw for the issue. Then ask Ovie to generate how Federal caselaw supports the beneficiary’s argument that they meet the criteria of the visa. You may edit as you see fit.",
  },
  {
    title: "",
    text: "Once you have selected, generated and edited the authority which best supports your case. Generate your response. Edit as you see fit. If you want to upload to community Responses, click the button and add your name, firm or company name and city, state.",
  },
];
export const QuestionTwo: any = [
  "hasWitness",
  "firstName",
  "lastName",
  "phone",
  "email",
  "primaryTextarea",
  "secondaryTextarea",
];

export const QuestionThree: any = [
  "hasExpert",
  "firstName",
  "lastName",
  "phone",
  "email",
  "primaryTextarea",
  "secondaryTextarea",
];
