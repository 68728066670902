import styled from "styled-components";

export const StyledAvatarWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;

export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: #1f323f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Dropdown = styled.div<{ hide?: boolean }>`
  position: absolute;
  top: 120%;
  left: -25px;
  background-color: red;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  // transition: opacity 1s 1s;
  // ----------
  // padding: 14px;
  border-width: medium medium medium 2px;
  border-style: none none none solid;
  border-color: currentcolor currentcolor currentcolor rgb(17, 77, 156);
  border-image: none;
  background: rgb(15, 30, 40);
  color: rgb(255, 255, 255);
  display: flex;
  // gap: 12px;
  -moz-box-align: center;
  align-items: center;
  width: 128px;
  cursor: pointer;
  flex-direction: column;

  display: ${(props) => (props.hide ? "none" : "flex")};

  // new styles by san
  border-radius: 4px;
  background: #1f323f;
  width: 140px;
  left: -32px;
  top: 116%;
  z-index: 200;
  &::after {
    content: "";
    position: absolute;
    top: -14px;
    right: 40px;
    width: 10px;
    height: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 14px solid rgb(31, 50, 63);
    border-bottom-radius: 10px;
  }
`;
