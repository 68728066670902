import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface exhibitsState {
  value: { id: string; caseId: string; name: string; extension: string; isBrief: boolean }[];
}

const initialState: exhibitsState = {
  value: [],
};

export const briefsSlice = createSlice({
  name: "briefs",
  initialState,
  reducers: {
    addBrief: (
      state,
      action: PayloadAction<{ id: string; caseId: string; name: string; extension: string }>
    ) => {
      state.value = [...state.value, { ...action.payload, isBrief: true }];
    },
    editBrief: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? { ...item, name: action.payload.name }
          : item
      );
    },
    removeBrief: (state, action: PayloadAction<{ id: string }>) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
  },
});

export const { addBrief, removeBrief, editBrief } = briefsSlice.actions;

export default briefsSlice.reducer;
