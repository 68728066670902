import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface activeTabState {
  value: string;
}

const initialState: activeTabState = {
  value: "Attachments",
};

export const activeTab = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    handleActiveTab: (state, action: PayloadAction<{ value: string }>) => {
      state.value = action.payload.value;
    },
  },
});

export const { handleActiveTab } = activeTab.actions;

export const selectActiveTab = (state: RootState) => state.activeTab.value;

export default activeTab.reducer;
