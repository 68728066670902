import { FC } from "react";
import { StyledPaper } from "./styled";
import { IPaperProps } from "src/types";

const Paper: FC<IPaperProps> = ({ text, docTempName }) => {
  return (
    <div
      style={{
        padding: "32px",
        overflowY: "scroll",
        lineHeight: "30px",
        width: "75%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {docTempName === "responseModel" ? (
        <StyledPaper>
          <h3>Response to RFE NOIR NOIR model</h3>
          {/* <p>{Date.now().toLocaleString()}</p> */}
          DEPARTMENT OF HOMELAND SECURITY
          <br />
          U.S. CITIZENSHIP AND IMMIGRATION SERVICES
          <p>
            Beneficiary: {text(1)} {text(2)}
          </p>
          <p>Petitioner: {text(3)}</p>
          <p>Receipt Number: {text(4)}</p>
          <p>{text(5)}</p>
          <p>Dear Immigration Officer:</p>
          <p>
            Please accept the attached evidence in response to the
            <br />
            {text(6)}
            <br />
            issued on {text(7)}
            which is due {text(8)} regarding the petition submitted by the
            parties above.
          </p>
          <h3>Evidence in support is attached as follows:</h3>
          <p>{text(9)}</p>
          <h3>Issue</h3>
          <h3>The RFE states:</h3>
          <p>{text(10)}</p>
          In response to the issue above, the petitioner and beneficiary offer
          the following:
          <h3>Statement of Facts</h3>
          <p>{text(11)}</p>
          <p>
            Additional evidence in support of the facts is attached as follows:
          </p>
          <p>{text(9)}</p>
          <p>
            Witness testimony that is related to the issue raided is offered
            through the statement of {text(12)}. The witness background is{" "}
            {text(13)}
          </p>
          <p>The statement says in pertinent part,</p>
          <p>{text(14)}</p>
          <p>{text(15)} is an expert based upon their biography as follows:</p>
          <p>{text(16)}</p>
          <p>The expert provides the following analysis:</p>
          <p>{text(17)}</p>
          <h3>Legal Analysis</h3>
          <p>The relevant authority is relevant to the issue raised.</p>
          <p>The following regulations are applicable to the issue.</p>
          <p>{text(18)}</p>
          <p>
            The plain language of the regulation(s) support(s) the present case
            as follows:
          </p>
          <p>{text(19)}</p>
          <p>Federal case law is applicable to the issue.</p>
          <p>{text(20)}</p>
          <p>The following agency decisions support the present case.</p>
          <p>{text(21)}</p>
          <p>The Agency's policy(ies) support(s) this case as follows:</p>
          <p>{text(22)}</p>
          <p>
            Applying the relevant facts, evidence and application of relevant
            law as discussed above the present case meets the standards for this
            issue as follows:
          </p>
          <p>{text(23)}</p>
          <h3>Conclusion</h3>
          <p>
            We respectfully submit that the evidence provided in this
            <br />
            {text(5)}
            <br />
            satisfies the questions submitted and makes the Petitioner and
            Beneficiary eligible for the requeted visa by a preponderance of the
            evidence.
          </p>
          <p>Respectfully Submitted,</p>
          <p>
            {text(24)} {text(25)}
          </p>
          <p>{text(26)}</p>
          <p>{text(27)}</p>
          <p>
            {text(28)} {text(29)}
          </p>
          <p>{text(30)}</p>
          <p>{text(34)}</p>
          <p>{text(35)}</p>
          <p>{text(31)}</p>
          <p>Phone: +1 {text(32)}</p>
          <p>Email: {text(33)}</p>
        </StyledPaper>
      ) : (
        <StyledPaper>
          {/* <p>{Date.now().toLocaleString()}</p> */}
          <h3>U.S. Citizenship and Immigration Services</h3>
          To Whom It May Concern:
          <p>
            My name is{text(1)},{text(2)} for {text(4)}.
          </p>
          <p>{text(3)}</p>
          <p>{text(5)}</p>
          <br />
          <p>Sincerely,</p>
          <br />
          <p>
            {text(24)} {text(25)}
          </p>
          <p>{text(26)}</p>
          <p>{text(27)}</p>
          <p>
            {text(28)} {text(29)}
          </p>
          <p>{text(30)}</p>
          <p>{text(34)}</p>
          <p>{text(35)}</p>
          <p>{text(31)}</p>
          <p>Phone: +1 {text(32)}</p>
          <p>Email: {text(33)}</p>
        </StyledPaper>
      )}
    </div>
  );
};

export default Paper;
