import styled from "styled-components";

export const StyledTextarea = styled.textarea<{ height: string }>`
  height: ${({ height }) => `${height}px`};
  resize: none;
  padding: 12px 16px;
  border: none;
  outline: none;
  background: #1f323f;
  color: #fff;
  margin: -8px 0 4px;
  font-family: "LibreFranklin-Regular", sans-serif;
`;


export const StyledLabelWrap = styled.div`
  margin-top: -14px;

  & > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const StyledTextAreaWrap = styled.div`
  width: 100%;
  position: relative;

  & > textarea {
    width: 100%;
  }
`;

export const StyledOvieBtn = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px 10px;
  background: #516879;
  position: absolute;
  right: 10px;
  bottom: 16px;
  cursor: pointer;
`;
