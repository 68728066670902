import { StyledFormWrap } from "../../components/Input/styled";
import { StyledText } from "../../components/Text/styled";
import { StyledButton } from "../../components/Button/styled";
import Input from "src/components/Input";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./helpers";
import { ROUTE_SIGN_IN } from "src/routes";
import { toast } from "react-toastify";
import { addNewData } from "src/services/commonServices";
import { MASTER_ENDPOINT } from "src/utils/Constant";
import { alertOptions } from "src/utils/helper";
import { useLocation, useNavigate } from "react-router";

const Create = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const submitHandler = ({ newPassword, confirmPassword }: any) => {
    const body = {
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    addNewData(
      body,
      `${String(MASTER_ENDPOINT.ResetPassword)}?hash=${
        location?.state?.hashKey
      }`
    )
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Password created successfully", alertOptions);
          navigate(ROUTE_SIGN_IN);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });
  const {
    values: { newPassword, confirmPassword },
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <StyledFormWrap isFixed>
        <div>
          <StyledText size={36} weight={600} padding="0 0 16px">
            Reset Password
          </StyledText>
          <StyledText
            size={14}
            weight={400}
            padding="0 0 30px 0"
            textAlign={"center"}
          >
            Please Enter your new password
          </StyledText>
          <Input
            id="newpassword"
            type="password"
            placeholder="New password"
            value={newPassword}
            handleBlur={handleBlur}
            handleValue={(e) => {
              setFieldValue("newPassword", e.currentTarget.value);
            }}
            error={touched.newPassword && errors.newPassword}
          />
          <StyledText
            size={12}
            weight={200}
            padding="0 0 20px 0"
            textAlign={"left"}
          >
            Password with 8+ characters, including atleast one number, lowercase
            and uppercase letter and special character.
          </StyledText>
          <Input
            id="repeatpassword"
            type="password"
            placeholder="Repeat password"
            value={confirmPassword}
            handleBlur={handleBlur}
            handleValue={(e) => {
              setFieldValue("confirmPassword", e.currentTarget.value);
            }}
            error={touched.confirmPassword && errors.confirmPassword}
          />
          <StyledButton hasBg type="submit">
            Reset Password
          </StyledButton>
        </div>
      </StyledFormWrap>
    </form>
  );
};

export default Create;
