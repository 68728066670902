import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface pdfDocState {
  path: string | null;
  string: string | null;
}

const initialState: pdfDocState = {
  path: null,
  string: null,
};

export const pdfDoc = createSlice({
  name: "pdfDoc",
  initialState,
  reducers: {
    handlePdfDoc: (state, action: PayloadAction<{ path: string | null, string: string | null }>) => {
      state.path = action.payload.path;
      state.string = action.payload.string;
    },
  },
});

export const { handlePdfDoc } = pdfDoc.actions;

export default pdfDoc.reducer;
