import React from "react";
import { initialValues, validationSchema } from "./herlper";
import { useFormik } from "formik";
import Input from "../Input";
import { StyledRow } from "../Input/styled";
import Modal from "../Modal";
import { StyledText } from "../Text/styled";
import { addNewData } from "src/services/commonServices";
// import { useParams } from "react-router";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";

export const PreparerInfo = ({ setmodelOpen }: any) => {
  // const params = useParams();
  const submitHandler = () => {
    if (
      !firstName &&
      !lastName &&
      !firmName &&
      !email &&
      !streetNumber &&
      !unitType &&
      !unitNumber &&
      !city &&
      !phoneNumber &&
      !state &&
      !postalCode
    ) {
      toast.error("Preparer's Information", alertOptions);
    } else {
      const bodyData = {
        // uploadId: params.id,
        firstName: firstName,
        lastName: lastName,
        firmName: firmName,
        email: email,
        street: streetNumber,
        unitType: unitType,
        unitNumber: unitNumber,
        city: city,
        country: country,
        phoneNumber: phoneNumber,
        state: state,
        postalCode: postalCode,
      };
      addNewData(bodyData, COMMON_ENDPOINT.PreparerInfo)
        .then((res) => {
          close();
          toast.success(
            "Preparer's Information added successfully",
            alertOptions
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const close = () => {
    setmodelOpen(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  const {
    values: {
      firstName,
      lastName,
      email,
      firmName,
      streetNumber,
      unitType,
      unitNumber,
      city,
      country,
      phoneNumber,
      state,
      postalCode,
      // emailAddress,
    },
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Modal handleClose={close} Prepareinfo={true}>
        <StyledText textAlign="left" size={22} padding="0 0 20px">
          Preparer's Information
        </StyledText>
        <StyledRow>
          <div>
            <Input
              id="firstName"
              placeholder="First name"
              value={firstName}
              handleBlur={handleBlur}
              handleValue={(e) => {
                const vals = e.currentTarget.value.replace(/[^a-zA-Z s]/, "");
                setFieldValue("firstName", vals);
              }}
              error={touched.firstName && errors.firstName}
            />
          </div>
          <div>
            <Input
              id="lastName"
              placeholder="Last name"
              value={lastName}
              handleBlur={handleBlur}
              handleValue={(e) =>
                setFieldValue("lastName", e.currentTarget.value)
              }
              error={touched.lastName && errors.lastName}
            />
          </div>
        </StyledRow>
        <div>
          <Input
            id="firmName"
            placeholder="Firm Name"
            value={firmName}
            handleBlur={handleBlur}
            handleValue={(e) => {
              const vals = e.currentTarget.value.replace(/[^a-zA-Z s]/, "");
              setFieldValue("firmName", vals);
            }}
            error={touched.firmName && errors.firmName}
          />
        </div>
        <div>
          <Input
            id="email"
            placeholder="Email"
            value={email}
            handleBlur={handleBlur}
            handleValue={(e) => setFieldValue("email", e.currentTarget.value)}
            error={touched.email && errors.email}
          />
        </div>
        <div>
          <Input
            id="streetNumber"
            placeholder="Street Number & Name"
            value={streetNumber}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("streetNumber", e.currentTarget.value)
            }
            error={touched.streetNumber && errors.streetNumber}
          />
        </div>
        <StyledRow>
          <div>
            <Input
              id="unitType"
              placeholder="Unit Type"
              value={unitType}
              handleBlur={handleBlur}
              handleValue={(e) =>
                setFieldValue("unitType", e.currentTarget.value)
              }
              error={touched.unitType && errors.unitType}
            />
          </div>
          <div>
            <Input
              id="unitNumber"
              placeholder="Unit Number"
              value={unitNumber}
              handleBlur={handleBlur}
              handleValue={(e) =>
                setFieldValue("unitNumber", e.currentTarget.value)
              }
              error={touched.unitNumber && errors.unitNumber}
            />
          </div>
        </StyledRow>
        <StyledRow>
          <div>
            <Input
              id="city"
              placeholder="City"
              value={city}
              handleBlur={handleBlur}
              handleValue={(e) => setFieldValue("city", e.currentTarget.value)}
              error={touched.city && errors.city}
            />
          </div>
          <div>
            <Input
              id="state"
              placeholder="State"
              value={state}
              handleBlur={handleBlur}
              handleValue={(e) => setFieldValue("state", e.currentTarget.value)}
              error={touched.state && errors.state}
            />
          </div>
        </StyledRow>
        <StyledRow>
          <div>
            <Input
              id="country"
              placeholder="Country"
              value={country}
              handleBlur={handleBlur}
              handleValue={(e: { currentTarget: { value: any } }) =>
                setFieldValue("country", e.currentTarget.value)
              }
              error={touched.country && errors.country}
            />
          </div>
          <div>
            <Input
              id="postalCode"
              placeholder="Postal Code"
              value={postalCode}
              handleBlur={handleBlur}
              handleValue={(e) =>
                setFieldValue("postalCode", e.currentTarget.value)
              }
              error={touched.postalCode && errors.postalCode}
            />
          </div>
        </StyledRow>
        <div>
          <Input
            id="phoneNumber"
            placeholder="Phone Number"
            value={phoneNumber}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("phoneNumber", e.currentTarget.value)
            }
            error={touched.phoneNumber && errors.phoneNumber}
          />
        </div>
        {/* <div>
          <Input
            id="emailAddress"
            placeholder="Email Address"
            value={emailAddress}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("emailAddress", e.currentTarget.value)
            }
            error={touched.emailAddress && errors.emailAddress}
          />
        </div> */}

        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            style={{
              background: "transparent",
              color: "#fff",
              fontSize: "14px",
              padding: "8px 16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: 6,
              border: "1px solid #fff",
            }}
            onClick={() => {
              close();
            }}
          >
            Skip
          </button>
          <button
            style={{
              background: "#FFF",
              fontSize: "14px",
              padding: "8px 28px",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            type="submit"
          >
            Add
          </button>
        </div>
      </Modal>
    </form>
  );
};
