import { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import TextArea from "src/components/TextArea/TextArea";
import { InitType, TempFilesType } from "../EvidenceForm";
import { EvidenceElement, EvidenceElementsHead } from "../styled";
import { StyledText } from "src/components/Text/styled";
import { StyledInput } from "src/components/Input/styled";
import { editAnswer } from "src/slices/answersSlice";
import { askBotType } from "src/types";

const StepFullForm: FC<{
  currentStep: number;
  textareas: {
    label: string;
    fieldName: string;
    hasCheckbox?: boolean;
    askOvieType?: askBotType;
  }[];
  values: InitType;
  setFieldValue: (name: string, value: TempFilesType[] | string) => void;
}> = ({ currentStep, textareas, values, setFieldValue }) => {
  const answer: any = useAppSelector((state) => state.answers.value);
  const {
    firstName,
    lastName,
    phone,
    email,
    primaryTextarea,
    secondaryTextarea,
  } = values;

  const dispatch = useAppDispatch();

  return (
    <>
      <EvidenceElementsHead flexDirection="column">
        <div className="labels_wrap">
          <StyledText size={14} weight={500}>
            What is the name of {currentStep === 1 ? "witness" : "expert"}?
          </StyledText>
          <StyledText size={14} weight={500}>
            What is the {currentStep === 1 ? "witness" : "expert"} contact info?
          </StyledText>
        </div>
        <EvidenceElement>
          <div>
            <StyledInput
              placeholder="First Name"
              hasError={false}
              hasLabel={false}
              value={firstName}
              bgColor={"#1F323F"}
              onChange={(e) => {
                const vals = e.currentTarget.value.replace(/[^a-zA-Z s]/, "");
                setFieldValue("firstName", vals);
                dispatch(
                  editAnswer({
                    id: currentStep,
                    name: "firstName",
                    answer: vals,
                    beId: answer[currentStep].questions[1].beId
                      ? answer[currentStep].questions[1].beId
                      : "",
                    sectionMappingId: answer[currentStep].questions[1]
                      .sectionMappingId
                      ? answer[currentStep].questions[1].sectionMappingId
                      : "",
                  })
                );
              }}
            />
          </div>
          <div>
            <StyledInput
              placeholder="Phone"
              hasError={false}
              hasLabel={false}
              bgColor={"#1F323F"}
              value={phone}
              onChange={(e) => {
                const vals = e.currentTarget.value.replace(/[^\d.-]+/g, "");
                setFieldValue("phone", vals);
                dispatch(
                  editAnswer({
                    id: currentStep,
                    name: "phone",
                    answer: vals,
                    beId: answer[currentStep].questions[3].beId
                      ? answer[currentStep].questions[3].beId
                      : "",
                    sectionMappingId: answer[currentStep].questions[1]
                      .sectionMappingId
                      ? answer[currentStep].questions[1].sectionMappingId
                      : "",
                  })
                );
              }}
            />
          </div>
        </EvidenceElement>
        <EvidenceElement>
          <div>
            <StyledInput
              placeholder="Last Name"
              hasError={false}
              hasLabel={false}
              value={lastName}
              bgColor={"#1F323F"}
              onChange={(e) => {
                const vals = e.currentTarget.value.replace(/[^a-zA-Z s]/, "");
                setFieldValue("lastName", vals);
                dispatch(
                  editAnswer({
                    id: currentStep,
                    name: "lastName",
                    answer: vals,
                    beId: answer[currentStep].questions[2].beId
                      ? answer[currentStep].questions[2].beId
                      : "",
                    sectionMappingId: answer[currentStep].questions[1]
                      .sectionMappingId
                      ? answer[currentStep].questions[1].sectionMappingId
                      : "",
                  })
                );
              }}
            />
          </div>
          <div>
            <StyledInput
              placeholder="Email"
              hasError={false}
              hasLabel={false}
              bgColor={"#1F323F"}
              value={email}
              type={"email"}
              onChange={(e) => {
                setFieldValue("email", e.currentTarget.value);
                dispatch(
                  editAnswer({
                    id: currentStep,
                    name: "email",
                    answer: e.currentTarget.value,
                    beId: answer[currentStep].questions[4].beId
                      ? answer[currentStep].questions[4].beId
                      : "",
                    sectionMappingId: answer[currentStep].questions[1]
                      .sectionMappingId
                      ? answer[currentStep].questions[1].sectionMappingId
                      : "",
                  })
                );
              }}
            />
          </div>
        </EvidenceElement>
      </EvidenceElementsHead>
      {textareas.map(({ label, fieldName, hasCheckbox, askOvieType }, i) => (
        <TextArea
          currentStep={currentStep}
          label={label}
          fieldName={fieldName}
          hasCheckbox={hasCheckbox}
          value={i === 0 ? primaryTextarea : secondaryTextarea}
          setFieldValue={(name: string, value: TempFilesType[] | string) =>
            setFieldValue(name, value)
          }
          askOvieType={askOvieType}
        />
      ))}
    </>
  );
};

export default StepFullForm;
