import { configureStore } from "@reduxjs/toolkit";

import attachmentsReducer from "src/slices/attachmentsSlice";
import activeTabReducer from "src/slices/activeTabSlice";
import exhibitsReducer from "src/slices/exhibitsSlice";
import documentsReducer from "src/slices/documentsSlice";
import answersReducer from "src/slices/answersSlice";
import chatBotReducer from "src/slices/chatBotSlice";
import pdfDocReducer from "src/slices/pdfDocSlice";
import currentIssueSlice from "../slices/currentIssue";
import communityAnswersReducer from "src/slices/communityAnswersSlice";
import issuesReducer from "src/slices/issuesSlice";
import briefsSlice from "../slices/briefsSlice";
import { userLoginReducer } from "./reducers/UserReducer";

const store = configureStore({
  reducer: {
    documents: documentsReducer,
    attachments: attachmentsReducer,
    exhibits: exhibitsReducer,
    briefs: briefsSlice,
    activeTab: activeTabReducer,
    answers: answersReducer,
    chatBot: chatBotReducer,
    communityAnswers: communityAnswersReducer,
    pdfDoc: pdfDocReducer,
    currentIssue: currentIssueSlice,
    issues: issuesReducer,
    userLogin: userLoginReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
