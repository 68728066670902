/* eslint-disable no-empty-pattern */
import {
  FC,
  useEffect,
  // useRef,
  useState,
} from "react";
import PSPDFKit, { Instance } from "pspdfkit";
import { IDocumentViewerProps } from "src/types";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useParams } from "react-router";
import Storage from "../../utils/Storage";
// import { StyledContextMenu, StyledContextMenuItem } from "./styled";
// import instance from "./../../services/api";

const DocumentViewer: FC<IDocumentViewerProps> = (props) => {
  const {
    containerRef,
    setContextMenuPosition,
    data,
    deleteMethod,
    onDataUpdate,
    // contextMenuPosition,
    // onMouseUp,
    // onMouseDown,
  } = props;
  let pdfBuffer: any = null;
  // const containerRef1 = useRef<any>(null);
  const [instance, setInstance] = useState<Instance | null>(null);
  const { id } = useParams();
  const loadProtectedPDF = async (documentUrl: string, token: any) => {
    const container: any = containerRef.current;
    const headers = new Headers();
    headers.set("Authorization", `Bearer ${token?.token}`);
    const pdfResponse = await fetch(documentUrl, { headers });
    pdfBuffer = await pdfResponse.arrayBuffer();
    const ins = await PSPDFKit.load({
      document: pdfBuffer,
      container,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
    });
    setInstance(ins);
    return ins;
  };
  useEffect(() => {
    if (data.length) {
      setTimeout(async () => {
        // if (containerRef.current) {
        for (let index = 0; index < data.length; index++) {
          if (!instance) {
            return;
          }
          onDataUpdate(instance);
          // const annotationsData = await containerRef.current.getAnnotations(0);
          // const annotation = annotationsData.get(0);
          // // const updatedAnnotation = annotation.set("opacity", 0.5);
          // // await containerRef.current.update(updatedAnnotation);
          // console.log(
          //   annotationsData,
          //   annotationsData && annotationsData.get("size")
          // );
          // console.log(annotation && annotation.get("id"));
          const annotations = [
            new PSPDFKit.Annotations.RectangleAnnotation({
              pageIndex: data[index].pageNumber - 1,
              strokeColor: PSPDFKit.Color.RED,
              strokeWidth: 1,
              name: "",
              id: data[index].id,
              boundingBox: new PSPDFKit.Geometry.Rect({
                left:
                  JSON.parse(data[index]?.boundingBox)?.y0 *
                  Number(instance.pageInfoForIndex(index)?.width),
                top:
                  JSON.parse(data[index]?.boundingBox)?.x0 *
                  Number(instance.pageInfoForIndex(index)?.height),
                width:
                  JSON.parse(data[index]?.boundingBox)?.y1 *
                  Number(instance.pageInfoForIndex(index)?.width),
                height:
                  JSON.parse(data[index]?.boundingBox)?.x1 *
                  Number(instance.pageInfoForIndex(index)?.height),
              }),
            }),
          ];

          containerRef?.current?.create(annotations);
          if (data[index].subIssue && data[index].subIssue.length) {
            data[index].subIssue.map((row: any) => {
              const annotations = [
                new PSPDFKit.Annotations.RectangleAnnotation({
                  pageIndex: row.pageNumber - 1,
                  strokeColor: PSPDFKit.Color.RED,
                  strokeWidth: 1,
                  name: data[index].id,
                  id: row.id,
                  boundingBox: new PSPDFKit.Geometry.Rect({
                    left:
                      JSON.parse(data[index]?.boundingBox)?.y0 *
                      Number(instance.pageInfoForIndex(index)?.width),
                    top:
                      JSON.parse(data[index]?.boundingBox)?.x0 *
                      Number(instance.pageInfoForIndex(index)?.height),
                    width:
                      JSON.parse(data[index]?.boundingBox)?.y1 *
                      Number(instance.pageInfoForIndex(index)?.width),
                    height:
                      JSON.parse(data[index]?.boundingBox)?.x1 *
                      Number(instance.pageInfoForIndex(index)?.height),
                  }),
                }),
              ];
              containerRef?.current?.create(annotations);
              return true;
            });
          }
        }
        // }
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, data, instance]);

  useEffect(() => {
    // const container: any = containerRef1.current;
    const Token: any = Storage.getTokens();
    (async function () {
      containerRef.current = await loadProtectedPDF(
        `${process.env.REACT_APP_APIURL}${COMMON_ENDPOINT.file}/${id}`,
        Token
      );
      const items = containerRef.current.toolbarItems;
      // containerRef.current.setToolbarItems([]);
      containerRef.current.setToolbarItems(
        items.filter((item: any) => item.type === "sidebar-thumbnails")
      );
      // console.log(items);

      containerRef.current.addEventListener(
        "annotations.create",
        function (createdAnnotations: any) {
          // console.log("New annotations got created", createdAnnotations.toJS());
          if (createdAnnotations.toJS()?.[0]?.id?.length !== 36) {
            setContextMenuPosition({
              type: "add",
              x: createdAnnotations.toJS()?.[0]?.boundingBox.top,
              y: createdAnnotations.toJS()?.[0]?.boundingBox.left,
              value: createdAnnotations.toJS(),
            });
          }
        }
      );
      containerRef.current.addEventListener(
        "annotations.update",
        function (updatedAnnotations: any) {
          console.log("Annotations got updated", updatedAnnotations.toJS());
          setContextMenuPosition({
            type: "update",
            x: updatedAnnotations.toJS()?.[0]?.boundingBox.top,
            y: updatedAnnotations.toJS()?.[0]?.boundingBox.left,
            value: updatedAnnotations.toJS(),
          });
        }
      );
      containerRef.current.addEventListener(
        "annotations.delete",
        function (deletedAnnotations: any) {
          console.log("Annotations got deleted", deletedAnnotations.toJS());
          // if (contextMenuPosition?.value === "edit") {
          deleteMethod(deletedAnnotations.toJS() as any);
          // }
        }
      );
    })();

    // return () => {
    //   PSPDFKit && PSPDFKit.unload(containerRef.current);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "calc(100vh - 100px)",
        }}
      />
    </>
  );
};

export default DocumentViewer;
