import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { StyledRow } from "src/components/Input/styled";
import { addNewData, getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { alertOptions } from "src/utils/helper";
import { initialValues, validationSchema } from "./herlper";
import Input from "src/components/Input/Input";
import { useNavigate } from "react-router";

const LawFirm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  // const params = useParams();
  const submitHandler = () => {
    if (
      !firstName &&
      !lastName &&
      !firmName &&
      !email &&
      !streetNumber &&
      !unitType &&
      !unitNumber &&
      !city &&
      !phoneNumber &&
      !state &&
      !postalCode
    ) {
      toast.error("Preparer's Information", alertOptions);
    } else {
      setLoading(true);
      const bodyData = {
        ...(rowId ? { id: rowId } : ""),
        firstName: firstName,
        lastName: lastName,
        firmName: firmName,
        street: streetNumber,
        unitType: unitType,
        unitNumber: unitNumber,
        city: city,
        country: country,
        phoneNumber: phoneNumber,
        email: email,
        state: state,
        postalCode: postalCode,
      };
      addNewData(bodyData, COMMON_ENDPOINT.PreparerInfo)
        .then((res) => {
          setLoading(false);
          toast.success(
            rowId
              ? "Preparer's Information updated successfully"
              : "Preparer's Information added successfully",
            alertOptions
          );
          getList();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  const {
    values: {
      firstName,
      lastName,
      email,
      firmName,
      streetNumber,
      unitType,
      unitNumber,
      city,
      country,
      phoneNumber,
      state,
      postalCode,
    },
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = formik;

  const getList = () => {
    setLoading(true);
    getAllListData(`${COMMON_ENDPOINT.preparerGet}`)
      .then((res: { data: string | any[] }) => {
        if (res && res.data && res.data.length) {
          setRowId(res && res.data && res?.data[0]?.id);
          setFieldValue("firstName", res?.data[0]?.firstName);
          setFieldValue("lastName", res?.data[0]?.lastName);
          setFieldValue("firmName", res?.data[0]?.firmName);
          setFieldValue("email", res?.data[0]?.email);
          setFieldValue("streetNumber", res?.data[0]?.street);
          setFieldValue("unitType", res?.data[0]?.unitType);
          setFieldValue("unitNumber", res?.data[0]?.unitNumber);
          setFieldValue("city", res?.data[0]?.city);
          setFieldValue("state", res?.data[0]?.state);
          setFieldValue("country", res?.data[0]?.country);
          setFieldValue("postalCode", res?.data[0]?.postalCode);
          setFieldValue("phoneNumber", res?.data[0]?.phoneNumber);
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        console.log(e);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <div className="preparer-form">
        <div className="preparer-header">
          Preparer's Information
          <div style={{ display: "flex" }}>
            <button
              style={{
                background: "transparent",
                color: "#fff",
                fontSize: "14px",
                padding: "8px 16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 6,
                border: "1px solid #fff",
              }}
              onClick={() => {
                navigate("/responder/cases");
              }}
            >
              Cancel
            </button>
            <button
              style={{
                marginLeft: "25px",
                background: "#FFF",
                fontSize: "14px",
                padding: "8px 28px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              form="preparer-form"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
        <div className="preparer-main">
          <form onSubmit={handleSubmit} id="preparer-form">
            <StyledRow>
              <div>
                <Input
                  id="firstName"
                  placeholder="First name"
                  value={firstName}
                  handleBlur={handleBlur}
                  handleValue={(e: any) => {
                    const vals = e.currentTarget.value.replace(
                      /[^a-zA-Z s]/,
                      ""
                    );
                    setFieldValue("firstName", vals);
                  }}
                  error={touched.firstName && errors.firstName}
                />
              </div>
              <div>
                <Input
                  id="lastName"
                  placeholder="Last name"
                  value={lastName}
                  handleBlur={handleBlur}
                  handleValue={(e: any) =>
                    setFieldValue("lastName", e.currentTarget.value)
                  }
                  error={touched.lastName && errors.lastName}
                />
              </div>
            </StyledRow>
            <div>
              <Input
                id="firmName"
                placeholder="Firm Name"
                value={firmName}
                handleBlur={handleBlur}
                handleValue={(e: any) => {
                  const vals = e.currentTarget.value.replace(/[^a-zA-Z s]/, "");
                  setFieldValue("firmName", vals);
                }}
                error={touched.firmName && errors.firmName}
              />
            </div>
            <div>
              <Input
                id="email"
                placeholder="Email"
                value={email}
                handleBlur={handleBlur}
                handleValue={(e: { currentTarget: { value: any } }) =>
                  setFieldValue("email", e.currentTarget.value)
                }
                error={touched.email && errors.email}
              />
            </div>
            <div>
              <Input
                id="streetNumber"
                placeholder="Street Number & Name"
                value={streetNumber}
                handleBlur={handleBlur}
                handleValue={(e: { currentTarget: { value: any } }) =>
                  setFieldValue("streetNumber", e.currentTarget.value)
                }
                error={touched.streetNumber && errors.streetNumber}
              />
            </div>
            <StyledRow>
              <div>
                <Input
                  id="unitType"
                  placeholder="Unit Type"
                  value={unitType}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("unitType", e.currentTarget.value)
                  }
                  error={touched.unitType && errors.unitType}
                />
              </div>
              <div>
                <Input
                  id="unitNumber"
                  placeholder="Unit Number"
                  value={unitNumber}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("unitNumber", e.currentTarget.value)
                  }
                  error={touched.unitNumber && errors.unitNumber}
                />
              </div>
            </StyledRow>
            <StyledRow>
              <div>
                <Input
                  id="city"
                  placeholder="City"
                  value={city}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("city", e.currentTarget.value)
                  }
                  error={touched.city && errors.city}
                />
              </div>
              <div>
                <Input
                  id="state"
                  placeholder="State"
                  value={state}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("state", e.currentTarget.value)
                  }
                  error={touched.state && errors.state}
                />
              </div>
            </StyledRow>
            <StyledRow>
              <div>
                <Input
                  id="country"
                  placeholder="Country"
                  value={country}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("country", e.currentTarget.value)
                  }
                  error={touched.country && errors.country}
                />
              </div>
              <div>
                <Input
                  id="postalCode"
                  placeholder="Postal Code"
                  value={postalCode}
                  handleBlur={handleBlur}
                  handleValue={(e: { currentTarget: { value: any } }) =>
                    setFieldValue("postalCode", e.currentTarget.value)
                  }
                  error={touched.postalCode && errors.postalCode}
                />
              </div>
            </StyledRow>
            <div>
              <Input
                id="phoneNumber"
                placeholder="Phone Number"
                value={phoneNumber}
                handleBlur={handleBlur}
                handleValue={(e: { currentTarget: { value: any } }) =>
                  setFieldValue("phoneNumber", e.currentTarget.value)
                }
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </div>
            {/* <div>
          <Input
            id="emailAddress"
            placeholder="Email Address"
            value={emailAddress}
            handleBlur={handleBlur}
            handleValue={(e) =>
              setFieldValue("emailAddress", e.currentTarget.value)
            }
            error={touched.emailAddress && errors.emailAddress}
          />
        </div> */}
          </form>
        </div>
      </div>
    </>
  );
};
export default LawFirm;
