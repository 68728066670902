import { FC, ReactNode, useRef } from "react";
import {
  StyledModal,
  StyledDarkBg,
  StyledModal2,
  StyledModalContainer2,
} from "./styled";
import closeimg from "../../assets/images/icons8-close (1).svg";

const Modal: FC<{
  handleClose?: any;
  hasLogo?: boolean;
  Prepareinfo?: boolean;
  buildModel?: boolean;
  children: ReactNode;
}> = ({ handleClose, hasLogo, children, Prepareinfo, buildModel }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // const handleClickOutside = (event: MouseEvent) => {
  //   const { target } = event;
  //   if (target instanceof Node && !modalRef.current?.contains(target)) {
  //     handleClose();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  if (Prepareinfo) {
    return (
      <StyledModalContainer2 hasBg={hasLogo}>
        {/* <StyledDarkBg hasBg={hasLogo} /> */}
        <div className="popovercontainer">
          <StyledModal2 ref={modalRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              {/* <img
                src={closeimg}
                alt="df"
                style={{ width: "20px", height: "20px" }}
              /> */}
            </div>
            {children}
          </StyledModal2>
        </div>
      </StyledModalContainer2>
    );
  }
  return (
    <>
      <StyledDarkBg hasBg={hasLogo} />
      <StyledModal ref={modalRef} active={buildModel ? true : false}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <img
            src={closeimg}
            alt="df"
            style={{ width: "13px", height: "15px" }}
          />{" "}
        </div>
        {children}
      </StyledModal>
    </>
  );
};

export default Modal;
