import styled from "styled-components";

export const StyledFileUploadButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    width: fit-content;
    padding: 8px 18px;
  }

  & > input {
    display: none;
  }

  & > span {
    font-family: 'LibreFranklin-Regular', sans-serif;
    font-size: 12px;
    color: rgb(172, 189, 202);
    padding: 6px;
  }
`;