import { FC } from "react";
import { useAppSelector } from "src/hooks";
import Question from "./Question";

const QuestionnaireSummary: FC<{ currentStep: number }> = ({ currentStep }) => {
  const answers = useAppSelector((state) => state.answers.value);

  return (
    <>
      {answers.map(({ id, questions }) => (
        <Question num={id} questions={questions} key={id}/>
      ))}
    </>
  );
};

export default QuestionnaireSummary;
