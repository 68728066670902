import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  ROUTE_DOCUMENT_REVIEW,
  ROUTE_DOCUMENT_WORKSPACE,
  ROUTE_FORGOT,
  ROUTE_QUESTIONNAIRE,
  ROUTE_RESETPASSWORD,
  ROUTE_RESPONDER,
  // ROUTE_RESPONDER_ASSEMBLE,
  ROUTE_RESPONDER_CASES_NEW,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_VERIFICATION,
} from "../routes";

import MainLayout from "../components/MainLayout/MainLayout";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Verification from "../pages/Verification";
import Questionnaire from "../pages/Questionnaire";
import Responder from "../pages/Responder";
import AddCaseModal from "../components/AddCaseModal/AddCaseModal";
import DocumentReview from "../components/DocumentReview/DocumentReview";
import DocumentWorkspace from "../components/DocumentWorkspace/DocumentWorkspace";
import { useAppSelector } from "../hooks";
import Forgot from "src/pages/ForgotPassword/Forgot";
import Create from "src/pages/CreatePassword/Create";

const AppRoute = () => {
  const { isLoggedIn }: any = useAppSelector((store: any) => store.userLogin);
  return isLoggedIn ? (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to={ROUTE_RESPONDER} />} />
        <Route index path="/" element={<Navigate to={ROUTE_RESPONDER} />} />
        <Route
          path={`${String(ROUTE_DOCUMENT_REVIEW)}/:id`}
          element={<MainLayout Component={DocumentReview} />}
        />
        <Route
          path={`${ROUTE_RESPONDER}/*`}
          element={<MainLayout Component={Responder} />}
        />
        <Route
          path={`${String(ROUTE_QUESTIONNAIRE)}/:id`}
          element={<MainLayout Component={Questionnaire} />}
        />
        <Route path={ROUTE_RESPONDER_CASES_NEW} Component={AddCaseModal} />
        <Route
          path={`${String(ROUTE_DOCUMENT_WORKSPACE)}/:id`}
          element={<MainLayout Component={DocumentWorkspace} hasBg={false} />}
        />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to={ROUTE_SIGN_IN} />} />
        <Route index path="/" element={<Navigate to={ROUTE_SIGN_IN} />} />
        <Route
          path={ROUTE_VERIFICATION}
          element={<MainLayout Component={Verification} />}
        />
        <Route
          path={ROUTE_FORGOT}
          element={<MainLayout Component={Forgot} />}
        />
        <Route
          path={ROUTE_RESETPASSWORD}
          element={<MainLayout Component={Create} />}
        />
        <Route
          path={ROUTE_SIGN_IN}
          element={<MainLayout Component={SignIn} />}
        />
        <Route
          path={ROUTE_SIGN_UP}
          element={<MainLayout Component={SignUp} />}
        />
      </Routes>
    </Router>
  );
};

export default AppRoute;
