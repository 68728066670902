import { FC, useState } from "react";
import { StyledText } from "src/components/Text/styled";
import { StyledHeader } from "../styled";
import UploadFile from "../UploadButton/UploadButton";
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const AssembleHeader: FC<{
  title?: string;
  hasSearch?: boolean;
  getData?: any;
  documents?: any;
  searchData?: any;
}> = ({ title, hasSearch, getData, documents, searchData }) => {
  const [value, setValue] = useState("");
  const handleChange = (data: string) => {
    searchData(data);
    setValue(data);
  };

  return (
    <StyledHeader>
      <StyledText color="#111" weight={700}>
        {title}
      </StyledText>
      {hasSearch && (
        <>
          <div>
            <TextField
              size="small"
              placeholder="Search Document"
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {value && (
                      <IconButton onClick={() => handleChange("")} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <UploadFile getData={getData} documents={documents} />
          </div>
        </>
      )}
    </StyledHeader>
  );
};

export default AssembleHeader;
