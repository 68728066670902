import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  askBotType,
  BotRequest,
  BotResponse,
  BotResponseItem,
  chatBotState,
} from "../types";
import axios, { AxiosResponse } from "axios";

const BOT_ANSWER_URL =
  "http://ec2-34-205-203-211.compute-1.amazonaws.com:8000/answer_";

const initialState: chatBotState = {
  messages: [
    // {
    //   title: "",
    //   text: "",
    // },
  ],
};

export const askChatBotTC = createAsyncThunk(
  "chatBot/ask",
  async (
    {
      msgType,
      issue,
    }: {
      msgType: askBotType;
      issue: string;
    },
    { rejectWithValue }
  ) => {
    let url = BOT_ANSWER_URL + msgType.toLowerCase();
    let res = await axios.post<BotRequest, AxiosResponse<BotResponse>>(url, {
      issue,
      num_responses: 3,
    });

    if (res && res.data) {
      let field = Object.keys(res.data).filter(
        (a) => res.data[a] !== null && res.data[a] !== undefined
      )[0];

      return res.data[field].map((el: BotResponseItem) => ({
        text: el.page_content,
        title: issue,
      }));
    } else {
      rejectWithValue(null);
    }
  }
);

export const chatBot = createSlice({
  name: "chatBot",
  initialState,
  reducers: {
    replaceBotMessage: (
      state,
      action: PayloadAction<{
        title: string;
        text: string;
      }>
    ) => {
      state.messages = [action.payload];
    },

    addNewMessage: (state, action: PayloadAction<any>) => {
      state.messages = [...state.messages, ...action.payload!];
    },
    emptyMessage: (state) => {
      state.messages = [];
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(askChatBotTC.fulfilled, (state, action) => {
  //     state.messages = [...state.messages, ...action.payload!];
  //   });
  // },
});

export const { replaceBotMessage, emptyMessage, addNewMessage } =
  chatBot.actions;

export default chatBot.reducer;
