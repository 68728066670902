import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks";
import Accordion from "../Accordion/Accodrion";
import AssembleArea from "./AssembleArea";
import Document from "../Document";
import { StyledAssembleWrap } from "../styled";
import { StyledAssembleBtn } from "../../../components/DocumentWorkspace/styled";
import { ReactComponent as Assemble } from "../../../assets/images/Assemble.svg";
import { StyledText } from "../../../components/Text/styled";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useEffect, useState } from "react";
import { deleteData, getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { toast } from "react-toastify";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Modal from "src/components/Modal";
import { Table } from "../CaseDetail/styled";
import moment from "moment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { downloadFile } from "src/helpers";
import { Checkbox } from "@mui/material";
import _ from "lodash";

const CaseAssemble = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const activeTab = useAppSelector((state) => state.activeTab.value);
  const [documents, setDocuments] = useState<any>();
  const [assembleDoc, setAssembleDoc] = useState<any>();
  const [assembleAllDoc, setAssembleAllDoc] = useState<any>();
  const [previousBuildList, setPreviousBuildList] = useState<any>({
    open: false,
    totalCount: 0,
    list: [],
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState<{
    open: boolean;
    id: string;
  }>({
    open: false,
    id: "",
  });
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState<boolean>(false);
  // const documents = useAppSelector((state) => state.documents.value);
  const [search, setSearch] = useState("");

  const searchData = (data: string) => setSearch(data);

  const getData = () => {
    setLoading(true);
    documentData();
    getAllListData(
      `${COMMON_ENDPOINT.assembleGet}?uploadId=${id}&type=${
        activeTab === "Attachments" ? "attachment" : activeTab.toLowerCase()
      }`
    )
      .then((res) => {
        setAssembleDoc("");
        setAssembleDoc(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    getAllListData(`${COMMON_ENDPOINT.assembleGet}?uploadId=${id}`)
      .then((res) => {
        setAssembleAllDoc("");
        setAssembleAllDoc(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const assembleDocument = () => {
    setLoading(true);
    getAllListData(`${COMMON_ENDPOINT.assembleAll}?uploadId=${id}`, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "assembleAllDoc.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          toast.success("File Downloaded");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const fileUploadCheck = (id: string): boolean => {
    let found = false;
    assembleAllDoc?.forEach((data: any) => {
      const childDocs = data.assembleExhibitChildDocs || [];
      const allDocs = [data, ...childDocs];
      if (!found) {
        found = allDocs.some((doc: any) => doc.assembleUploadId === id);
      }
    });
    return found;
  };

  const previousBuild = () => {
    setLoading(true);
    getAllListData(`${COMMON_ENDPOINT.findAssembleAllBuildDoc}?uploadId=${id}`)
      .then((res) => {
        setPreviousBuildList({
          open: true,
          totalCount: res.total,
          list: res.data,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const getFile = (data: any) => {
    setLoading(true);
    getAllListData(
      `${COMMON_ENDPOINT.getAssembleAllFile}?uploadId=${id}&id=${data.id}`,
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => {
        downloadFile(res, data.fileName);
        setLoading(false);
        toast.success(`${data.fileName} downloaded`);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        toast.error(err.message);
      });
  };

  const deleteFile = () => {
    setLoading(true);
    deleteData(deleteModalOpen.id, COMMON_ENDPOINT.getAssembleAllFile)
      .then((res) => {
        toast.success(res.message);
        previousBuild();
        deleteCloseModal();
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const deleteAllDocument = () => {
    setLoading(true);
    const documentIds = _.map(_.reject(documents, { checked: false }), "id");

    deleteData("", COMMON_ENDPOINT.deleteAllAssembleDocs, {
      documentIds,
    })
      .then((res) => {
        toast.success(res.message);
        getData();
        setLoading(false);
        deleteAllCloseModal();
      })
      .catch((err: any) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const handleDeleteAllChecked = (e: any, index: number) => {
    documents[index]["checked"] = e.target.checked;
    setDocuments([...documents]);
  };

  const deleteCloseModal = () =>
    setDeleteModalOpen({
      open: false,
      id: "",
    });

  const deleteAllCloseModal = () => setDeleteAllModalOpen(false);

  const closeModal = () => {
    setPreviousBuildList({
      open: false,
      totalCount: 0,
      list: [],
    });
  };
  const documentData = () => {
    setTimeout(() => {
      setLoading(true);
      getAllListData(
        `${COMMON_ENDPOINT.Assemble}?uploadId=${id}&search=${search}`
      )
        .then((res) => {
          const addCheckValue = res?.data.map((resData: any) => {
            return { ...resData, checked: true };
          });
          setDocuments(addCheckValue);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }, 500);
  };
  useEffect(() => {
    documentData();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    documentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <StyledAssembleBtn onClick={assembleDocument}>
            <Assemble />
            <StyledText size={14} cursor="pointer">
              Assemble
            </StyledText>
          </StyledAssembleBtn>
          <div style={{ display: "flex" }}>
            <StyledAssembleBtn
              style={{
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={previousBuild}
            >
              <StyledText
                size={14}
                style={{
                  cursor: "pointer",
                }}
              >
                Previous Build
              </StyledText>
            </StyledAssembleBtn>
            <StyledAssembleBtn
              onClick={() => documents.length && setDeleteAllModalOpen(true)}
              style={{
                opacity: !documents?.length ? 0.2 : "",
                cursor: !documents?.length ? "not-allowed" : "pointer",
              }}
            >
              <DeleteOutlineIcon
                style={{ fill: "white", height: "15px", width: "15px" }}
              />
              <StyledText
                size={14}
                cursor={!documents?.length ? "not-allowed" : "cursor"}
              >
                Delete all
              </StyledText>
            </StyledAssembleBtn>
          </div>
        </div>

        <StyledAssembleWrap
          style={{
            gridAutoRows: "400px",
            gridAutoFlow: "row",
          }}
        >
          <AssembleArea
            getData={getData}
            documents={documents}
            title="Documents"
            hasSearch
            overflow
            searchData={searchData}
          >
            <div style={{ overflow: "auto", height: "100%" }}>
              {documents &&
                documents?.map((dat: any) => (
                  <Document
                    getData={getData}
                    name={dat.fileName}
                    id={dat.id}
                    caseId={dat.caseId}
                    extension={dat.extension}
                    docType="document"
                    value="assembleUpload"
                    disableClose={fileUploadCheck(dat.id)}
                    // isAttachment={isAttachment}
                    // isExhibit={isExhibit}
                    // isBrief={isBrief}
                    isDraggable={true}
                  />
                ))}
            </div>
          </AssembleArea>
          <AssembleArea hasTabs isDroppable getData={getData}>
            <div style={{ overflow: "auto", height: "88%" }}>
              {assembleDoc &&
                assembleDoc.map((dat: any) => (
                  <Document
                    isAssemble={true}
                    getData={getData}
                    data={dat}
                    name={dat?.fileName}
                    id={dat?.id}
                    caseId={dat?.caseId}
                    extension={dat?.extension}
                    docType="document"
                    // isAttachment={isAttachment}
                    // isExhibit={isExhibit}
                    // isBrief={isBrief}
                    isDraggable={true}
                    activeTab={activeTab}
                  />
                ))}
            </div>
          </AssembleArea>
          <AssembleArea title="Assemble Preview">
            <Accordion assembleDoc={assembleAllDoc} />
          </AssembleArea>
        </StyledAssembleWrap>
      </DndProvider>
      {previousBuildList?.open && (
        <Modal handleClose={closeModal} buildModel={true}>
          <StyledText textAlign="left" size={22} padding="0 0 20px">
            Previous version
          </StyledText>
          {previousBuildList?.list?.length ? (
            <>
              <Table>
                <div
                  style={{ height: "100%", width: "100%", overflow: "auto" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <th>Document Name</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {Array.isArray(previousBuildList?.list) &&
                      previousBuildList?.list.length &&
                      previousBuildList.list?.map((res: any, index: number) => (
                        <tr
                          style={{
                            backgroundColor: index % 2 ? "#d9d9df" : "",
                          }}
                        >
                          <td
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => getFile(res)}
                          >
                            {res.fileName}
                          </td>
                          <td>{moment(res.createdAt).format("DD-MM-YYYY")}</td>
                          <td>
                            <div style={{ color: "red", cursor: "pointer" }}>
                              <DeleteForeverIcon
                                onClick={() =>
                                  setDeleteModalOpen({
                                    open: true,
                                    id: res.id,
                                  })
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </div>
              </Table>
            </>
          ) : (
            <div
              style={{
                height: "200px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <StyledText size={22}>No Records found</StyledText>
            </div>
          )}
        </Modal>
      )}
      {deleteAllModalOpen && (
        <Modal handleClose={deleteAllCloseModal} buildModel={true}>
          <StyledText textAlign="left" size={22} padding="0 0 20px">
            Delete All
          </StyledText>
          <Table>
            <div style={{ height: "400px", width: "100%", overflow: "auto" }}>
              <thead style={{ position: "sticky", top: 0 }}>
                <th>Document Name</th>
                <th>Created At</th>
                <th>Action</th>
              </thead>
              <tbody>
                {Array.isArray(documents) &&
                  documents?.length &&
                  documents?.map((res: any, index: number) => (
                    <tr style={{ backgroundColor: index % 2 ? "#d9d9df" : "" }}>
                      <td style={{ cursor: "pointer" }}>{res.fileName}</td>
                      <td>{moment(res.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        <Checkbox
                          defaultChecked={res.checked}
                          onChange={(e) => handleDeleteAllChecked(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </div>
          </Table>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              style={{
                width: "100px",
                height: "30px",
                padding: "8px 16px 8px 16px",
                cursor: _.find(documents, { checked: true })
                  ? "pointer"
                  : "not-allowed",
                marginTop: "15px",
                opacity: _.find(documents, { checked: true }) ? 1 : 0.4,
              }}
              onClick={
                _.find(documents, { checked: true }) && deleteAllDocument
              }
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
      {deleteModalOpen.open && (
        <Modal handleClose={deleteCloseModal}>
          <div>
            <div style={{ marginTop: "15px" }}>
              <StyledText size={18} padding="0 0 20px">
                Are you sure you want to delete this file ?
              </StyledText>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  width: "78px",
                  height: "30px",
                  padding: "8px 16px 8px 16px",
                  cursor: "pointer",
                }}
                onClick={deleteCloseModal}
              >
                Cancel
              </button>
              <button
                style={{
                  width: "78px",
                  height: "30px",
                  padding: "8px 16px 8px 16px",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                onClick={deleteFile}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CaseAssemble;
