import Icons from "../../../components/Icons";
import {StyledBtn} from "../styled";
import {Dispatch, FC, SetStateAction} from "react";

interface IBackBtn {
  currentStep: number,
  showForm: boolean,
  setShowForm: Dispatch<SetStateAction<boolean>>,
  setCurrentStep: Dispatch<SetStateAction<number>>
}

export const BackBtn: FC<IBackBtn> = ({ currentStep, showForm, setShowForm, setCurrentStep }) => {
  const backBtnHandleOnClick = () => {
    if (showForm && currentStep === 0) {
      return setShowForm(false)
    }

    if (showForm) {
      return setShowForm(false)
    }

    return setCurrentStep((prevState) => prevState - 1)
  };

  if (currentStep > 0 || showForm) {
    return (
      <StyledBtn onClick={() => backBtnHandleOnClick()}>
        <Icons.WhiteArrowLeft/>
        Back
      </StyledBtn>
    )
  }

  return <></>
}