import { FC, FormEvent, useState, useCallback } from "react";
import {
  StyledInput,
  StyledError,
  StyledInputContainer,
  StyledPlaceholder,
} from "./styled";

interface IInput {
  placeholder: string;
  id: string;
  value?: string;
  type?: string;
  error?: string | boolean;
  handleBlur: (e: FormEvent<HTMLInputElement>) => void;
  handleValue: (e: FormEvent<HTMLInputElement>) => void;
  pattern?: string;
}

const Input: FC<IInput> = ({
  placeholder,
  id,
  type = "text",
  error,
  value,
  handleBlur,
  handleValue,
  pattern,
}) => {
  const [focused, setFocused] = useState(false);

  const onBlur = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      handleBlur(e);

      if (!value) {
        setFocused(false);
      }
    },
    [handleBlur, value]
  );

  return (
    <StyledInputContainer>
      <StyledPlaceholder htmlFor={id} focused={focused} value={value}>
        {placeholder}
      </StyledPlaceholder>
      <StyledInput
        pattern={pattern}
        id={id}
        type={type}
        name={id}
        hasError={!!error}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={onBlur}
        onChange={handleValue}
      />
      {error && <StyledError>{error}</StyledError>}
    </StyledInputContainer>
  );
};

export default Input;
