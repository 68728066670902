import { v4 } from "uuid";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface documentsState {
  value: {
    id: string;
    caseId: string;
    name: string;
    extension: string;
    isAttachment?: boolean;
    isExhibit?: boolean;
    isBrief?: boolean
  }[];
}

const initialState: documentsState = {
  value: [
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "1",
      extension: "pdf",
      isAttachment: true,
    },
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "1",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "Response to RFE NOIR NOIR model",
      id: v4(),
      caseId: "1",
      extension: "docx",
    },
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "2",
      extension: "pdf",
      isAttachment: true,
    },
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "2",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "Response to RFE NOIR NOIR model",
      id: v4(),
      caseId: "2",
      extension: "docx",
    },
    {
      name: "BY BY H-1B full petition.pdf",
      id: v4(),
      caseId: "3",
      extension: "pdf",
      isAttachment: true,
    },
    {
      name: "Response Expert Letter from Martha Ponzi",
      id: v4(),
      caseId: "3",
      extension: "docx",
      isAttachment: true,
    },
    {
      name: "Response to RFE NOIR NOIR model",
      id: v4(),
      caseId: "3",
      extension: "docx",
    },
  ],
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    addDocument: (
      state,
      action: PayloadAction<{
        id: string;
        caseId: string;
        name: string;
        extension: string;
        isAttachment?: boolean;
        isExhibit?: boolean;
        isBrief?: boolean
      }>
    ) => {
      state.value = [...state.value, { ...action.payload }];
    },
    editDocument: (
      state,
      action: PayloadAction<{
        id: string; name: string,
        isAttachment?: boolean;
        isExhibit?: boolean;
        isBrief?: boolean
      }>
    ) => {
      state.value = state.value.map((item) =>
        item.id === action.payload.id
          ? { ...item, ...action.payload }
          : item
      );
    },
    removeDocument: (state, action: PayloadAction<{ id: string }>) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
  },
});

export const { addDocument, editDocument, removeDocument } =
  documentsSlice.actions;

export const selectDocuments = (state: RootState) => state.documents.value;

export default documentsSlice.reducer;
