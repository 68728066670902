import jwtDecode from "jwt-decode";

import { MASTER_ENDPOINT } from "../../utils/Constant";
import { postRequest } from "../../utils/Handlers";

import Storage from "../../utils/Storage";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
} from "../types/UserTypes";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";

export const login = (email: string, password: string, loginType?: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const params = {
      data: {
        email,
        password,
      },
      token: "",
      url: MASTER_ENDPOINT.Login,
    };
    return await postRequest(params)
      .then((response: any) => {
        const token = response?.accessToken;
        const userData: any = jwtDecode(String(token));
        if (token && userData?.id) {
          const tokenData: any = {
            token: token,
            userId: userData.id,
          };
          Storage.setItem("Token", JSON.stringify(tokenData));
        }
        userData["token"] = token;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });
        // toast.success("Login successfully", alertOptions);
      })
      .catch((e: any) => {
        dispatch({
          type: USER_LOGIN_FAIL,
        });
        toast.error(
          e.error.message ? e.error.message : "Oops!!! Something went wrong",
          alertOptions
        );
      });
  };
};

export const refreshToken: any = (token: string) => (dispatch: any) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const logout = () => {
  return (dispatch: any) => {
    dispatch({
      type: USER_LOGOUT,
    });
    Storage.clear();
  };
};
