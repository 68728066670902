import { FC } from "react";
import { ReactComponent as Logo } from "src/assets/images/Logo.svg";
import Avatar from "../Avatar/Avatar";
import { StyledHeader } from "./styled";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/hooks";

const Header: FC<{ hasAvatar?: boolean; className?: string }> = ({
  hasAvatar,
  className = "",
}) => {
  const isLoggedIn: any = useAppSelector(
    (store: any) => store.userLogin.isLoggedIn
  );

  return (
    <>
      <StyledHeader hasAvatar={hasAvatar} className={className}>
        <Link to={"/"}>
          <Logo />
        </Link>
        {isLoggedIn && <Avatar />}
      </StyledHeader>
      <div style={{ marginTop: "70px" }}></div>
    </>
  );
};

export default Header;
