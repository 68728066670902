export const ROUTE_SIGN_IN = "/sign-in";
export const ROUTE_SIGN_UP = "/sign-up";
export const ROUTE_VERIFICATION = "/verification";
export const ROUTE_FORGOT = "/forgot";
export const ROUTE_RESETPASSWORD = "/reset-password";
export const ROUTE_QUESTIONNAIRE = "/questionnaire";
export const ROUTE_DOCUMENT_REVIEW = "/document-review";
export const ROUTE_RESPONDER = "/responder/cases";
export const ROUTE_RESPONDER_ASSEMBLE = "/responder/cases/assemble";
export const ROUTE_RESPONDER_CASES_NEW = `${ROUTE_RESPONDER}/new`;
export const ROUTE_RESPONDER_CASES_ID = `${ROUTE_RESPONDER}/:id`;
export const ROUTE_DOCUMENT_WORKSPACE = "/document-workspace";
