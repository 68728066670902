import { useNavigate } from "react-router-dom";
import { ROUTE_RESPONDER } from "src/routes";
import { StyledWrap } from "../styled";
import { StyledText } from "src/components/Text/styled";

const CaseNav = () => {
  const navigate = useNavigate();
  // const {
  //   state: { name },
  // } = useLocation();

  return (
    <StyledWrap
      justifyContent="flex-start"
      alignItems="center"
      gap={10}
      padding="0 0 22px"
    >
      <StyledText
        onClick={() => navigate(ROUTE_RESPONDER)}
        textAlign="left"
        color="#ACBDCA"
        cursor="pointer"
        size={12}
        lineHeight={24}
      >
        Response Cases
      </StyledText>
      <StyledText color="#ACBDCA">{""}</StyledText>
      <StyledText textAlign="left" size={12} weight={700} lineHeight={24}>
        {/* {name} */}
      </StyledText>
    </StyledWrap>
  );
};

export default CaseNav;
