import { COMMON_ENDPOINT } from "src/utils/Constant";
import AccordionItem from "./AccordionItem";
import { getAllListData } from "src/services/commonServices";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

interface assembleKeys {
  exhibit: any;
  brief: any;
  attachment: any;
  cover: any;
}
interface accordionProps {
  assembleDoc: any;
}

const Accordion = ({ assembleDoc }: accordionProps) => {
  const [assemblePreview, setAssemblePreview] = useState<assembleKeys>({
    exhibit: [],
    brief: [],
    attachment: [],
    cover: [],
  });
  const params = useParams();
  const initialData = () => {
    getAllListData(
      `${COMMON_ENDPOINT.assembleGet}?uploadId=${params?.id}`
    ).then((res: any) => {
      const exhibitData = res?.data?.filter(
        (assembleExhibit: any) => assembleExhibit.type === "exhibit"
      );
      const attachmentData = res?.data?.filter(
        (assembleAttachment: any) => assembleAttachment.type === "attachment"
      );
      const coverData = res?.data?.filter(
        (assembleCover: any) => assembleCover.type === "cover"
      );
      const briefData = res?.data?.filter(
        (assembleBrief: any) => assembleBrief.type === "brief"
      );
      setAssemblePreview({
        exhibit: exhibitData,
        brief: briefData,
        attachment: attachmentData,
        cover: coverData,
      });
    });
  };

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assembleDoc]);

  return (
    <>
      <AccordionItem title="Attachment" content={assemblePreview?.attachment} />
      <AccordionItem title="Cover Page" content={assemblePreview?.cover} />
      <AccordionItem title="Brief" content={assemblePreview?.brief} />
      <AccordionItem title="Exhibit" content={assemblePreview?.exhibit} />
    </>
  );
};

export default Accordion;
