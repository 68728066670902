import styled from "styled-components";

export const StyledOTPInput = styled.span<{
  size?: number;
  color?: string;
  padding?: string;
  family?: string;
  weight?: number;
  textAlign?: string;
  isError: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-bottom: ${({isError}) => isError ? '8px' : '24px'};
`;

export const StyledOTPInputElement = styled.input<{ type?: string, isError: boolean }>`
  height: 58px;
  padding: 16px;
  border: ${({isError}) => (isError ? '1px solid #D13F50' : '1px solid transparent')};
  text-align: center;
  font-size: 16px;
  line-height: 1;
  color: white;
  background: #162632;
  width: ${({ type }) => (type === "checkbox" ? "auto" : "100%")};
`;