import { FC, useEffect } from "react";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "src/hooks";

import StepFile from "./StepFile";
import FileUploadButton from "../FileUploadButton";
import StepFullForm from "./StepFullForm/StepFullForm";
import TextArea from "../TextArea";

import { AttachmentWrapperStyled, EvidenceFormStyled } from "./styled";
import { editAnswer } from "src/slices/answersSlice";
import QuestionnaireSummary from "src/pages/Questionnaire/QuestionnaireSummary/QuestionnaireSummary";
import { askBotType } from "../../types";

export type TempFilesType = {
  fileName: string;
  fileUrl: string;
  description: string;
  file: any;
  fileId: any;
};

export type InitType = {
  files: TempFilesType[];
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  primaryTextarea?: string;
  secondaryTextarea?: string;
};

interface IEvidenceForm {
  id: number;
  isSummary?: boolean;
  isFormSubmitting: boolean;
  hasFileUpload?: boolean;
  inputs?: {
    label: string;
    fields: { fieldName: string; placeholder: string }[];
  }[];
  textareas?: {
    fieldName: string;
    label: string;
    hasCheckbox?: boolean;
    askOvieType?: askBotType;
  }[];
  isFormSubmittedCallBack: (res: boolean) => void;
  hasFilesLengthCallBack: (res: boolean) => void;
}

const EvidenceForm: FC<IEvidenceForm> = ({
  id,
  isSummary,
  isFormSubmitting,
  hasFileUpload,
  inputs,
  textareas,
  isFormSubmittedCallBack,
  hasFilesLengthCallBack,
}) => {
  const dispatch = useAppDispatch();
  const answer = useAppSelector((state) =>
    state.answers.value.find((a) => a.id === id)
  );

  const getValue = (field: string) => {
    let value:
      | string
      | { fileUrl?: string; fileName: string; description?: string }[] = "";

    answer?.questions.forEach(({ name, answer }) => {
      if (name === field) {
        value = answer;
      }
    });
    return value;
  };

  const formik = useFormik({
    initialValues: {
      files: getValue("files") || [],
      firstName: getValue("firstName"),
      lastName: getValue("lastName"),
      phone: getValue("phone"),
      email: getValue("email"),
      primaryTextarea: getValue("primaryTextarea"),
      secondaryTextarea: getValue("secondaryTextarea"),
    } as InitType,
    onSubmit: (values) => {
      let res = !!values.files?.length;
      isFormSubmittedCallBack(res);
    },
  });

  const {
    values: { files, primaryTextarea, secondaryTextarea },
    setFieldValue,
  } = formik;

  const hasFullForm = !!(inputs && textareas);

  const addAttachment = async (fileData: any, fileName: string) => {
    setFieldValue("files", [
      ...files,
      {
        file: fileData,
        fileUrl: "",
        fileName,
        description: "",
      },
    ]);

    dispatch(
      editAnswer({
        id,
        name: "files",
        answer: [
          ...files,
          {
            file: fileData,
            fileUrl: "",
            fileName,
            description: "",
            fileId: "",
          },
        ],
      })
    );
  };

  useEffect(() => {
    if (isFormSubmitting) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitting]);

  useEffect(() => {
    hasFilesLengthCallBack(!!formik.values.files?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, formik.values]);

  useEffect(() => {
    if (answer) {
      formik.setValues({
        files:
          id === 0 ? (answer?.questions[1]?.answer as TempFilesType[]) : [],
        firstName: getValue("firstName"),
        lastName: getValue("lastName"),
        phone: getValue("phone"),
        email: getValue("email"),
        primaryTextarea: getValue("primaryTextarea"),
        secondaryTextarea: getValue("secondaryTextarea"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  return (
    <EvidenceFormStyled onSubmit={formik.handleSubmit} isSummary={isSummary}>
      {hasFileUpload && <FileUploadButton onUploadHandler={addAttachment} />}
      <AttachmentWrapperStyled>
        {hasFileUpload && (
          <StepFile
            id={id}
            files={files}
            setFieldValue={(name: string, value: TempFilesType[] | string) =>
              setFieldValue(name, value)
            }
          />
        )}
        {hasFullForm && (
          <StepFullForm
            currentStep={id}
            textareas={textareas}
            values={formik.values}
            setFieldValue={(name: string, value: TempFilesType[] | string) =>
              setFieldValue(name, value)
            }
          />
        )}
        {!hasFullForm &&
          textareas &&
          textareas.map(({ label, fieldName, hasCheckbox, askOvieType }, i) => (
            <TextArea
              currentStep={id}
              heightNum={fieldName === "secondaryTextarea" ? "100" : ""}
              label={label}
              fieldName={fieldName}
              hasCheckbox={hasCheckbox}
              askOvieType={askOvieType}
              value={i === 0 ? primaryTextarea : secondaryTextarea}
              setFieldValue={(name: string, value: TempFilesType[] | string) =>
                setFieldValue(name, value)
              }
            />
          ))}
      </AttachmentWrapperStyled>
      {isSummary && <QuestionnaireSummary currentStep={id} />}
    </EvidenceFormStyled>
  );
};

export default EvidenceForm;
