import styled from "styled-components";

export const StyledUploadInput = styled.input.attrs(props => ({
  ...props,
  multiple: props.type === "file" && props.multiple ? true : false
}))`
  display: none;
`;

export const StyledUploadButton = styled.label`
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;

  & > svg {
    cursor: pointer;
  }
`;

export const StyledDropLabel = styled.label`
  line-height: 20px; /* 166.667% */
  width: 100%;
  display: block;
  font-size: 14px;
  padding: 0px;
  color: rgb(17, 17, 17);
  font-family: LibreFranklin;
  font-weight: 600;
  cursor: pointer;
  margin: 0px;
  padding: 8px 18px;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  &:hover {
    background: rgba(10, 10, 10, 0.06);
  }
`;

export const StyledUploadDrops = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  border-radius: 4px;
  background: var(--background-paper-elevation-1, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07),
    0px 0px 16px -4px rgba(0, 0, 0, 0.08), 0px 0px 16px -4px rgba(0, 0, 0, 0.08),
    0px 0px 16px -4px rgba(0, 0, 0, 0.08);
  min-width: 230px;
  /* padding: 8px 16px; */
  padding: 6px 0px;
  z-index: 100;
`;

export const StyledDropModal = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  z-index: 99;
`;

export const EditpopupModal = styled.div`
  --background-12: hsla(204, 45%, 11%, 1);
  --text-on-background-high-contrast: hsla(0, 0%, 100%, 1);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  .mainmodalcontainer {
    background: var(--background-12, #0f1e28);
    /* background: #2b404f; */
    padding: 32px;
    padding-bottom: 20px;
    min-width: 320px;
    border-radius: 4px;
    z-index: 300;
    header {
      color: var(--text-on-background-high-contrast, #fff);

      /* H4/Bold */
      font-family: LibreFranklin;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        svg {
          cursor: pointer;
          transform-box: fill-box;
          transform: scale(1.75);
          path {
            fill: #fff !important;
            stroke-width: 5 !important;
          }
        }
      }
    }

    .contentcontainer {
      display: grid;
      grid-template-columns: 200px minmax(300px, 2fr)
        repeat(1, minmax(200px, 1fr));
      align-items: center;
      margin-top: 16px;
      grid-auto-flow: row;
      header {
        grid-area: 1 / 1 / 1 / -1;
        text-align: left;
        text-transform: capitalize;
        padding: 8px 0px;
        padding-bottom: 20px;
        color: var(--text-on-background-high-contrast, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        display: grid;
        /* grid-template-columns: 100px repeat(2, minmax(200px, 1fr)); */
        grid-template-columns: 200px minmax(300px, 2fr)
          repeat(1, minmax(200px, 1fr));
        align-items: center;
      }
      main {
        grid-column: 1/-1;
        grid-auto-flow: row;
        display: grid;
        gap: 22px;
        gap: 6px;
        max-height: 460px;
        overflow: auto;
      }
    }
    .buttoncon {
      margin-top: 16px;
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: 200px minmax(300px, 2fr)
        repeat(1, minmax(200px, 1fr));
      justify-items: start;
      align-items: center;
      .save {
        grid-column: 3/3;
        outline: none;
        border: none;
        margin-left: 30px;
        padding: 8px 8px;
        text-align: center;
        width: 100px;
        cursor: pointer;
        color: var(--primary-tints-0, #fff);
        /* Body (S) text/Medium */
        font-family: LibreFranklin;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        background: #1374ec;
        text-transform: capitalize;
        &:hover {
          background: var(--navy-4, #0f5ab7);
        }
      }
    }
  }
`;

export const Perrow = styled.div`
  padding: 10px 0px;
  display: grid;
  /* grid-template-columns: 100px repeat(2, minmax(200px, 1fr)); */
  grid-template-columns: 200px minmax(300px, 2fr) repeat(1, minmax(200px, 1fr));
  align-items: center;
  span {
    color: var(--text-on-background-high-contrast, #fff);

    /* H4/Bold */
    font-family: LibreFranklin;
    font-size: 14px;
    font-style: normal;
    font-weight: 580;
    line-height: 16px; /* 133.333% */
  }

  .actiondcircle {
    width: 25px;
    height: 25px;
    border: 2px solid #0080ff;
    border-radius: 50%;
    transform: translateX(8px);
    position: relative;
    cursor: pointer;
  }

  .tickstyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -44%);
    pointer-events: none;
    svg {
      width: 18px;
      height: 18px;
      /* transform: translateX(8px); */

      path {
        /* fill: #47a4ff; */
        /* fill: #0080ff; */
        /* fill: #00c853; */
        /* fill: #3399FF; */
        fill: #33bbff;
      }
    }
  }
`;
