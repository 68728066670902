import styled, { css } from "styled-components";

export const StyledQuestion = styled.div<{ padding?: string }>`
  border-radius: 10px;
  padding: ${({ padding }) => padding || "16px 16px 0"};
  background: #2b404f;

  & > ul > li {
    list-style: none;
  }
`;

export const StyledRow = styled.div<{
  isHeader?: boolean;
  hasBorderBottom?: boolean;
}>`
  display: flex;
  background: ${({ isHeader }) => (isHeader ? "#0F1E28" : "#1F323F")};
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom ? "1px solid #4c5361" : "none"};

  ${({ isHeader }) =>
    isHeader &&
    css`
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    `}
  ${({ hasBorderBottom, isHeader }) =>
    !hasBorderBottom &&
    !isHeader &&
    css`
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    `}
  & > span:nth-child(1) {
    width: 50%;
    border-right: ${({ isHeader }) =>
      isHeader ? "none" : "1px solid #4c5361"};
  }
`;

export const StyledSubmitWrapper = styled.div`
  // position: fixed;
  // left: 15vw;
  // width: 69.6vw;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 32px;
  background: #0f1e28;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-top: 1px solid #1f323f;
`;
