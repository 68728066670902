import { ReactComponent as Document } from "src/assets/images/Document.svg";
import { ReactComponent as Close } from "src/assets/images/Close.svg";
import { StyledAttachment, StyledAttachmentText } from "./styled";
import Button from "../Button";

type PropsType = {
  docName: string
  onCloseClick: () => void
}

const Attachment = ({docName, onCloseClick}: PropsType) => {
  return (
    <StyledAttachment>
      <Document/>
      <StyledAttachmentText>{docName}</StyledAttachmentText>
      <Button onClick={onCloseClick}>
        <Close/>
      </Button>
    </StyledAttachment>
  );
};

export default Attachment;