import styled, { css } from "styled-components";

export const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
export const StyledTabs = styled.div`
  // position: fixed;
  // right: 0;
  // top: 88px;
  height: calc(100vh - 88px);
  height: calc(100vh - 67px);
  width: 2.1vw;
  background: #162632;
  border-top: 1px solid #2b404f;
  border-left: 1px solid #2b404f;
  display: flex;
  flex-direction: column;
  // z-index: 2;
`;

export const StyledTab = styled.div<{ active?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 24px;
    background: #0080ff;
    border-radius: 0 2px 2px 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s ease;
  }

  svg path {
    transition: all 0.2s ease;
  }

  &:hover {
    svg path {
      opacity: 0.8;
    }
  }

  ${({ active }) =>
    active
      ? css`
          &:before {
            opacity: 1;
          }

          svg path {
            fill: #0080ff;
          }
        `
      : css`
          svg path {
            fill: #fff;
          }
        `};
`;
