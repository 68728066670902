import styled from "styled-components";

export const StyledTabs = styled.ul`
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;

  & > li {
    list-style: none;
    padding: 14px 8px;
  }
`;

export const StyledListItem = styled.li<{
  hasBorder?: boolean;
  header?: boolean;
}>`
  ${({ hasBorder }) => hasBorder && `border-bottom: 2px solid #111;`}
  ${({ header }) =>
    header && `margin-left:245px; display:flex; color:#002c69; cursor:pointer`}
`;
