import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import Icons from "../../components/Icons";
import { QuestionTwo, QuestionThree, TestData } from "./QuestionnaireTestData";
import QuestionnaireHeader from "./QuestionnaireHead";
import Steps from "./Tabs";
import QuestionnaireAnswers from "./QuestionnaireAnswers";
import QuestionnaireBtns from "./QuestionnaireBtns";
import TabsBar from "../../components/TabsBar";
import {
  StyledFormContainer,
  StyledSection,
  StyledQnaryMainblock,
  StyledGenerateresblock,
} from "./styled";
import { StyledText } from "src/components/Text/styled";
import { ROUTE_DOCUMENT_REVIEW } from "../../routes";
// import { IIssue } from "../../types";
import { emptyMessage } from "../../slices/chatBotSlice";
import { getCommunityAnswersTC } from "../../slices/communityAnswersSlice";
import { StyledSubmitWrapper } from "./QuestionnaireSummary/styled";
import TextArea from "../../components/TextArea";
import { getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { TempFilesType } from "src/components/EvidenceForm/EvidenceForm";
import { editAnswer } from "src/slices/answersSlice";

const Questionnaire = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isFilesLength, setFilesLength] = useState(false);
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [testdata, setTestdata] = useState<any>();
  const dataLength = TestData.length;
  const [loading, setLoading] = useState(false);
  // const [issuesList, setIssuesList] = useState<Array<IIssue>>([]);
  const answer: any = useAppSelector((state) => state.answers.value);
  const [activation, _setActivation] = useState(false);
  // const [rects, setRects] = useState<Array<any>>([]);
  // const [operation, setOperation] = useState<{
  //   type: string;
  //   payload: number | null;
  // }>({ type: "", payload: null });

  const activationRef = useRef(activation);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setActivation = (data: boolean) => {
    activationRef.current = data;
    _setActivation(data);
  };

  const isSummary = currentStep === dataLength - 1;
  useEffect(() => {
    setLoading(true);
    getAllListData(
      `${COMMON_ENDPOINT.question}?id=${
        currentStep === 8 ? 8 : currentStep + 1
      }`
    )
      .then((res) => {
        setTestdata(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
    dispatch(emptyMessage());
    if (currentStep === 0) {
      dispatch(getCommunityAnswersTC(location.state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);
  // console.log(location);
  useEffect(() => {
    setLoading(true);
    const url = location.state.subIssue
      ? `subIssueId=${location.state.data.id}`
      : `issueId=${location.state.data.id}`;
    getAllListData(
      `${COMMON_ENDPOINT.question}/answer?uploadId=${id}&${url}&sectionId=${
        currentStep + 1
      }&order=asc`
    ).then(async (res) => {
      if (currentStep === 0) {
        const data: any = [];
        res.data.map((el: any) =>
          el.sectionMapping.map((en: any) =>
            en.files ? data.push(en.files) : []
          )
        );
        await dispatch(
          editAnswer({
            id: currentStep,
            name: "files",
            answer: data.map((el: any) => {
              return {
                beId: el.id ? el.id : "",
                file: "",
                fileUrl: "",
                fileName: el?.fileName ? el?.fileName : "",
                description: el?.description ? el?.description : "",
                fileId: el.id ? el.id : "",
                sectionMappingId: el.sectionMappingId
                  ? el.sectionMappingId
                  : "",
              };
            }),
          })
        );
      } else if (currentStep === 1) {
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionTwo[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 2) {
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionThree[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 3) {
        const QuestionName: any = ["primaryTextarea", "secondaryTextarea"];
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionName[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 4) {
        const QuestionName: any = ["primaryTextarea", "secondaryTextarea"];
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionName[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 5) {
        const QuestionName: any = ["primaryTextarea", "secondaryTextarea"];
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionName[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 6) {
        const QuestionName: any = ["primaryTextarea", "secondaryTextarea"];
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en.id,
                id: currentStep,
                name: QuestionName[ain],
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      } else if (currentStep === 7) {
        res.data.map((el: any) =>
          el.sectionMapping.map(async (en: any, ain: number) => {
            await dispatch(
              editAnswer({
                beId: en?.id,
                id: currentStep,
                name: "summary",
                answer: en.answer,
                sectionMappingId: en.sectionMappingId,
              })
            );
            return true;
          })
        );
      }
      setLoading(false);
    });
  }, [
    currentStep,
    dispatch,
    id,
    location?.state?.data.id,
    location?.state?.visaType,
    location?.state?.subIssue,
  ]);
  useEffect(() => {
    if (location?.state?.currentStep) {
      setCurrentStep(
        location?.state?.currentStep === 8 ? 7 : location?.state?.currentStep
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledQnaryMainblock>
      {loading && <div className="liberty-loader" />}
      <StyledGenerateresblock>
        <StyledSection isSummary={isSummary}>
          <Link
            to={`${ROUTE_DOCUMENT_REVIEW}/${location?.state?.id}`}
            className="back"
          >
            <Icons.BackArrow /> Back to document
          </Link>
          <QuestionnaireHeader
            data={location.state.data}
            index={location.state.index}
          />
          <Steps currentStep={currentStep} setCurrentStep={setCurrentStep} />
          <StyledText
            size={isSummary ? 16 : 24}
            textAlign={isSummary ? "left" : "center"}
            padding={`${isSummary ? "24" : "72"}px 0 ${
              isSummary ? "0" : "24"
            }px`}
            weight={700}
          >
            {!showForm || currentStep > 2
              ? testdata && testdata[0]?.issueSection[0]?.questionName
              : testdata && testdata[0]?.issueSection[1]?.questionTitle}
            {/* {!showForm || currentStep > 2
            ? TestData[currentStep].mainQuestion
            : TestData[currentStep].question} */}
          </StyledText>
          <QuestionnaireAnswers
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            showForm={showForm}
            setShowForm={setShowForm}
            dataLength={dataLength}
          />
          {showForm || currentStep > 2 ? (
            <StyledFormContainer>
              {TestData && TestData[currentStep]?.stepForm(setFilesLength)}
            </StyledFormContainer>
          ) : (
            <></>
          )}
          {!isSummary ? (
            <QuestionnaireBtns
              showForm={showForm}
              currentStep={currentStep}
              dataLength={dataLength}
              setCurrentStep={setCurrentStep}
              setShowForm={setShowForm}
              isFilesLength={isFilesLength}
            />
          ) : (
            <div style={{ display: "none" }}></div>
          )}
        </StyledSection>
        {isSummary && (
          <StyledSubmitWrapper>
            <TextArea
              visaType={location?.state?.visaType}
              currentStep={currentStep}
              hasCheckbox={false}
              label={"Response Generator"}
              heightNum={"120"}
              value={
                answer[7].questions[0].answer
                  ? answer[7].questions[0].answer
                  : ""
              }
              askOvieType="GenerateResponse"
              fieldName={"summary"}
              setFieldValue={(
                name: string,
                value: TempFilesType[] | string
              ) => {
                dispatch(
                  editAnswer({
                    id: currentStep,
                    name: "summary",
                    answer: value,
                    beId: answer[7].questions[0].beId
                      ? answer[7].questions[0].beId
                      : "",
                    sectionMappingId: answer[7].questions[0].sectionMappingId
                      ? answer[7].questions[0].sectionMappingId
                      : "",
                  })
                );
              }}
            />
            <QuestionnaireBtns
              showForm={showForm}
              currentStep={currentStep}
              dataLength={dataLength}
              setCurrentStep={setCurrentStep}
              setShowForm={setShowForm}
              isFilesLength={isFilesLength}
            />
          </StyledSubmitWrapper>
        )}
      </StyledGenerateresblock>
      <TabsBar />
    </StyledQnaryMainblock>
  );
};

export default Questionnaire;
