import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Upload } from "src/assets/images/Upload.svg";
import { ReactComponent as Document } from "src/assets/images/Document.svg";
import Modal from "../Modal";
// import Header from "../Header";
import {
  StyledLabel,
  StyledForm,
  StyledFileInput,
  StyledUploadArea,
  StyledUploadedArea,
} from "./styled";
import Dropzone from "react-dropzone";
import { StyledText } from "../Text/styled";
import { StyledButton } from "../Button/styled";
import { ROUTE_RESPONDER } from "../../routes";
import { addNewData, getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";
import CustomSelct from "../CustomSelect/CustomSelect";
import Input from "../Input";

const AddCaseModal: FC<{
  handleClose?: () => void;
  setModelOpen?: any;
  getData?: any;
}> = ({ handleClose, setModelOpen, getData }) => {
  const [selectValue, setSelectValue] = useState("");
  const [visaType, setVisaType] = useState([]);
  const navigate = useNavigate();
  const hiddenFileInput: any = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<{ name?: string } | null>();
  const [loading, setLoading] = useState(false);
  const [firmName, setFirmName] = useState("");
  const [beneficiaryFirstName, setBeneficiaryFirstName] = useState("");
  const [beneficiaryLastName, setBeneficiaryLastName] = useState("");

  const onSubmit = () => {
    if (selectValue) {
      if (firmName) {
        if (file) {
          setLoading(true);
          const formData: any = new FormData();
          formData.append("file", file);
          formData.append("visaType", selectValue);
          formData.append("firmName", firmName);
          formData.append("beneficiaryFirstName", beneficiaryFirstName);
          formData.append("beneficiaryLastName", beneficiaryLastName);
          addNewData(formData, COMMON_ENDPOINT.UploadedDoc, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              navigate(ROUTE_RESPONDER);
              // toast.success("Uploaded successfully", alertOptions);
              setLoading(false);
              setSelectValue("");
              setFirmName("");
              setModelOpen(false);
              getData();
            })
            .catch((e) => {
              toast.error(e.message, alertOptions);
              setLoading(false);
            });
        }
      } else {
        toast.error("Firm name is required");
      }
    } else {
      toast.error("Select the visa type");
    }
  };
  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.visaType)
      .then((res) => {
        setVisaType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
  }, []);

  function handleBlur(e: FormEvent<HTMLInputElement>): void {
    // console.log(e);
  }

  return (
    <>
      {loading && <div className="liberty-loader" />}
      {/* <Header className="header header_home" /> */}
      <Modal handleClose={handleClose} hasLogo>
        <StyledForm>
          <StyledText textAlign="left" size={22} padding="0 0 8px">
            Upload your RFE
          </StyledText>
          <StyledText
            textAlign="left"
            color="#ACBDCA"
            padding="0 0 18px"
            size={14}
            lineHeight={24}
          >
            Please upload your RFE to start the Response preparation flow.
          </StyledText>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <CustomSelct
                labelKey={"visaName"}
                valueKey={"visaCode"}
                placeholder={"Select VisaType"}
                handleValue={(e: any) => setSelectValue(e?.target?.value)}
                value={selectValue}
                options={visaType}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Input
                pattern="^(?!.*[A-Z])"
                id=""
                type="text"
                placeholder="Firm Name"
                value={firmName}
                handleBlur={handleBlur}
                handleValue={(e) => {
                  setFirmName(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Input
                pattern="^(?!.*[A-Z])"
                id=""
                type="text"
                placeholder="Beneficiary First Name"
                value={beneficiaryFirstName}
                handleBlur={handleBlur}
                handleValue={(e) => {
                  setBeneficiaryFirstName(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Input
                pattern="^(?!.*[A-Z])"
                id=""
                type="text"
                placeholder="Beneficiary Last Name"
                value={beneficiaryLastName}
                handleBlur={handleBlur}
                handleValue={(e) => {
                  setBeneficiaryLastName(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <StyledText textAlign="left" size={14} padding="0 0 8px">
            Upload your attachment
          </StyledText>
          {file ? (
            <StyledUploadedArea>
              <Document />
              <StyledText size={14} color="#111">
                {file.name}
              </StyledText>
              <button onClick={() => setFile(null)}>+</button>
            </StyledUploadedArea>
          ) : (
            <Dropzone onDrop={onDrop}>
              {({ getRootProps }) => {
                return (
                  <StyledUploadArea
                    {...getRootProps({
                      onClick: (event) => {
                        event.stopPropagation();
                        hiddenFileInput.current.click();
                      },
                    })}
                  >
                    <Upload />
                    <StyledText size={14} weight={600} padding="0 0 4px">
                      Drag and drop your file here
                    </StyledText>
                    <StyledText size={12} color="#ACBDCA" padding="0 0 16px">
                      Supported files: PDF, Word
                    </StyledText>
                    <StyledLabel>Select file</StyledLabel>
                    <StyledFileInput
                      type="file"
                      id="files"
                      ref={hiddenFileInput}
                      onChange={(e: any) => {
                        e.target.files && onDrop(e.target.files);
                      }}
                    />
                  </StyledUploadArea>
                );
              }}
            </Dropzone>
          )}
          <StyledButton hasBg type="button" disabled={!file} onClick={onSubmit}>
            Submit
          </StyledButton>
        </StyledForm>
      </Modal>
    </>
  );
};

export default AddCaseModal;
