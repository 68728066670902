import styled from "styled-components";

export const StyledAttachment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid transparent;

  & svg {
    flex-shrink: 0;
  }
`;

export const StyledAttachmentText = styled.span`
  flex-basis: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'LibreFranklin-Regular', sans-serif;
  font-size: 12px;
`;
