/* eslint-disable @typescript-eslint/no-unused-vars */
// import { ReactComponent as ArrowDown } from "src/assets/images/ArrowDown.svg";
import React, { useState } from "react";
import { StyledAvatar, StyledAvatarWrap } from "./styled";
import { StyledText } from "../Text/styled";
import { useAppSelector } from "src/hooks";
// import Downarrow from "./downarrow";
// import Responder from "../../pages/Responder";

const Avatar = () => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo
  );
  const [open, setopen] = useState<boolean>(false);
  const handleopen: () => void = () => {
    setopen((p: boolean) => !p);
  };
  return (
    <StyledAvatarWrap>
      <StyledAvatar>
        <StyledText size={14} lineHeight={20} onClick={handleopen}>
          {userDetail?.firstName &&
            userDetail?.firstName.charAt(0).toUpperCase("")}
          {userDetail?.lastName &&
            userDetail.lastName.charAt(0).toUpperCase("")}
        </StyledText>
      </StyledAvatar>
      {/* <div
        style={{
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleopen}
      >
        <Downarrow open={open} />
      </div>
      <Dropdown hide={!open}>
        <Responder sidebasasdropdown={true} />
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            backgroundColor: "transparent",
            zIndex: 190,
          }}
          onClick={() => setopen(false)}
        ></div>
      </Dropdown> */}
    </StyledAvatarWrap>
  );
};

export default Avatar;
