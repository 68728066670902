import { FC, ReactNode } from "react";
import { StyledButton } from "./styled";

interface IButton {
  hasBg?: boolean;
  weight?: number;
  children: string | ReactNode;
  onClick?: () => void;
}

const Button: FC<IButton> = ({hasBg, weight, children, onClick}) => (
  <StyledButton hasBg={hasBg} weight={weight} onClick={onClick}>
    {children}
  </StyledButton>
);

export default Button;
