/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import AddNewIssue from "../../assets/images/AddNewIssue.svg";
import AddNewSubissue from "../../assets/images/AddNewSubissue.svg";
import AssignToIssue from "../../assets/images/AssignToIssue.svg";
import {
  StyledContextMenu,
  StyledContextMenuItem,
  StyledDocumentReviewContainer,
  StyledIcon,
  StyledIssueProgressInfoWrapper,
  StyledIssueProgressWrapper,
  StyledIssueTitle,
  StyledProgress,
  StyledProgressInfo,
  StyledSubHeading,
} from "./styled";
import Panel from "./Panel";
import { modifyPdf } from "./modifyPdf";
import DocumentViewer from "./DocumentViewer";
import { RGB, rgb } from "pdf-lib";

import { fabric } from "fabric";
import { ICoordinates, IIssue } from "src/types";
import { Canvas } from "fabric/fabric-impl";
import TabsBar from "../TabsBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTE_RESPONDER } from "../../routes";

import { replaceBotMessage } from "../../slices/chatBotSlice";
// import { replaceAnswer } from "../../slices/communityAnswersSlice";
import { mockData } from "./mockData";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { handleIssues } from "src/slices/issuesSlice";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from "src/services/commonServices";
import { clearAnswer } from "src/slices/answersSlice";
import PSPDFKit, { Instance } from "pspdfkit";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";

const FileViewer: FC = () => {
  const navigate = useNavigate();
  const [pdfDataUri, setPdfDataUri] = useState("");
  const [issueId, setIssueId] = useState("");
  const containerRef: any = useRef(null);
  const issuesList = useAppSelector((state) => state.issues.issues);
  const setIssuesList = (data: Array<IIssue>) =>
    dispatch(handleIssues({ issues: data }));
  const params = useParams();
  // const rectsData: Array<any> = [
  //   [
  //     1,
  //     550, //top
  //     60, //left
  //     200, //height
  //     233, //width
  //     {
  //       type: "RGB",
  //       red: 1,
  //       green: 0.09,
  //       blue: 0.27,
  //     },
  //     {
  //       toIssue: 1,
  //     },
  //   ],
  // ];

  const [activation, _setActivation] = useState(false);
  const [parentData, setParentData] = useState<Instance>();
  const pdfString = useAppSelector((state) => state.pdfDoc.string);
  const pdfPath = useAppSelector((state) => state.pdfDoc.path);
  const [rects, setRects] = useState<Array<any>>([]);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleChildDataUpdate = useCallback(
    (newData: any) => {
      setParentData(newData);
    },
    [parentData]
  );

  useEffect(() => {
    setLoading(true);
    // setRects(rectsData);
    setIssuesList(mockData);
    setOperation({ type: "addBackIssues", payload: null });
  }, []);

  const [contextMenuPosition, _setContextMenuPosition] = useState<{
    type: string;
    x: number;
    y: number;
    value: [];
  } | null>(null);

  const [addNewSubissueContextMenu, setAddNewSubissueContextMenu] =
    useState(false);

  const [assignToIssueContextMenu, setAssignToIssueContextMenu] =
    useState(false);

  const [assignToSubissueContextMenu, setAssignToSubissueContextMenu] =
    useState(false);

  const [pageCoordinates, setPageCoordinates] = useState<ICoordinates>({
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  });

  const [operation, setOperation] = useState<{
    type: string;
    payload: any | null;
  }>({ type: "", payload: null });

  const [color, setColor] = useState<RGB | null>(null);
  useEffect(() => {
    if (rects && pdfString) {
      modifyPdf(
        setPdfDataUri,
        rects,
        pdfString
        // pageCoordinates,
        // pdfPath,
        // canvasCoordinates,
        // scaleRef.current,
        // issuesList,
        // setIssuesList,
        // color,
        // setActivation,
        // operation
      );
    }
  }, [operation]);

  useEffect(() => {
    // dispatch(replaceAnswer([]));
    dispatch(
      replaceBotMessage({
        text:
          "Your uploaded document is shown here, " +
          "You may increase or increase the language to capture the full issue." +
          " You may create additional boxes or additional issues or sub-issues." +
          " You may paraphrase or edit the language of the issue.",
        title: "",
      })
    );
  }, []);

  const [canvas, _setCanvas] = useState<Canvas | null>(null);
  const [rectangle, setRectangle] = useState({ width: 0, height: 0 });
  const [pageHeight, _setPageHeight] = useState(0);
  const [pageWidth, _setPageWidth] = useState(0);
  const [scale, _setScale] = useState(1);
  const [headerprogress, setheaderprogress] = useState<{
    issueProgress: number;
    total: number;
  }>({ issueProgress: 0, total: 0 });

  const [canvasCoordinates, setCanvasCoordinates] =
    useState<ICoordinates | null>(null);
  const [clearCanvas, setClearCanvas] = useState(false);
  const [offset, _setOffset] = useState<number>(0);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState({
    editStatus: false,
    editData: "",
    editId: "",
  });
  const documentViewerRef = useRef<any>();
  const documentViewerRef2 = useRef<any>();
  let startX: number;
  let startY: number;
  let startXforCanvas: number;
  let startYforCanvas: number;

  const scaleRef = useRef(scale);
  const pageHeightRef = useRef(pageHeight);
  const pageWidthRef = useRef(pageWidth);
  const offsetRef = useRef(offset);
  const canvasRef = useRef(canvas);
  const contextMenuPositionRef = useRef(contextMenuPosition);

  const setScale = (data: number) => {
    scaleRef.current = data;
    _setScale(data);
  };

  const setContextMenuPosition = (
    data: { x: number; y: number; value: []; type: string } | null
  ) => {
    _setActivation(true);
    // contextMenuPositionRef.current = data;
    _setContextMenuPosition(data);
  };

  const setPageHeight = (data: number) => {
    pageHeightRef.current = data;
    _setPageHeight(data);
  };

  const setPageWidth = (data: number) => {
    pageWidthRef.current = data;
    _setPageWidth(data);
  };

  // const activationRef = useRef(activation);
  const setActivation = (data: boolean) => {
    if (containerRef.current) {
      // containerRef.current.setToolbarItems([{ type: "rectangle" }]);
      containerRef.current.setViewState((viewState: any) =>
        viewState.set(
          "interactionMode",
          PSPDFKit.InteractionMode.SHAPE_RECTANGLE
        )
      );
    }
    // activationRef.current = data;
    _setActivation(data);
  };

  const setOffset = (data: number) => {
    offsetRef.current = data;
    _setOffset(data);
  };

  const setCanvas = (data: Canvas) => {
    canvasRef.current = data;
    _setCanvas(data);
  };

  const AddNewIssueIcon = (): ReactElement<HTMLImageElement> => (
    <StyledIcon src={AddNewIssue} margin="0 6px 0 0" />
  );

  const AddNewSubissueIcon = (): ReactElement<HTMLImageElement> => (
    <StyledIcon src={AddNewSubissue} margin="0 6px 0 0" />
  );

  const AssignToIssueIcon = (): ReactElement<HTMLImageElement> => (
    <StyledIcon src={AssignToIssue} margin="0 6px 0 0" />
  );

  // useEffect(() => {
  //   if (edit) {
  //     const rect = new fabric.Rect({
  //       left: edit.editData.y0,
  //       top: edit.editData.x0,
  //       height: edit.editData.x1,
  //       width: edit.editData.y1,
  //       // fill: "transparent",
  //       hoverCursor: "pointer",
  //       hasControls: true,
  //       inverted: true,
  //       hasBorders: false,
  //       backgroundColor: "yellow",
  //       opacity: 0.1,
  //       selectable: true,
  //     });

  //     canvasRef.current?.add(rect);
  //   }
  // }, [edit]);
  const onMouseDown = (e: MouseEvent): void => {
    if (activation) {
      startXforCanvas = e.clientX - documentViewerRef.current.offsetLeft;
      startX = startXforCanvas / scaleRef.current;
      const marginTop =
        e.clientY -
        documentViewerRef.current.offsetTop -
        documentViewerRef2.current.offsetTop -
        40;
      startY =
        pageHeightRef.current -
        Math.round(marginTop) / scaleRef.current -
        offsetRef.current;
      startYforCanvas = marginTop;
      if (
        (e.target as HTMLDivElement).classList.contains("upper-canvas") &&
        contextMenuPosition
      ) {
        setContextMenuPosition(null);
        setAddNewSubissueContextMenu(false);
        setAssignToIssueContextMenu(false);
        setAssignToSubissueContextMenu(false);
        setClearCanvas(true);
      }
    }
  };

  const onMouseUp = (e: any): void => {
    // console.log(e);

    if (activation) {
      if (
        (e.target as HTMLDivElement).classList.contains("upper-canvas") &&
        documentViewerRef.current &&
        !contextMenuPositionRef.current
      ) {
        const endXforCanvas = e.clientX - documentViewerRef.current.offsetLeft;
        const endX = endXforCanvas / scaleRef.current;
        const marginTop =
          e.clientY -
          documentViewerRef.current.offsetTop -
          documentViewerRef2.current.offsetTop -
          40;
        const endY =
          pageHeightRef.current -
          Math.round(marginTop) / scaleRef.current -
          offsetRef.current;
        const width = Math.abs(endX - startX);
        const height = Math.abs(Math.round(endY - startY));
        setRectangle({ width, height });
        setPageCoordinates({
          minX: e.target.clientHeight - (endX - startX + startX) + 30,
          minY: e.target.clientWidth - e.nativeEvent?.offsetY,
          maxX: startX > endX ? startX : endX,
          maxY: startY > endY ? startY : endY,
        });
        setCanvasCoordinates({
          minX:
            startXforCanvas < endXforCanvas ? startXforCanvas : endXforCanvas,
          minY: startYforCanvas < marginTop ? startYforCanvas : marginTop,
          maxX:
            startXforCanvas > endXforCanvas ? startXforCanvas : endXforCanvas,
          maxY: startYforCanvas > marginTop ? startYforCanvas : marginTop,
        });
        if (Math.abs(endX - startX) && Math.abs(Math.round(endY - startY))) {
          // setContextMenuPosition({ x: e.clientX, y: e.clientY });
        }
      }
    }
  };

  useEffect(() => {
    if (canvasCoordinates) {
      const { minX, minY, maxX, maxY } = canvasCoordinates;
      const rect = new fabric.Rect({
        left: minX,
        top: minY,
        height: maxY - minY,
        // fill: "transparent",
        hoverCursor: "text",
        hasControls: false,
        inverted: true,
        hasBorders: false,
        backgroundColor: "red",
        opacity: 0.1,
        selectable: false,
      });

      canvasRef.current?.add(rect);
    }
  }, [canvasCoordinates]);

  useEffect(() => {
    setLoading(true);
    getAllListData(`${COMMON_ENDPOINT.file}/${params.id}`, {
      responseType: "arraybuffer",
    }).then((res: any) => {
      const pdfString: any = res;
      modifyPdf(
        setPdfDataUri,
        rects,
        pdfString
        // pageCoordinates,
        // pdfPath,
        // canvasCoordinates,
        // scaleRef.current,
        // issuesList,
        // setIssuesList,
        // color,
        // setActivation,
        // operation
      );
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rects]);
  const stylik: any = {
    position: "relative",
    display: "flex",
    "flex-direction": "column",
    height: "100%",
    width: "69.5vw",
  };
  const addNewIssue = (boundries: any) => {
    setLoading(true);
    const randomColor = rgb(
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255
    );
    setColor(randomColor);
    setContextMenuPosition(null);
    addNewData(
      {
        uploadId: params.id,
        boundingBox: {
          x0:
            boundries[0].boundingBox.top /
            Number(parentData?.pageInfoForIndex(0)?.height), //top
          y0:
            boundries[0].boundingBox.left /
            Number(parentData?.pageInfoForIndex(0)?.width), //left
          x1:
            boundries[0].boundingBox.height /
            Number(parentData?.pageInfoForIndex(0)?.height),
          y1:
            boundries[0].boundingBox.width /
            Number(parentData?.pageInfoForIndex(0)?.width),
        },
        documentPath: location?.state?.s3Url,
        pageNumber: boundries[0].pageIndex + 1,
      },
      "/issues"
    )
      .then((res: any) => {
        // getData();
        _setActivation(false);
        setLoading(false);
        // toast.success("Issue added successfully", alertOptions);
        navigate(0);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const updateMethod = (boundries: any) => {
    setLoading(true);
    const randomColor = rgb(
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255
    );
    setColor(randomColor);

    setContextMenuPosition(null);
    if (boundries?.[0]?.name?.length === 36) {
      updateData(
        boundries[0].id,
        {
          uploadId: params.id,
          issueId: boundries?.[0]?.name,
          boundingBox: {
            x0:
              boundries[0].boundingBox.top /
              Number(parentData?.pageInfoForIndex(0)?.height), //top
            y0:
              boundries[0].boundingBox.left /
              Number(parentData?.pageInfoForIndex(0)?.width), //left
            x1:
              boundries[0].boundingBox.height /
              Number(parentData?.pageInfoForIndex(0)?.height),
            y1:
              boundries[0].boundingBox.width /
              Number(parentData?.pageInfoForIndex(0)?.width),
          },
          documentPath: location?.state?.s3Url,
          pageNumber: boundries[0].pageIndex + 1,
        },
        "/sub-issues"
      )
        .then((res: any) => {
          // getData();
          _setActivation(false);
          setLoading(false);
          toast.success("Sub-Issue updated successfully", alertOptions);
          // navigate(0);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      updateData(
        boundries[0].id,
        {
          uploadId: params.id,
          boundingBox: {
            x0:
              boundries[0].boundingBox.top /
              Number(parentData?.pageInfoForIndex(0)?.height), //top
            y0:
              boundries[0].boundingBox.left /
              Number(parentData?.pageInfoForIndex(0)?.width), //left
            x1:
              boundries[0].boundingBox.height /
              Number(parentData?.pageInfoForIndex(0)?.height),
            y1:
              boundries[0].boundingBox.width /
              Number(parentData?.pageInfoForIndex(0)?.width),
          },
          documentPath: location?.state?.s3Url,
          pageNumber: boundries[0].pageIndex + 1,
        },
        "/issues"
      )
        .then(async (res: any) => {
          // getData();
          _setActivation(false);
          setLoading(false);
          toast.success("Issue updated successfully", alertOptions);
          // navigate(0);
          // containerRef.current.setViewState((viewState: any) =>
          //   viewState.set("showToolbar", !viewState.showToolbar)
          // );
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };
  const deleteMethod = (boundries: any) => {
    setLoading(true);
    setContextMenuPosition(null);
    if (boundries?.[0]?.name?.length === 36) {
      deleteData(boundries[0].id, "/sub-issues", {
        uploadId: params.id,
        issueId: boundries?.[0]?.name,
      })
        .then((res: any) => {
          // getData();
          _setActivation(false);
          setLoading(false);
          toast.success("Sub-Issue deleted successfully", alertOptions);
          navigate(0);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      deleteData(boundries[0].id, "/issues", {
        uploadId: params.id,
      })
        .then((res: any) => {
          // getData();
          _setActivation(false);
          setLoading(false);
          toast.success("Issue deleted successfully", alertOptions);
          navigate(0);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };
  const addNewSubissue = (elNumber: number, boundries: any) => {
    setLoading(true);
    const randomColor = rgb(
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255,
      (+Math.random() * 255) / 255
    );
    setColor(randomColor);
    setContextMenuPosition(null);
    addNewData(
      {
        uploadId: params.id,
        issueId: elNumber,
        boundingBox: {
          x0:
            boundries[0].boundingBox.top /
            Number(parentData?.pageInfoForIndex(0)?.height), //top
          y0:
            boundries[0].boundingBox.left /
            Number(parentData?.pageInfoForIndex(0)?.width), //left
          x1:
            boundries[0].boundingBox.height /
            Number(parentData?.pageInfoForIndex(0)?.height),
          y1:
            boundries[0].boundingBox.width /
            Number(parentData?.pageInfoForIndex(0)?.width),
        },
        documentPath: location?.state?.s3Url,
        pageNumber: boundries[0].pageIndex + 1,
      },
      `/sub-issues`
    )
      .then((res: any) => {
        // getData();
        setLoading(false);
        navigate(0);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const assignToIssue = (elNumber: number) => {
    const { minX, minY } = pageCoordinates;
    const { width, height } = rectangle;
    const thisColor = issuesList.find(
      (el) => el.number === elNumber && el.isIssue
    )?.color;
    setColor(thisColor);
    const data = [
      1,
      minX,
      minY,
      width,
      height,
      thisColor,
      { toIssue: elNumber },
    ];
    setRects((prev) => [...prev, data]);
    setClearCanvas(true);
    setContextMenuPosition(null);

    setOperation({ type: "assignToIssue", payload: elNumber });
  };

  useEffect(() => {
    clearCanvas && setClearCanvas(false);
  }, [clearCanvas]);

  const assignToSubissue = (elNumber: number) => {
    //TODO: implement assign subIssue to issue flow
  };
  // console.log(canvasCoordinates);
  useEffect(() => {
    dispatch(clearAnswer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = () => {
    setLoading(true);
    getAllListData(`${COMMON_ENDPOINT.issues}?uploadId=${id}`)
      .then((res) => {
        setData(res.data);
        setRects(
          res.data &&
            res.data.map((re: any) => [
              re.pageNumber,
              re?.boundingBox.x0,
              re?.boundingBox.y0,
              re?.boundingBox.x1,
              re?.boundingBox.y1,

              {
                type: "RGB",
                red: 1,
                green: 0.09,
                blue: 0.27,
              },
            ])
        );
        setheaderprogress((p: { issueProgress: number; total: number }) => ({
          ...p,
          issueProgress: res.issueProgress,
          total: res.total,
        }));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <StyledDocumentReviewContainer>
        <Panel
          visaType={location?.state?.visaType}
          mock={issuesList}
          setMock={setIssuesList}
          setheaderprogress={setheaderprogress}
          activationRef={activation}
          setActivation={setActivation}
          setOperation={setOperation}
          setRects={setRects}
          loading={loading}
          data={data}
          setData={setData}
          issueId={issueId}
          setIssueId={setIssueId}
        />
        <div style={stylik} ref={documentViewerRef}>
          <div style={{ position: "relative" }}>
            <StyledIssueProgressWrapper style={{ border: "3px solid #0080ff" }}>
              <StyledIssueProgressInfoWrapper>
                <StyledIssueTitle fontWeight={600} noMargin>
                  Issue Progress
                </StyledIssueTitle>
                <StyledProgressInfo>
                  <p
                    style={{
                      fontFamily: "Libre Franklin, sans-serif",
                      fontWeight: 400,
                      fontSize: "12px",
                    }}
                  >
                    {headerprogress.issueProgress}/{headerprogress.total}
                  </p>
                </StyledProgressInfo>
              </StyledIssueProgressInfoWrapper>
              <StyledSubHeading
                fontSize={14}
                noMargin
                pointer
                onClick={() =>
                  headerprogress.issueProgress === headerprogress.total &&
                  navigate(`${ROUTE_RESPONDER}/${params.id}`)
                }
                style={{
                  color:
                    headerprogress.issueProgress === headerprogress.total
                      ? "#070D12"
                      : "#3D5161",
                  backgroundColor:
                    headerprogress.issueProgress === headerprogress.total
                      ? "white"
                      : "transparent",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor:
                    headerprogress.issueProgress < headerprogress.total
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Generate Response
              </StyledSubHeading>
            </StyledIssueProgressWrapper>
            <StyledProgress
              width={
                (headerprogress.issueProgress / headerprogress.total) * 100
              }
            />
          </div>
          <div ref={documentViewerRef2} style={{ position: "relative" }}>
            <DocumentViewer
              onDataUpdate={handleChildDataUpdate}
              containerRef={containerRef}
              setContextMenuPosition={setContextMenuPosition}
              data={data}
              deleteMethod={deleteMethod}
              contextMenuPosition={contextMenuPosition}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            />
            {contextMenuPosition && activation ? (
              contextMenuPosition.type === "add" ? (
                <StyledContextMenu top={200} left={250}>
                  <ul style={{ listStyleType: "none" }}>
                    <StyledContextMenuItem
                      onClick={() => addNewIssue(contextMenuPosition.value)}
                    >
                      <AddNewIssueIcon />
                      Add New Issue
                    </StyledContextMenuItem>
                    <StyledContextMenuItem
                      onMouseOver={() => setAddNewSubissueContextMenu(true)}
                      onMouseLeave={() => setAddNewSubissueContextMenu(false)}
                    >
                      <AddNewSubissueIcon />
                      Add New Subissue
                    </StyledContextMenuItem>
                    {addNewSubissueContextMenu && (
                      <StyledContextMenu
                        top={32.5}
                        left={147.4}
                        onMouseOver={() => setAddNewSubissueContextMenu(true)}
                      >
                        <ul style={{ listStyleType: "none" }}>
                          {data.map((el: any, index) => (
                            <StyledContextMenuItem
                              onClick={() =>
                                addNewSubissue(el.id, contextMenuPosition.value)
                              }
                            >
                              To Issue #{index + 1}
                            </StyledContextMenuItem>
                          ))}
                        </ul>
                      </StyledContextMenu>
                    )}
                    <StyledContextMenuItem
                      onMouseOver={() => setAssignToIssueContextMenu(true)}
                      onMouseLeave={() => setAssignToIssueContextMenu(false)}
                    >
                      <AssignToIssueIcon />
                      Assign to issue
                    </StyledContextMenuItem>
                    {assignToIssueContextMenu && (
                      // <StyledContextMenu
                      // top={62.5}
                      // left={147.4}
                      //   onMouseOver={() => setAssignToIssueContextMenu(true)}
                      // >
                      //   <ul style={{ listStyleType: "none" }}>
                      //     {issuesList.map(
                      //       (el) =>
                      //         el.isIssue && (
                      //           <StyledContextMenuItem
                      //             onClick={() => assignToIssue(el.number)}
                      //           >
                      //             Issue #{el.number}
                      //           </StyledContextMenuItem>
                      //         )
                      //     )}
                      //   </ul>
                      // </StyledContextMenu>
                      <StyledContextMenu
                        top={62.5}
                        left={147.4}
                        onMouseOver={() => setAddNewSubissueContextMenu(true)}
                      >
                        <ul style={{ listStyleType: "none" }}>
                          {data.map((el: any, index) => (
                            <StyledContextMenuItem
                              onClick={() =>
                                addNewSubissue(el.id, contextMenuPosition.value)
                              }
                            >
                              To Issue #{index + 1}
                            </StyledContextMenuItem>
                          ))}
                        </ul>
                      </StyledContextMenu>
                    )}
                    {/* {assignToSubissueContextMenu && (
                      <StyledContextMenu
                        top={82.5}
                        left={147.4}
                        onMouseOver={() => setAssignToSubissueContextMenu(true)}
                      >
                        <ul style={{ listStyleType: "none" }}>
                          {issuesList.map(
                            (el) =>
                              el.isIssue && (
                                <StyledContextMenuItem
                                  onClick={() => assignToSubissue(el.number)}
                                >
                                  Issue #{el.number}
                                </StyledContextMenuItem>
                              )
                          )}
                        </ul>
                      </StyledContextMenu>
                    )} */}
                  </ul>
                </StyledContextMenu>
              ) : (
                <StyledContextMenu top={200} left={250}>
                  <ul style={{ listStyleType: "none" }}>
                    <StyledContextMenuItem
                      onClick={() => updateMethod(contextMenuPosition.value)}
                    >
                      {/* <AddNewIssueIcon /> */}
                      save
                    </StyledContextMenuItem>
                    {/* <StyledContextMenuItem>cancel</StyledContextMenuItem> */}
                  </ul>
                </StyledContextMenu>
              )
            ) : (
              ""
            )}
          </div>
        </div>

        <TabsBar />
      </StyledDocumentReviewContainer>
    </>
  );
};

export default FileViewer;
