import { useFormik } from "formik";
import { initialValues, validationSchema } from "./helpers";
import Form from "src/components/Form";
import Input from "src/components/Input/Input";
import { login } from "src/store/actions/UserAction";
import { useAppDispatch } from "src/store/hooks";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks";

const SignIn = () => {
  const loaderState: boolean = useAppSelector(
    (store: any) => store?.userLogin?.loading
  );
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useAppDispatch();
  const submitHandler = ({ email, password }: any) => {
    setLoading(true);
    dispatch(login(email, password, "login"));
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });
  const {
    values: { email, password },
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  useEffect(() => {
    if (!loaderState) {
      setLoading(loaderState);
    }
  }, [loaderState]);

  return (
    <Form type="sign-in" isFixed onSubmit={handleSubmit}>
      {loading && <div className="liberty-loader" />}
      <Input
        placeholder="Email"
        id="email"
        error={touched.email && errors.email}
        value={email}
        handleBlur={handleBlur}
        handleValue={handleChange}
      />
      <Input
        placeholder="Password"
        id="password"
        type="password"
        error={touched.password && errors.password}
        value={password}
        handleBlur={handleBlur}
        handleValue={handleChange}
      />
    </Form>
  );
};

export default SignIn;
