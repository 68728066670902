import { useNavigate } from "react-router-dom";
import { ROUTE_DOCUMENT_REVIEW } from "src/routes";
import { ReactComponent as Folder } from "src/assets/images/Folder.svg";
// import { CASES } from "../constants";
import { StyledAddCaseBtn, StyledCase, StyledList } from "../styled";
import { StyledText } from "src/components/Text/styled";
import { useEffect, useState } from "react";
import { getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useAppSelector } from "src/hooks";
import AddCaseModal from "src/components/AddCaseModal";
// import { toast } from "react-toastify";
// import { alertOptions } from "src/utils/helper";
import Modal from "src/components/Modal";
import InfoIcon from "@mui/icons-material/Info";
// import { StyledOvieBtn } from "src/components/TextArea/styled";

const CasesList = () => {
  const navigate = useNavigate();
  const [cases, SetCases]: any = useState();
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo
  );
  const getData = () => {
    setLoading(true);
    getAllListData(
      `${String(COMMON_ENDPOINT.UploadedDoc)}?userId=${String(userDetail?.id)}`
    )
      .then((res) => {
        SetCases(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  const closeModel = () => {
    setModelOpen(false);
  };

  const informationCloseModel = () => {
    setInformationModal(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeNavigate = (data?: any) => {
    setLoading(true);
    getAllListData(
      `${String(COMMON_ENDPOINT.InferanceAPI)}?uploadId=${String(
        data?.id
      )}&jobId=${data?.jobId}`
    )
      .then((res: any) => {
        if (res?.status === 200 && res?.message === "success") {
          navigate(`${ROUTE_DOCUMENT_REVIEW}/${data?.id}`, {
            state: {
              s3Url: data?.s3Url,
              visaType: data?.visaType,
            },
          });
        } else {
          setLoading(false);
          setInformationModal(true);
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        // toast.error(e?.message, alertOptions);
      });
  };
  return (
    <>
      {loading && <div className="liberty-loader" />}
      <StyledText
        textAlign="left"
        weight={700}
        lineHeight={24}
        padding="0 0 24px"
      >
        Responder Cases
      </StyledText>
      <StyledList>
        <StyledAddCaseBtn onClick={() => setModelOpen(true)}>
          <StyledText size={26} cursor="pointer">
            +
          </StyledText>
          <StyledText size={14} cursor="pointer">
            Add new case
          </StyledText>
        </StyledAddCaseBtn>
        {cases?.map((data: any, index: any) => {
          return (
            <li key={index}>
              <StyledCase onClick={() => changeNavigate(data)}>
                <Folder />
                <span>{data?.fileName}</span>
              </StyledCase>
            </li>
          );
        })}
      </StyledList>
      {modelOpen && (
        <AddCaseModal
          handleClose={closeModel}
          setModelOpen={setModelOpen}
          getData={getData}
        />
      )}
      {informationModal && (
        <Modal handleClose={informationCloseModel}>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InfoIcon sx={{ fill: "cornflowerblue" }} fontSize="large" />
            </div>
            <div style={{ marginTop: "15px" }}>
              <StyledText size={18} padding="0 0 20px">
                File Uploaded, It's takes some time to generate issues!
              </StyledText>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  width: "78px",
                  height: "30px",
                  padding: "8px 16px 8px 16px",
                  cursor: "pointer",
                }}
                onClick={informationCloseModel}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CasesList;
