import { FC, ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { handleActiveTab } from "src/slices/activeTabSlice";
import { ITabProps } from "./Tab/Tab";
import { StyledText } from "../Text/styled";
import { StyledTabs, StyledListItem } from "./styled";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const Tabs: FC<{
  children: ReactElement<ITabProps>[];
  openModel: any;
  setOpenModel: any;
}> = ({ children, openModel, setOpenModel }) => {
  const activeTab = useAppSelector((state) => state.activeTab.value);
  const dispatch = useAppDispatch();

  return (
    <div>
      <StyledTabs>
        {children.map(({ props: { label } }) => {
          const isActive = activeTab === label;

          return (
            <StyledListItem key={label} hasBorder={isActive}>
              <StyledText
                color={isActive ? "#111" : "#ccc"}
                weight={isActive ? 700 : 400}
                cursor="pointer"
                onClick={() => dispatch(handleActiveTab({ value: label }))}
              >
                {label}
              </StyledText>
            </StyledListItem>
          );
        })}
        {activeTab === "Exhibit" && (
          <StyledListItem
            header={true}
            onClick={() =>
              setOpenModel((pre: any) => ({
                ...pre,
                open: true,
              }))
            }
          >
            <ControlPointIcon
              style={{ height: "18px", width: "18px", marginRight: "5px" }}
            />
            Add Header
          </StyledListItem>
        )}
      </StyledTabs>
      <>
        {children.map(
          ({ props: { label, children } }) => label === activeTab && children
        )}
      </>
    </div>
  );
};

export default Tabs;
