import styled from "styled-components";

const whiteColor = "#FFF";
const interFontFamily = "Inter, sans-serif";
const libreFranklinFontFamily = "Libre Franklin, sans-serif";

export const StyledDocumentReviewContainer = styled.div`
  display: flex;
  // height: 100%;
  .rpv-default-layout__body {
    padding: 0;
  }
`;

export const StyledPanel = styled.div`
  width: 15vw;
  min-width: 288px;
  height: calc(100vh - 88px);
  height: calc(100vh - 67px);
  // height: calc(100vh - 100px);
  background: #162632;
  border: 1px solid #1f323f;
  padding: 8px 20px;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #070f15;
    border: 1px solid #1f323f;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: #3c5262;

    border-radius: 4px;
  }
`;

export const StyledHeading = styled.h6`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${whiteColor};
  font-family: ${interFontFamily};
  margin-bottom: 16px;
  margin-top: 6px;
`;

export const StyledIssueTitle = styled.h6<{
  fontWeight?: number;
  noMargin?: boolean;
}>`
  font-family: Libre Franklin, sans-serif;
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  font-size: 12px;
  line-height: 14px;
  color: ${whiteColor};
  text-overflow: ellipsis;

  ${({ noMargin }) => !noMargin && `margin: 14px 0 6px;`}
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const StyledSubHeading = styled.h6<{
  fontSize?: number;
  textTransform?: string;
  isPointer?: boolean;
  noMargin?: boolean;
  pointer?: boolean;
}>`
  font-size: ${({ fontSize }) => fontSize || 10}px;
  ${({ isPointer }) => isPointer && `cursor: pointer`};
  line-height: 10px;
  font-weight: 500;
  color: #3d5161;
  ${({ textTransform }) => `text-transform: ${textTransform};`}
  font-family: ${libreFranklinFontFamily};
  ${({ noMargin }) => !noMargin && `margin-bottom: 8px;`}
  ${({ pointer }) => pointer && `cursor: pointer`}
`;

export const StyledIssueNumber = styled.h6`
  font-family: ${libreFranklinFontFamily};
  font-size: 12px;
  font-weight: 400;
  color: #0080ff;
  margin: 0 0 10px;
`;

export const StyledButton = styled.button<{
  color?: string;
  fontWeight: number;
  bgColor?: string;
  active: boolean;
}>`
  color: ${({ color }) => color || whiteColor};
  height: 36px;
  cursor: pointer;
  border: none;
  font-family: ${libreFranklinFontFamily};
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  width: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  // margin-bottom: 10px;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
`;

export const StyledTile = styled.div<{ color?: any; padding?: string }>`
  border-radius: 4px;
  border-left: 3px solid;
  background: #1f323f;
  padding: ${({ padding = "10px" }) => padding};
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
`;

export const StyledIssueText = styled.p`
  font-family: ${libreFranklinFontFamily};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #acbdca;
  margin: 0 0 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
`;

export const StyledProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${whiteColor};

  & p {
    font-family: ${libreFranklinFontFamily};
    font-weight: 400;
    font-size: 12px;
  }
`;

export const StyledIssueScrollcontainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const StyledProgress = styled.div<{
  width?: number;
  borderRadius?: string | number;
}>`
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  // z-index:200;
  height: 36px;
  border: none;
  background-color: #162632;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${({ width }) => width || 4}%;
    height: 36px;
    background-color: #0080ff;
    border-radius: ${({ borderRadius }) => borderRadius || 1}px;
  }
`;

export const StyledProgressMini = styled.div<{
  width?: number;
  borderRadius?: string | number;
}>`
  // width: ${({ width }) => (width && width > 100 ? 100 : width || 4)}%;
  width: 100%;
  height: 4px;
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius || 0}px;
  width: 100%;
  background-color: #162632;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${({ width }) => (width && width > 100 ? 100 : width || 14)}%;
    height: 100%;
    background-color: ${({ width }) =>
      width && width < 50 ? "#FF1744" : "#00C853"};
    border-radius: 1px;
    // margin-right: 5px;
  }
`;

export const StyledViewer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 83.2px - 37px);
  height: calc(100vh - 100px);
  position: relative;
  background: #0a1720;

  * {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      background: #070f15;
      border: 1px solid #1f323f;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      width: 4px;
      background: #3c5262;

      border-radius: 4px;
    }
  }
`;

export const StyledIcon = styled.img<{ margin?: string }>`
  cursor: pointer;
  ${({ margin }) => margin && `margin: ${margin}`}
`;

export const StyledThumbnailsWrapper = styled.div`
  width: 20%;
  // margin-top: 50px;
  margin-top: 36px;
  // height: 100vh;
`;

export const StyledIssueProgressInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 24px;
  width: 20%;
`;

export const StyledIssueProgressWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  border-top: 1px solid #1f323f;
`;

export const StyledToolbarInfoWrapper = styled.div`
  color: ${whiteColor};
  padding: 8px 12px;
  background: #162632;
  border: 1px solid #1f323f;
  font-family: ${libreFranklinFontFamily};
  font-size: 12px;
  font-weight: 400;
  margin: 0 10px;
  border-radius: 2px;
`;

export const StyledZoomInfoContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
`;

export const StyledContextMenu = styled.div<{ top: number; left: number }>`
  background: #0a1720;
  color: ${whiteColor};
  position: absolute;
  z-index: 2;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  font-weight: 400;
  font-size: 12px;
  padding: 4px 0;
  min-width: 70px;
  font-family: ${libreFranklinFontFamily};
`;

export const StyledContextMenuItem = styled.li`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  min-width: 100px;
  :hover {
    background: #2b404f;
    cursor: pointer;
  }
`;
