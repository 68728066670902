import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ICurrentIssue {
  value: {
    title: string;
    number: number;
    text?: string;
  }
}

const initialState: ICurrentIssue = {
  value: {
    title: '',
    number: 0,
    text: '',
  }
}

export const currentIssueSlice = createSlice({
  name: 'currentIssue',
  initialState,
  reducers: {
    setCurrentIssue: (
      state: {
        value: { title: string; number: number; text?: string };
      },
      action: PayloadAction<{
        title: string;
        number: number;
        text?: string;
      }>
    ) => {
      state.value = action.payload;
    }
  }
})

export const { setCurrentIssue } = currentIssueSlice.actions

export default currentIssueSlice.reducer;