import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  background-color: #d5e8f6;
  &,
  th,
  td {
    /* border: 1px solid; */
    border-collapse: collapse;
  }
  thead {
    background-color: #162632 !important;
    height: 40px;
    /* color: var(--text-on-background-high-contrast, #fff); */

    color: #ffffff;
    font-family: LibreFranklin;
    font-size: 12px;
    padding: 10px 15px;
    text-align: left;
    text-transform: capitalize;

    th {
      padding: 10px 15px;
      text-align: left;
      text-transform: capitalize;
    }
  }
  tbody {
    font-family: "LibreFranklin-Regular", sans-serif;
    td {
      border-bottom: 1px solid #d5e8f6;
      color: black;
      line-height: 22px;
      padding: 10px 15px;
      width: 17%;
      font-size: 14px;
    }
  }
`;
