import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks";
import Tabs from "src/components/Tabs";
import Tab from "src/components/Tabs/Tab/Tab";
import Document from "../Document";

const AssembleTabs = ({ openModel, setOpenModel }: any) => {
  const { id } = useParams();

  const { attachments, exhibits, briefs } = useAppSelector((state) => ({
    attachments: state.attachments.value,
    exhibits: state.exhibits.value,
    briefs: state.briefs.value,
  }));

  return (
    <>
      <Tabs openModel={openModel} setOpenModel={setOpenModel}>
        <Tab label="Attachments">
          {attachments
            .filter(({ caseId }) => caseId === id)
            .map(({ name, id, extension }) => (
              <Document
                name={name}
                extension={extension}
                id={id}
                docType="attachment"
              />
            ))}
        </Tab>
        <Tab label="Exhibit">
          {exhibits
            .filter(({ caseId }) => caseId === id)
            .map(({ name, id, extension }) => (
              <Document
                name={name}
                extension={extension}
                id={id}
                docType="exhibit"
                isExhibit={true}
              />
            ))}
        </Tab>
        <Tab label="Brief">
          {briefs
            .filter(({ caseId }) => caseId === id)
            .map(({ name, id, extension }) => (
              <Document
                name={name}
                extension={extension}
                docType={"brief"}
                isBrief={true}
                id={id}
              />
            ))}
        </Tab>
        <Tab label="Cover">
          {briefs
            .filter(({ caseId }) => caseId === id)
            .map(({ name, id, extension }) => (
              <Document
                name={name}
                extension={extension}
                docType={"cover"}
                isCover={true}
                id={id}
              />
            ))}
        </Tab>
      </Tabs>
    </>
  );
};

export default AssembleTabs;
