import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-top: 1px solid #1f323f;
`;

export const StyledSubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 16px;
  // justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background-color: #070d12;
  }
`;

export const StyledAside = styled.aside`
  width: 30%;
  max-width: 288px;
  // height: calc(100vh - 90px);
  // height: 100%;
  border-right: 1px solid #1f323f;
  background: #070d12;
  padding: 8px 0;
`;

export const StyledContent = styled.div`
  padding: 24px 32px;
  width: 100%;
  // overflow: hidden;
  /* overflow: auto; */
`;

export const StyledAddCaseBtn = styled.button`
  padding: 40px;
  border: 1px dashed #114d9c;
  border-radius: 4px;
  background: #0f2142;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: LibreFranklin;
`;

export const StyledCase = styled(StyledAddCaseBtn)`
  position: relative;
  background: linear-gradient(
      135deg,
      #ffffff00 18.13%,
      #ffffff0d 46.96%,
      #ffffff0d 51.3%,
      #ffffff00 85.1%
    ),
    #1f323f;
  padding: 40px 60px;
  border: 0.7px solid;
  border-radius: 4px;
  border-image-source: linear-gradient(0deg, #ffffff00 100%, #ffffff47 0%);
  border-image-slice: 10;

  &::after {
    content: "";
    position: absolute;
    top: -0.7px;
    left: -0.7px;
    right: -0.7px;
    bottom: -0.7px;
    border-top: 0.7px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
`;

export const StyledList = styled.ul<{ isFlexGrow?: boolean }>`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;

  & > li {
    list-style: none;
    flex-grow: ${({ isFlexGrow }) => (isFlexGrow ? 1 : 0)};
  }
`;

export const StyledSidebarBtn = styled.button`
  padding: 14px;
  border: none;
  border-left: 2px solid #114d9c;
  background: #0f1e28;
  color: #fff;
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const StyledCaseDetail = styled.div`
  padding: 22px;
  background: #1f323f;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  flex-grow: 1;

  &:hover {
    background: #3c5262;
    transition: 0.5s;
  }
`;

export const StyledAssembleBtn = styled.button`
  border: 1px solid #1f323f;
  background: transparent;
  padding: 12px 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

export const StyledWrap = styled.div<{
  justifyContent?: string;
  alignItems?: string;
  gap?: number;
  padding?: string;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  gap: ${({ gap }) => `${gap || 0}px`};
  padding: ${({ padding }) => padding || 0};
`;

export const StyledAssembleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const StyledArea = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 400px;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;

  & > div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  & > div > input {
    width: 50%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    outline: none;
  }
`;

export const StyledAssembleFooter = styled.div`
  position: absolute;
  bottom: 0;
  border-top: 1px solid #ccc;
  padding: 10px;
  width: 100%;

  & > span {
    display: block;
    padding: 6px;
    border: 2px dashed #114d9c;
  }
`;
export const StyledAssembleDrag = styled.div<{
  hasBg?: boolean;
  isDraggable?: boolean;
  opacity?: number;
}>`
  position: absolute;
  /* bottom: 0; */
  /* border-top: 1px solid #ccc; */
  /* padding: 10px; */
  /* width: 100%; */
  padding: 10px;
  background: ${({ hasBg }) => (hasBg ? "#edfdf5" : "none")};
  border: ${({ hasBg }) => (hasBg ? "none" : "1px solid #ccc")};
  border-radius: 5px;
  width: 95%;
  margin: 0 auto 10px;
  display: flex;
  gap: 10px;
  /* align-items: center; */
  opacity: ${({ opacity }) => opacity || 1};
  cursor: ${({ isDraggable }) => (isDraggable ? "move" : "default")};

  .labels_wrap {
    margin-left: auto;
  }
  & > span {
    display: block;
    padding: 3px;
    border: 1px dashed #114d9c;
    width: 85%;
  }
`;
export const StyledDoc = styled.div<{
  hasBg?: boolean;
  isDraggable: boolean;
  opacity?: number;
}>`
  padding: 10px;
  background: ${({ hasBg }) => (hasBg ? "#edfdf5" : "none")};
  border: ${({ hasBg }) => (hasBg ? "none" : "1px solid #ccc")};
  border-radius: 5px;
  width: 95%;
  margin: 0 auto 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  opacity: ${({ opacity }) => opacity || 1};
  cursor: ${({ isDraggable }) => (isDraggable ? "move" : "default")};

  .labels_wrap {
    margin-left: auto;
  }
`;

export const StyledLabel = styled.span`
  padding: 3px 10px;
  background: lightgreen;
  border-radius: 30px;
  font-family: LibreFranklin;
  font-weight: 500;
  font-size: 12px;
`;

export const StyledBtnsWrap = styled.div<{
  justifyContent?: string;
  hasLabel?: boolean;
}>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  margin-left: ${({ hasLabel }) => (hasLabel ? "0" : "auto")};

  & > .close_btn {
    background: transparent;
    border: 1px solid gray;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .btn_edit,
  .btn_cancel {
    padding: 6px 20px;
    border: none;
    cursor: pointer;
  }

  & > svg {
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
`;
