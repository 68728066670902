import { FC, useState } from "react";
import { ReactComponent as Message } from "src/assets/images/Message.svg";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { editAnswer } from "src/slices/answersSlice";
import { TempFilesType } from "../EvidenceForm/EvidenceForm";
import { StyledCheckbox } from "../Input/styled";
import { StyledText } from "../Text/styled";
import {
  StyledLabelWrap,
  StyledOvieBtn,
  StyledTextarea,
  StyledTextAreaWrap,
} from "./styled";
import { askBotType } from "../../types";
import {
  addNewMessage,
  // askChatBotTC,
  emptyMessage,
} from "../../slices/chatBotSlice";
import { addNewData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import Storage from "src/utils/Storage";
import jwtDecode from "jwt-decode";

interface ITextArea {
  currentStep: number;
  label?: string;
  fieldName?: string;
  hasCheckbox?: boolean;
  value?: string;
  setFieldValue?: (name: string, value: TempFilesType[] | string) => void;
  askOvieType?: askBotType;
  heightNum?: string;
  visaType?: string;
}

const TextArea: FC<ITextArea> = ({
  currentStep,
  label,
  fieldName,
  hasCheckbox,
  value,
  setFieldValue,
  askOvieType,
  heightNum,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const { selectedText, title, id: Id } = location?.state?.data;
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const height = +currentStep > 3 ? "200" : "100";
  const answer: any = useAppSelector((state) => state.answers.value);
  // const currentIssue = useAppSelector((state) => state.currentIssue.value);
  const [loading, setLoading] = useState(false);
  const { subIssue, visaType, issueData } = location?.state;
  const TokenStatus: any = Storage.getTokens();
  const tokenData: any = jwtDecode(String(TokenStatus?.token));

  const regulationAskOvie = () => {
    setLoading(true);
    const obj: any = {
      visaType: visaType,
      issueTitle: subIssue ? issueData?.title : title,
      issueText: selectedText ? selectedText : "",
      sampleText: "",
    };
    addNewData(obj, COMMON_ENDPOINT.regulationAskOvie)
      .then((rea) => {
        // console.log(rea.data.regulations[0].page_content.replace(/\n/g, ""));
        // rea?.data?.forEach((regulationRes: any) => {
        dispatch(emptyMessage());
        dispatch(
          addNewMessage([
            {
              title: `Relevant Regulaton`,
              text: rea?.data?.response.replace(/\n/g, ""),
            },
          ])
        );
        setLoading(false);
        // });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const regulationAskOvieSecondary = () => {
    setLoading(true);
    const obj: any = {
      issue: title,
      selectedRegulations: "",
      issueText: selectedText ? selectedText : "",
      sampleText: "",
    };
    addNewData(obj, COMMON_ENDPOINT.regulationSecondaryAskovie)
      .then((secondaryRegulation) => {
        // console.log(rea.data.regulations[0].page_content.replace(/\n/g, ""));
        dispatch(emptyMessage());
        dispatch(
          addNewMessage([
            {
              title: "",
              text: secondaryRegulation.data.answer.replace(/\n/g, ""),
            },
          ])
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };
  const policyAskOvie = () => {
    setLoading(true);
    const obj: any = {
      issue: issueData ? issueData.title : !subIssue ? title : "",
      visaType,
      subIssue: subIssue ? title : "",
      issueText: selectedText ? selectedText : "",
      numResponses: 3,
    };
    addNewData(obj, COMMON_ENDPOINT.policyAskOvie)
      .then((policyRes) => {
        // console.log(rea.data.policies[0].page_content.replace(/\n/g, ""));
        policyRes?.data?.forEach((res: any) => {
          dispatch(
            addNewMessage([
              {
                title: "",
                text: res.page_content.replace(/\n/g, ""),
              },
            ])
          );
          setLoading(false);
        });
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };
  const federalCaselawAsk = () => {
    setLoading(true);
    const obj: any = {
      issueTitle: title ? title : "",
      visaType,
      issueText: selectedText ? selectedText : "",
      sampleText: "",
    };
    addNewData(obj, COMMON_ENDPOINT.federalCaseLaw)
      .then((rea) => {
        // console.log(rea.data.policies[0].page_content.replace(/\n/g, ""));
        rea?.data?.forEach((res: any) => {
          dispatch(
            addNewMessage([
              {
                title: res.url,
                text: res.content,
              },
            ])
          );
          setLoading(false);
        });
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };
  const expertAskOvie = () => {
    setLoading(true);
    const obj: any = {
      issueTitle: title ? title : "",
      visaType,
      issueText: selectedText ? selectedText : "",
      sampleText: "",
    };
    addNewData(obj, COMMON_ENDPOINT.expertAskOvie)
      .then((expretRes) => {
        // console.log(rea.data.policies[0].page_content.replace(/\n/g, ""));
        dispatch(
          addNewMessage([
            {
              title: "",
              text: expretRes.data.response ? expretRes.data.response : "",
            },
          ])
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };

  const communityPost = () => {
    setLoading(true);
    setChecked((prevState) => !prevState);
    if (checked) {
      setLoading(false);
    }
    if (!checked) {
      const communityBodyData = {
        uploadId: id,
        issueId: issueData ? issueData.id : !subIssue ? Id : "",
        subIssueId: subIssue ? Id : "",
        visaType,
        issue: issueData ? issueData.title : !subIssue ? title : "",
        subIssue: subIssue ? title : "",
        userId: tokenData ? tokenData?.id : "",
        userName: tokenData
          ? `${tokenData?.firstName} ${tokenData?.lastName}`
          : "",
        lawyerFirm: tokenData ? tokenData?.firmName : "",
        status: true,
      };
      addNewData(communityBodyData, COMMON_ENDPOINT.communityPost)
        .then((res) => {
          if (res) {
            setLoading(false);
            setChecked((pre) => !pre);
            toast.success(res.message);
          }
        })
        .catch((err) => {
          toast.error(err?.message);
          setLoading(false);
        });
    }
  };

  const generateResponse = () => {
    setLoading(true);
    const obj: any = {
      issueTitle: title ? title : "",
      expertBiography: "",
      issue: selectedText ? selectedText : "",
      exhibits: {},
    };
    addNewData(obj, COMMON_ENDPOINT.responseGenerator)
      .then((responseGenerator) => {
        // console.log(rea.data.policies[0].page_content.replace(/\n/g, ""));
        dispatch(
          addNewMessage([
            {
              title: "Generated Response",
              text: responseGenerator.data.answer.replace(/\n/g, ""),
            },
          ])
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };
  const witnessAskOvie = () => {
    setLoading(true);
    const witnessBackGroundText: String = answer[1]?.questions[5]?.answer;
    const obj: any = {
      issueTitle: title ? title : "",
      witnessBackground: witnessBackGroundText,
      issue: selectedText ? selectedText : "",
      exhibits: {},
    };
    addNewData(obj, COMMON_ENDPOINT.witnessAskOvie)
      .then((witessRes) => {
        dispatch(
          addNewMessage([
            {
              title: "Witness Testimony",
              text: witessRes.data.answer ? witessRes.data.answer : "",
            },
          ])
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };
  const policySecondary = () => {
    setLoading(true);
    console.log("Not Integrated");
    setLoading(false);
  };

  const handleAskOvie = () => {
    switch (askOvieType) {
      case "Policy":
        policyAskOvie();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "Policy",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "AAO_Decisions":
        federalCaselawAsk();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "AAO_Decisions",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "FederalCaselaw":
        federalCaselawAsk();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "FederalCaselaw",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;

      case "RegulationsPrimary":
        regulationAskOvie();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "RegulationsPrimary",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "RegulationsSecondary":
        regulationAskOvieSecondary();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "RegulationsSecondary",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "Witness":
        witnessAskOvie();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "Witness",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "Expert":
        expertAskOvie();
        // dispatch(
        //   askChatBotTC({
        //     msgType: "Expert",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        break;
      case "GenerateResponse":
        // dispatch(
        //   askChatBotTC({
        //     msgType: "GenerateResponse",
        //     issue: currentIssue?.title || "Sum Issue",
        //   })
        // );
        generateResponse();
        break;

      case "PolicySecondary":
        policySecondary();
        break;
    }
  };

  return (
    <>
      {loading && <div className="liberty-loader" />}
      {label && (
        <StyledText size={14} textAlign="left">
          {label}
        </StyledText>
      )}
      <StyledTextAreaWrap>
        <StyledTextarea
          value={value}
          height={heightNum || height}
          onChange={(e) => {
            if (fieldName && setFieldValue) {
              setFieldValue(fieldName, e.currentTarget.value);
              dispatch(
                editAnswer({
                  id: +currentStep,
                  name: fieldName,
                  answer: e.currentTarget.value,
                  beId:
                    currentStep === 1 || currentStep === 2
                      ? fieldName === "primaryTextarea"
                        ? answer[currentStep].questions[5].beId
                          ? answer[currentStep].questions[5].beId
                          : ""
                        : answer[currentStep].questions[6].beId
                        ? answer[currentStep].questions[6].beId
                        : ""
                      : currentStep === 3
                      ? fieldName === "primaryTextarea"
                        ? answer[currentStep].questions[0].beId
                          ? answer[currentStep].questions[0].beId
                          : ""
                        : answer[currentStep].questions[1].beId
                        ? answer[currentStep].questions[1].beId
                        : ""
                      : currentStep === 7
                      ? answer[currentStep].questions[0].beId
                        ? answer[currentStep].questions[0].beId
                        : ""
                      : currentStep === 4
                      ? fieldName === "primaryTextarea"
                        ? answer[currentStep].questions[0].beId
                          ? answer[currentStep].questions[0].beId
                          : ""
                        : answer[currentStep].questions[1].beId
                        ? answer[currentStep].questions[1].beId
                        : ""
                      : currentStep === 5
                      ? fieldName === "primaryTextarea"
                        ? answer[currentStep].questions[0].beId
                          ? answer[currentStep].questions[0].beId
                          : ""
                        : answer[currentStep].questions[1].beId
                        ? answer[currentStep].questions[1].beId
                        : ""
                      : currentStep === 6 && fieldName === "primaryTextarea"
                      ? answer[currentStep].questions[0].beId
                        ? answer[currentStep].questions[0].beId
                        : ""
                      : answer[currentStep].questions[1].beId
                      ? answer[currentStep].questions[1].beId
                      : "",
                  sectionMappingId:
                    currentStep === 1 || currentStep === 2 || currentStep === 3
                      ? answer[currentStep].questions[1].sectionMappingId
                        ? answer[currentStep].questions[1].sectionMappingId
                        : ""
                      : answer[currentStep].questions[0].sectionMappingId
                      ? answer[currentStep].questions[0].sectionMappingId
                      : "",
                })
              );
            }
          }}
        />
        {askOvieType && (
          <StyledOvieBtn onClick={handleAskOvie}>
            <Message />
            <StyledText size={12} cursor="pointer">
              {currentStep === 7 ? "Generate Response" : "Ask Ovie"}
            </StyledText>
          </StyledOvieBtn>
        )}
      </StyledTextAreaWrap>
      {hasCheckbox && (
        <StyledLabelWrap>
          <label>
            <StyledCheckbox checked={checked} onChange={communityPost} />
            <StyledText size={14} textAlign="left">
              Share with community
            </StyledText>
          </label>
        </StyledLabelWrap>
      )}
    </>
  );
};

export default TextArea;
