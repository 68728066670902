import "../src/styles/style.css";
import { Provider } from "react-redux";
import store from "./store/store";
import "./App.css";
import "src/assets/fonts/font-face.css";
import AppRoute from "./route";

const App = () => {
  return (
    <Provider store={store}>
      <AppRoute />
    </Provider>
  );
};

export default App;
