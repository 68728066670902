import { FC, FormEvent, useState } from "react";
import { useAppDispatch } from "src/hooks";
import { editAnswer, removeAnswerFile } from "src/slices/answersSlice";
import Attachment from "src/components/Attachment";
import { TempFilesType } from "../EvidenceForm";
import { StyledText } from "src/components/Text/styled";
import { StyledInput } from "src/components/Input/styled";
import { EvidenceElement, EvidenceElementsHead } from "../styled";
import { addNewData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { useLocation } from "react-router";
import { alertOptions } from "src/utils/helper";
import { toast } from "react-toastify";

interface IStepFile {
  id: number;
  files: TempFilesType[];
  setFieldValue: (name: string, value: TempFilesType[] | string) => void;
}

const StepFile: FC<IStepFile> = ({ id, files, setFieldValue }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleDescriptionChange = (
    e: FormEvent<HTMLInputElement>,
    index: number
  ) => {
    let value = e.currentTarget.value;
    const newArray = files.map((el, i) => {
      return i === index ? { ...el, description: value } : el;
    });

    setFieldValue(`files`, newArray);
    dispatch(editAnswer({ id, name: "files", answer: newArray }));
  };

  const delAttachment = (index: number) => {
    setLoading(true);
    let arr = files.filter((el: any, i: any) => i !== index);
    setFieldValue("files", arr);

    dispatch(removeAnswerFile({ id, name: "files", files: arr }));
    const formData = new FormData();
    if (location?.state?.subIssue) {
      formData.append("subIssueId", location?.state?.data?.id);
      formData.append("issueId", location.state.data.issueId);
    }
    if (!location?.state?.subIssue) {
      formData.append("issueId", location.state.data.id);
    }
    formData.append("uploadId", location?.state?.id);
    formData.append("sectionId", "1");
    formData.append("answer", JSON.stringify([]));
    const removeFileIds: any = files
      .filter((el: any, i: any) => i === index)
      .map((en: any) => en.fileId);
    formData.append("removeFileIds[]", JSON.stringify(removeFileIds));
    addNewData(formData, COMMON_ENDPOINT.question)
      .then((re) => {
        toast.success("file delete successfully", alertOptions);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {loading && <div className="liberty-loader" />}
      {files && !!files?.length && (
        <EvidenceElementsHead>
          <StyledText
            color={"rgba(172, 189, 202, 1)"}
            size={10}
            weight={500}
            family={"LibreFranklin"}
          >
            EVIDENCE FILE
          </StyledText>
          <StyledText
            color={"rgba(172, 189, 202, 1)"}
            size={10}
            weight={500}
            family={"LibreFranklin"}
          >
            EVIDENCE DESCRIPTION
          </StyledText>
        </EvidenceElementsHead>
      )}
      {files &&
        files.map((el: any, i: any) => (
          <EvidenceElement key={i}>
            <div>
              <Attachment
                docName={el.fileName}
                onCloseClick={() => delAttachment(i)}
              />
            </div>
            <div>
              <StyledInput
                style={{ marginBottom: 0 }}
                hasError={false}
                hasLabel={false}
                value={el.description}
                onChange={(e) => handleDescriptionChange(e, i)}
              />
            </div>
          </EvidenceElement>
        ))}
    </>
  );
};

export default StepFile;
