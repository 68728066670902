import styled from "styled-components";



export const StyledWorkspaceContainer = styled.div`
display:flex;
width:100%;
`;


export const StyledLabel = styled.label`
  font-size: 12px;
  color: white;
  font-family: Libre Franklin, sans-serif;
  display: block;
  width: 100%;
`;

export const StyledTextarea = styled.textarea<{ clicked: boolean }>`
  background: #1F323F;
  padding: 8px 16px;
  width: 100%;
  height: 100px;
  font-size: 14px;
  border: none;
  outline: none;
  color: ${({ clicked }) => clicked ? "yellow" : "white"};
  margin-top: 8px;
  display: block;
`;

export const StyledInput = styled.input<{ clicked: boolean }>`
  background: #1F323F;
  padding: 8px 16px;
  width: 100%;
  font-size: 14px;
  border: none;
  outline: none;
  color: ${({ clicked }) => clicked ? "yellow" : "white"};
  margin-top: 8px;
  display: block;
`;

export const StyledPaper = styled.div`
  width: 700px;
  background: white;
  font-family: Libre Franklin, sans-serif;
  padding: 32px;
  height: max-content;
`;

export const StyledDocumentWorkspace = styled.div`
  & div {
    ::-webkit-scrollbar {
      width: 12px;
      background: #070F15;
      padding: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #070F15;
      border: 1px solid #1F323F;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: content-box;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

export const StyledAssembleBtn = styled.a`
  border: 1px solid #1f323f;
  background: transparent;
  padding: 12px 14px;
  display: flex;
  width: max-content;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: none;
`;