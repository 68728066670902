import { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import Icons from "../../../components/Icons";
import { StyledAnswer, StyledAnswers } from "../styled";
import { editAnswer } from "src/slices/answersSlice";

interface IQuestionnaireAnswers {
  showForm: boolean;
  currentStep: number;
  dataLength: number;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const QuestionnaireAnswers: FC<IQuestionnaireAnswers> = ({
  showForm,
  setShowForm,
  currentStep,
  dataLength,
  setCurrentStep,
}) => {
  const dispatch = useAppDispatch();
  const answer: any = useAppSelector((state) => state.answers.value);

  const getFieldName = () => {
    if (currentStep === 0) return "hasAdditionalFacts";

    return currentStep === 1 ? "hasWitness" : "hasExpert";
  };

  const onSuccess = () => {
    setShowForm(true);

    if (currentStep < 3) {
      dispatch(
        editAnswer({
          id: currentStep,
          name: getFieldName(),
          answer: "true",
          beId:
            currentStep === 1 || currentStep === 2
              ? answer[currentStep].questions[0].beId
                ? answer[currentStep].questions[0].beId
                : ""
              : "",
          sectionMappingId:
            currentStep === 1 || currentStep === 2
              ? answer[currentStep].questions[0].sectionMappingId
                ? answer[currentStep].questions[0].sectionMappingId
                : ""
              : "",
        })
      );
    }
  };

  const onDenial = () => {
    setCurrentStep((prevState) => prevState + 1);

    if (currentStep < 3) {
      dispatch(
        editAnswer({
          id: currentStep,
          name: getFieldName(),
          answer: "false",
          beId: answer[currentStep].questions[0].beId
            ? answer[currentStep].questions[0].beId
            : "",
          sectionMappingId: answer[currentStep].questions[0].sectionMappingId
            ? answer[currentStep].questions[0].sectionMappingId
            : "",
        })
      );
    }
  };

  if (!showForm && currentStep !== dataLength - 1 && currentStep < 3) {
    return (
      <StyledAnswers>
        <StyledAnswer onClick={onSuccess}>
          <Icons.SuccessIcon />
        </StyledAnswer>
        <StyledAnswer onClick={onDenial}>
          <Icons.DenialIcon />
        </StyledAnswer>
      </StyledAnswers>
    );
  }

  return <></>;
};

export default QuestionnaireAnswers;
