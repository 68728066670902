import { Dispatch, FC, SetStateAction } from "react";
import { BackBtn } from "./BackBtn";
import { NextBtn } from "./NextBtn";
import { StyledBtns } from "../styled";

interface IQuestionnaireBtns {
  showForm: boolean;
  currentStep: number;
  dataLength: number;
  isFilesLength: boolean;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const QuestionnaireBtns: FC<IQuestionnaireBtns> = ({
  showForm,
  currentStep,
  dataLength,
  isFilesLength,
  setShowForm,
  setCurrentStep,
}) => {
  const isSummary = currentStep + 1 === dataLength;

  return (
    <StyledBtns margin={isSummary ? "10px 0 0px auto" : "40px auto 0"}>
      <BackBtn
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setShowForm={setShowForm}
        showForm={showForm}
      />
      <NextBtn
        currentStep={currentStep}
        dataLength={dataLength}
        setCurrentStep={setCurrentStep}
        setShowForm={setShowForm}
        showForm={showForm}
        isFilesLength={isFilesLength}
      />
    </StyledBtns>
  );
};

export default QuestionnaireBtns;
