import {
  StyledAuthor,
  StyledCommunityItem,
  StyledCompany,
  StyledContent,
  StyledCopy,
  StyledFullContent,
  // StyledNegative,
  // StyledPositive,
  StyledPreview,
  // StyledRating,
  StyledShowMore,
} from "./styled";
import Icons from "../Icons";
// import {Dispatch, FC, SetStateAction, useState} from "react";
import { FC, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Tooltip, Typography } from "@mui/material";

interface ICommunityItem {
  author: string;
  company: string;
  contentPreview: string;
  contentFull: string;
  // likeCount: number;
  // dislikeCount: number;
  index: number;
  updateCopyValue: any;
  copiedValue: {
    value: boolean;
    index: number;
  };
}

// const likeOnClickHandle = (setLikesCount: Dispatch<SetStateAction<number>>, setDislikesCount: Dispatch<SetStateAction<number>>, likeCount: number, dislikeCount: number) => {
//   setLikesCount((prevState) => {
//     if (prevState == likeCount) {
//       setDislikesCount((prevState) => {
//         if (prevState > dislikeCount) {
//           return prevState - 1;
//         }
//         return prevState;
//       });
//       return prevState + 1;
//     }
//     return prevState - 1;
//   });
// }

// const dislikeOnClickHandle = (setDislikesCount: Dispatch<SetStateAction<number>>, setLikesCount: Dispatch<SetStateAction<number>>, dislikeCount: number, likeCount: number) => {
//   setDislikesCount((prevState) => {
//     if (prevState === dislikeCount) {
//       setLikesCount((prevState) => {
//         if (prevState > likeCount) {
//           return prevState - 1;
//         }
//         return prevState;
//       });
//       return prevState + 1;
//     }
//     return prevState - 1;
//   });
// }
const CustomTooltip = ({ title, fontSize, children }: any) => {
  const customStyle = {
    fontSize: fontSize,
  };

  return (
    <Tooltip arrow title={<Typography style={customStyle}>{title}</Typography>}>
      {children}
    </Tooltip>
  );
};
export const CommunityItem: FC<ICommunityItem> = ({
  author,
  company,
  contentPreview,
  contentFull,
  index,
  updateCopyValue,
  copiedValue,
  // likeCount,
  // dislikeCount
}) => {
  const [showFullContent, setShowFullContent] = useState(false);

  // const [likesCount, setLikesCount] = useState(likeCount);
  // const [dislikesCount, setDislikesCount] = useState(dislikeCount);

  return (
    <StyledCommunityItem>
      <StyledAuthor>{author}</StyledAuthor>
      <StyledCompany>{company}</StyledCompany>
      <StyledContent>
        <StyledPreview hide={showFullContent}>{contentPreview}</StyledPreview>
        <StyledFullContent active={showFullContent}>
          {contentFull}
        </StyledFullContent>
        <StyledShowMore
          active={showFullContent}
          onClick={() => setShowFullContent((prevState) => !prevState)}
        >
          {!showFullContent ? "Show More" : "Show Less"} {Icons.ArrowDownIcon}{" "}
        </StyledShowMore>
        {copiedValue.value && index === copiedValue.index ? (
          <CustomTooltip title={"Copied"} fontSize={"13px"}>
            <StyledCopy>
              <CheckIcon fontSize="small" sx={{ fill: "yellowgreen" }} />
            </StyledCopy>
          </CustomTooltip>
        ) : (
          <CustomTooltip title={"Copy"} fontSize={"13px"}>
            <StyledCopy onClick={() => updateCopyValue(contentFull, index)}>
              {Icons.CopyIcon}
            </StyledCopy>
          </CustomTooltip>
        )}
      </StyledContent>
      {/* <StyledRating>
        <StyledPositive
          liked={likesCount !== likeCount}
          onClick={() => likeOnClickHandle(setLikesCount, setDislikesCount, likeCount, dislikeCount)
          }
        >
          {likesCount} {Icons.RatingPositiveIcon}
        </StyledPositive>
        <StyledNegative
          disliked={dislikesCount !== dislikeCount}
          onClick={() => dislikeOnClickHandle(setDislikesCount, setLikesCount, dislikeCount, likeCount)}
        >
          {dislikesCount} {Icons.RatingNegativeIcon}
        </StyledNegative>
      </StyledRating> */}
    </StyledCommunityItem>
  );
};
