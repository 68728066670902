import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IIssue } from "src/types";

interface issuesState {
  issues: Array<IIssue>
}

const initialState: issuesState = {
  issues: []
};

export const issues = createSlice({
  name: "issues",
  initialState,
  reducers: {
    handleIssues: (state, action: PayloadAction<{ issues: Array<IIssue> }>) => {
      state.issues = action.payload.issues;
    },
  },
});

export const { handleIssues } = issues.actions;

export default issues.reducer;
