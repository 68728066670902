export const BASE_URL = process.env.REACT_APP_APIURL;

export const MASTER_ENDPOINT = {
  Login: "/auth/login",
  Users: "/users",
  Otp: "/password/forgot-password",
  ResetPassword: "/password/reset-password",
  VerifyOtp: "/password/verify-otp",
  ResendOtp: "/password/resend-otp",
  logout: "/auth/logout",
};
export const COMMON_ENDPOINT = {
  UploadedDoc: "/upload",
  getUploadedDoc: "/assemble/uploadedDoc",
  getbuildedDoc: "/assemble/buildedDoc",
  visaType: "/master/visaTypes",
  file: "/upload/file",
  issues: "/issues",
  question: "/questions",
  preparerGet: "/preparers-information",
  attorneyDetails: "/attorney-form",
  PreparerInfo: "/preparers-information",
  buildDocument: "/build-document",
  previewDocument: "/generate-document",
  Assemble: "/assemble",
  regulationAskOvie: "/ask-ovie/regulation",
  witnessAskOvie: "/generation/witness-testimony",
  policyAskOvie: "/ask-ovie/policy",
  federalCaseLaw: "/ask-ovie/search-query",
  expertAskOvie: "/ask-ovie/process-selected-regulation",
  regulationSecondaryAskovie: "/generation/regulation-answer",
  assembleReName: "/assemble/rename-file",
  buildDocumentFile: "/build-document/build-file",
  assemble: "/assemble/assemble-documents",
  assembleGet: "/assemble/assemble-doc",
  assembleHeader: "/assemble/assemble-documents/exhibit",
  headerRename: "/assemble/exhibit-header/rename",
  InferanceAPI: "/upload/inference/check",
  responseGenerator: "/generation/expert-response",
  communityIssues: "/community-database/similar-issues",
  communityPost: "/community-database/create-item?anonymize=false",
  assembleAll: "/assemble/assembleAll",
  findAssembleAllBuildDoc: "/assemble/assembleAll/docs",
  getAssembleAllFile: "/assemble/assembleAll/build-file",
  deleteAllAssembleDocs: "/assemble/assemble-doc/deleteAll",
};
