import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommunityAnswerStateItem, SimilarIssues } from "../types";
import axios from "axios";

import { COMMON_ENDPOINT } from "src/utils/Constant";
import Storage from "src/utils/Storage";
import { toast } from "react-toastify";

let initialState: any = {
  answers: [],
};

export const getCommunityAnswersTC: any = createAsyncThunk(
  "communityAnswers/getAnswers",
  async (param, { rejectWithValue, dispatch, getState }) => {
    // let state = getState() as RootState;
    const TokenStatus: any = Storage.getTokens();
    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : "";
    const { subIssue, issueData, visaType, data }: any = param;

    let body = {
      subIssue: subIssue ? data?.title : "",
      issue: issueData ? issueData?.title : !subIssue ? data?.title : "",
      visaType: visaType,
      numResponses: 3,
    };

    axios
      .post(
        `https://v1.visas.ai/api/v1/rfe${COMMON_ENDPOINT.communityIssues}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data && res.data.data) {
          let dataToSet = res.data.data.similar_issues.map(
            (el: SimilarIssues): CommunityAnswerStateItem => {
              // let itemNum = getRandomValue(12);
              return {
                contentPreview: el.rfe_answer.substring(0, 104) + "...",
                contentFull: el.rfe_answer,
                author: "",
                company: "",
                dislikeCount: 15,
                likeCount: 25,
              };
            }
          );
          dispatch(replaceAnswer(dataToSet));
        } else {
          rejectWithValue(null);
        }
      })
      .catch((err) => toast.error(err?.message));
  }
);

export const communityAnswers = createSlice({
  name: "communityAnswers",
  initialState,
  reducers: {
    replaceAnswer: (
      state,
      action: PayloadAction<Array<CommunityAnswerStateItem>>
    ) => {
      state.answers = action.payload;
      return state;
    },
  },
});

export const { replaceAnswer } = communityAnswers.actions;

export default communityAnswers.reducer;
