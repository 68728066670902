import { FC, useEffect, useRef, useState } from "react";
import { StyledChatAreaContainer, StyledMessageContainer } from "./styled";
import ChatAreaIcon from "../../assets/images/ChatArea.png";
import { useAppSelector } from "../../hooks";
import { MessageObj } from "../../types";
import ChatMessage from "./ChatMessage";

interface textCopy {
  value: boolean;
  index: number;
}
const ChatArea: FC = () => {
  const messages = useAppSelector((state) => state.chatBot.messages);
  const ref = useRef<HTMLDivElement>(null);
  const [textCopied, setTextCopied] = useState<textCopy>({
    value: false,
    index: 0,
  });
  const scrollToLastMessage = () => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const copyOnClickHandle = (text: string, indexValue: number) => {
    setTextCopied({
      value: true,
      index: indexValue,
    });
    return navigator.clipboard.writeText(text);
  };
  useEffect(() => {
    setTextCopied({
      value: false,
      index: 0,
    });
    scrollToLastMessage();
  }, [messages]);

  return (
    <StyledChatAreaContainer>
      <StyledMessageContainer ref={ref}>
        {messages.map((el: MessageObj, i: number) => (
          <ChatMessage
            title={el.title}
            text={el.text}
            key={i}
            indexValue={i}
            copyOnClickHandle={copyOnClickHandle}
            textCopied={textCopied}
          />
        ))}
      </StyledMessageContainer>
      <img src={ChatAreaIcon} alt={"Chat bot"} />
    </StyledChatAreaContainer>
  );
};

export default ChatArea;
