import { ChangeEvent, useEffect, useState, FC } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useAppDispatch } from "src/hooks";
import { addDocument } from "src/slices/documentsSlice";
import { StyledText } from "src/components/Text/styled";
import { ReactComponent as Send } from "src/assets/images/Send.svg";
import { ReactComponent as Close } from "src/assets/images/Close.svg";
import { ReactComponent as Checkmark } from "src/assets/images/Checkmark.svg";
import {
  StyledUploadButton,
  StyledUploadInput,
  StyledUploadDrops,
  StyledDropLabel,
  StyledDropModal,
  EditpopupModal,
  Perrow,
} from "./styled";
import { addNewData, getAllListData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { UUID } from "crypto";
import { toast } from "react-toastify";
import { alertOptions } from "src/utils/helper";
import moment from "moment";

const Modalcuscomp: FC<{
  id?: any;
  key?: any;
  actiond?: boolean;
  fileName?: string;
  createdAt?: string;
  onClick?: () => void;
  documents?: any;
}> = ({ id, actiond, fileName, createdAt, onClick, key, documents }) => {
  // const duplicate: any = documents.some((res: any) => res.id === id);
  return (
    <Perrow
      className="perrow"
      key={key}
      style={{
        backgroundColor: actiond ? "rgba(43, 64, 79, 0.7)" : "transparent",
        // opacity: duplicate ? 0.2 : "",
        // cursor: duplicate ? "not-allowed" : "",
      }}
    >
      <span
        className={`actiondcircle`}
        style={{ borderColor: actiond ? "#3399FF" : "" }}
        onClick={onClick}
      >
        {actiond ? (
          <span className={`tickstyle`}>
            <Checkmark />
          </span>
        ) : (
          <></>
        )}
      </span>
      <span>{fileName ? fileName : ""}</span>
      <span style={{ paddingLeft: "30px" }}>{createdAt ? createdAt : "-"}</span>
    </Perrow>
  );
};

const UploadFile = ({ getData, documents }: any) => {
  const dispatch = useAppDispatch();
  // console.log(documents);

  const [clik, setClik] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modaState, setmodaState] = useState({ open: false, name: "" });
  const [modresdata, setmodresdata] = useState<{ upload: any[]; build: any[] }>(
    { upload: [], build: [] }
  );

  const { id } = useParams();

  useEffect(() => {
    if (
      modaState.open &&
      modaState.name &&
      modaState.name === "Uploaded Documents"
    ) {
      getAllListData(
        `${COMMON_ENDPOINT.getUploadedDoc}?uploadId=${id}&type=question`
      ).then((res) => {
        console.log(res);
        setmodresdata((p) => ({
          ...p,
          upload:
            res && res.data
              ? res.data.map((ety: any) => ({ ...ety, actiond: false }))
              : [],
        }));
      });
    }

    if (
      modaState.open &&
      modaState.name &&
      modaState.name === "Build Documents"
    ) {
      getAllListData(`${COMMON_ENDPOINT.getbuildedDoc}?uploadId=${id}`).then(
        (res) => {
          setmodresdata((p) => ({
            ...p,
            build:
              res && res.data
                ? res.data.map((ety: any) => ({ ...ety, actiond: false }))
                : [],
          }));
        }
      );
    }
  }, [modaState.open, modaState.name, id]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.files, "files");

    setClik(false);
    const files = e.target && e.target.files ? e.target.files : [];

    const formData = new FormData();
    formData.append(`fileType`, "document");
    formData.append(`assembleType`, "assembleUpload");
    formData.append(`uploadId`, id as UUID);
    // if(files.length !> 0) {return}

    Array.from(files).forEach((fil, idx) => {
      formData.append(`file${idx}`, fil);

      if (fil) {
        const { name } = fil;
        // const { name } = e.currentTarget.files?.[0] ?? {};
        const extension = name && name.split(".").pop();
        const isExtensionValid =
          extension && (extension === "pdf" || extension === "docx");
        const isValid = name && id && isExtensionValid;

        fil &&
          isValid &&
          dispatch(
            addDocument({
              id: v4(),
              caseId: id,
              name: fil.name,
              extension,
            })
          );
      }
    });
    try {
      setLoading(true);
      const assembleresponse: any = await addNewData(
        formData,
        COMMON_ENDPOINT.Assemble,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(
        assembleresponse &&
          assembleresponse.message &&
          assembleresponse.status === 201
          ? assembleresponse.message
          : "file uploaded successfully",
        alertOptions
      );
      getData();
    } catch (e) {
      typeof e === "string"
        ? toast.error(e ? e : "Please login again. ", alertOptions)
        : toast.error("Please login again. ", alertOptions);
    } finally {
      setClik(false);
      setLoading(false);
    }
    // console.log(JSON.stringify(formData), "formdata");
    // console.log(formData, "formdata");
  };

  const handleSend = async () => {
    // console.log('joke');
    const formData = new FormData();
    formData.append(`fileType`, "document");
    formData.append(`assembleType`, "buildedDocs");
    formData.append(`uploadId`, id as UUID);
    // if (
    //   modaState.open &&
    //   modaState.name &&
    //   modaState.name === "Uploaded Documents"
    // ) {
    //   console.log(modaState.name);
    //   const valarray: any = modresdata.upload
    //     .filter((ea1: any) => ea1.actiond === true)
    //     .map((ea2: any) => ea2.id);
    //   formData.append(`fields`, valarray);
    // }

    if (
      modaState.open &&
      modaState.name &&
      modaState.name === "Build Documents"
    ) {
      const valarray: any = modresdata.build
        .filter((ea1: any) => ea1.actiond === true)
        .map((ea2: any) => ea2.id);
      formData.append(`fileIds`, JSON.stringify(valarray));
    }

    try {
      setLoading(true);
      const assembleresponse: any = await addNewData(
        formData,
        COMMON_ENDPOINT.Assemble,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(
        assembleresponse &&
          assembleresponse.message &&
          assembleresponse.status === 201
          ? assembleresponse.message
          : "file uploaded successfully",
        alertOptions
      );
    } catch (e) {
      typeof e === "string"
        ? toast.error(e ? e : "Please login again. ", alertOptions)
        : toast.error("Please login again. ", alertOptions);
    } finally {
      setmodaState({ open: false, name: "" });
      setmodresdata((p: any) => ({
        ...p,
        build: p.build.map((er: any) => ({ ...er, actiond: false })),
        upload: p.upload.map((er: any) => ({ ...er, actiond: false })),
      }));
      setClik(false);
      setLoading(false);
      getData();
    }
  };

  return (
    <>
      {loading && <div className="liberty-loader" />}
      <StyledUploadButton>
        <Send />
        <StyledText
          color="#114d9c"
          cursor="pointer"
          weight={600}
          onClick={() => setClik((p) => !p)}
        >
          Upload
        </StyledText>
        {clik && (
          <>
            <StyledUploadDrops>
              <StyledDropLabel htmlFor="upload-input">
                Upload File
              </StyledDropLabel>
              {/* <StyledDropLabel
                onClick={() =>
                  setmodaState((p) => ({
                    ...p,
                    open: !false,
                    name: "Uploaded Documents",
                  }))
                }
              >
                Select Uploaded Documents
              </StyledDropLabel> */}
              <StyledDropLabel
                onClick={() =>
                  setmodaState((p) => ({
                    ...p,
                    open: !false,
                    name: "Build Documents",
                  }))
                }
              >
                Select Build Documents
              </StyledDropLabel>
            </StyledUploadDrops>
            <StyledDropModal onClick={() => setClik(false)} />
          </>
        )}
      </StyledUploadButton>
      <StyledUploadInput
        id="upload-input"
        type="file"
        multiple
        onChange={handleUpload}
      />
      {modaState.open && (
        <EditpopupModal>
          <div className="mainmodalcontainer">
            <header>
              <span>{modaState.name}</span>
              <span
                onClick={() => setmodaState((p) => ({ ...p, open: false }))}
              >
                <Close />
              </span>
            </header>
            <div className="contentcontainer">
              <header>
                <span>Action</span>
                <span>Name</span>
                <span style={{ paddingLeft: "30px", textAlign: "left" }}>
                  Date
                </span>
              </header>
              <main>
                {modresdata &&
                  modresdata.upload &&
                  modresdata.upload.length > 0 &&
                  modaState.name === "Uploaded Documents" &&
                  modresdata.upload.map((datd: any) => {
                    return (
                      <Modalcuscomp
                        fileName={datd ? datd.fileName : ""}
                        createdAt={
                          datd
                            ? moment(datd.createdAt).format("YYYY-MM-DD")
                            : "-"
                        }
                        key={datd && datd.id ? datd.id : ""}
                        id={datd && datd.id ? datd.id : ""}
                        actiond={datd && datd.actiond ? datd.actiond : false}
                        onClick={() =>
                          setmodresdata((p: any) => ({
                            ...p,
                            upload: p.upload.map((er: any) =>
                              er.id === datd.id
                                ? { ...er, actiond: !er.actiond }
                                : er
                            ),
                          }))
                        }
                      />
                    );
                  })}
                {modresdata &&
                  modresdata.build &&
                  modresdata.build.length > 0 &&
                  modaState.name === "Build Documents" &&
                  modresdata.build.map((datd: any) => {
                    return (
                      <Modalcuscomp
                        documents={documents}
                        fileName={datd ? datd.fileName : ""}
                        createdAt={
                          datd
                            ? moment(datd.createdAt).format("YYYY-MM-DD")
                            : "-"
                        }
                        key={datd && datd.id ? datd.id : ""}
                        id={datd && datd.id ? datd.id : ""}
                        actiond={datd && datd.actiond ? datd.actiond : false}
                        onClick={() =>
                          setmodresdata((p: any) => ({
                            ...p,
                            build: p.build.map((er: any) =>
                              er.id === datd.id
                                ? { ...er, actiond: !er.actiond }
                                : er
                            ),
                          }))
                        }
                      />
                    );
                  })}
              </main>
              <div className="buttoncon">
                <button className="save" onClick={handleSend}>
                  send
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: 299,
              backgroundColor: "rgba(240, 240, 240, 0.15)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(1px)",
            }}
            onClick={() => setmodaState((p) => ({ ...p, open: false }))}
          ></div>
        </EditpopupModal>
      )}
    </>
  );
};

export default UploadFile;
