import styled, { css } from "styled-components";
import Checkmark from "src/assets/images/CheckmarkWhite.svg";

export const StyledQnaryMainblock = styled.div`
  display: flex;
`;

export const StyledGenerateresblock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const StyledPanel = styled.div`
  position: absolute;
  width: 15vw;
  min-width: 288px;
  top: 88px;

  div {
    width: 100%;
  }
`;

export const StyledSection = styled.section<{ isSummary?: boolean }>`
  position: relative;
  background: #0a1720;
  // width: 85vw;
  width: 100%;
  height: calc(100vh - 88px);
  left: -5px;
  // margin: 0 auto;
  padding: 32px;
  border: 1px solid #1f323f;
  overflow-y: scroll;

  ${({ isSummary }) =>
    isSummary &&
    css`
      max-height: calc(100vh - 252px);
      max-height: calc(100vh - 300px);
      // padding-bottom: 275px;
      padding-bottom: 110px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #070f15;
        border: 1px solid #1f323f;
      }

      ::-webkit-scrollbar-thumb {
        width: 4px;
        background: #3c5262;

        border-radius: 4px;
      }
    `}
  .back {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "LibreFranklin", serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    margin-bottom: 22px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledHead = styled.div`
  background: #1f323f;
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
`;

export const StyledNumber = styled.div`
  font-family: "LibreFranklin", serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #0080ff;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const StyledTitle = styled.div`
  font-family: "Inter", serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #fff;
  margin-bottom: 4px;
`;

export const StyledText = styled.div`
  font-family: "Inter", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #acbdca;
`;

export const StyledTabs = styled.div`
  counter-reset: tabsCounter;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledTab = styled.div<{ active?: boolean; passed?: boolean }>`
  position: relative;
  counter-increment: tabsCounter;
  width: 8%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-family: "LibreFranklin", serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  text-align: center;

  &:before {
    ${({ passed }) =>
      passed
        ? css`
            cursor: pointer;
            content: url(${Checkmark});
            background: #0080ff !important;
          `
        : css`
            content: counter(tabsCounter);
          `};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    min-height: 24px;
    transition: all 0.5s ease;
    background: ${({ active }) => (active ? "#0080FF" : "#516879")};
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -6%;
      transform: translate(100%, 50%);
      height: 1px;
      width: 50%;
      background: #2b404f;
    }
  }
`;

export const StyledAnswers = styled.div`
  width: 388px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledAnswer = styled.div`
  cursor: pointer;
  width: 100%;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const StyledBtns = styled.div<{ margin: string }>`
  display: flex;
  gap: 16px;
  width: max-content;
  margin: ${({ margin }) => margin};
`;

export const StyledBtn = styled.div<{ hasBg?: boolean; disabled?: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border: 1px solid #ffffff;
  font-family: "LibreFranklin", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.35s ease;

  ${({ hasBg }) =>
    hasBg
      ? css`
          background: #ffffff;
          color: #070d12;
        `
      : css`
          color: #ffffff;
        `};

  ${({ disabled }) =>
    !disabled
      ? css`
          opacity: 1;
        `
      : css`
          cursor: no-drop;
          opacity: 0.3;
        `};
`;
export const StyledButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;
export const StyledShowMore = styled.div<{ active?: boolean }>`
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  color: #ffffff;

  svg {
    margin-top: 2px;
    transition: all 0.3s ease;
  }

  ${({ active }) =>
    active
      ? css`
          svg {
            transform: rotate(180deg);
          }
        `
      : css`
          svg {
            transform: rotate(0deg);
          }
        `};
`;
