import { FC, ReactElement } from "react";
import {
  StyledButton,
  StyledHeading,
  StyledIcon,
  StyledIssueNumber,
  StyledIssueScrollcontainer,
  StyledIssueText,
  StyledIssueTitle,
  StyledPanel,
  // StyledProgress,
  StyledProgressInfo,
  StyledProgressMini,
  StyledSubHeading,
  StyledTile,
} from "./styled";
// import { mockData } from "./mockData";
import plus from "src/assets/images/Plus.svg";
import { IPanelProps } from "src/types";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_QUESTIONNAIRE } from "../../routes";
// import { useAppDispatch } from "../../hooks";
// import { setCurrentIssue } from "../../slices/currentIssue";
// import PenIcon from "src/assets/images/Pen.svg";
// import { getAllListData } from "src/services/commonServices";
// import { COMMON_ENDPOINT } from "src/utils/Constant";
// import PSPDFKit from "pspdfkit";

const Panel: FC<IPanelProps> = ({
  mock,
  setMock,
  activationRef,
  setActivation,
  setheaderprogress,
  data,
  setData,
  issueId,
  setIssueId,
  visaType,
  // editItem,
}) => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // const [data, setData] = useState([]);
  const Plus = (): ReactElement<HTMLImageElement> => (
    <StyledIcon src={plus} margin="0 10px 1px 0" />
  );

  return (
    <StyledPanel>
      <StyledHeading>File name</StyledHeading>
      <StyledSubHeading textTransform="uppercase" style={{ color: "#7591A9" }}>
        Issue List
      </StyledSubHeading>
      <StyledButton
        fontWeight={500}
        bgColor={"#0F5AB7"}
        onClick={() => setActivation(true)}
        active={activationRef}
      >
        <Plus />
        Add Issue
      </StyledButton>
      {/* <div style={{ overflowY: "auto", maxHeight: "100%", scrollbarWidth: 'none' }}> */}
      <StyledIssueScrollcontainer>
        <div style={{ height: "10px" }}></div>
        {data &&
          data.map((item: any, index: any) => {
            return (
              <>
                <StyledTile
                  key={index}
                  padding={"12px 16px"}
                  title={item.title}
                  style={{
                    background: "#1b2f3c",
                    borderLeft: `2.5px solid ${
                      index % 2 === 0 ? "#FF1744" : "#FF9800"
                    }`,
                  }}
                >
                  {/* <button
                    style={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      border: "none",
                      background: "none",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={PenIcon}
                      alt={"edit"}
                      style={{ width: "16px" }}
                      onClick={() => editItem(item.id, item)}
                    />
                  </button> */}
                  <div
                    onClick={(e) => {
                      if (
                        (e.target as HTMLElement).tagName !== "BUTTON" &&
                        (e.target as HTMLElement).tagName !== "IMG"
                      ) {
                        navigate(
                          `${String(ROUTE_QUESTIONNAIRE)}/${id}?issueId=${
                            item?.id
                          }`,
                          {
                            state: {
                              data: item,
                              index: index + 1,
                              id: id,
                              visaType: item?.issue?.visaType,
                              subIssue: false,
                              currentStep: item?.issueAnswerCount,
                            },
                          }
                        );
                      }
                    }}
                  >
                    <StyledIssueNumber>ISSUE #{index + 1}</StyledIssueNumber>
                    <StyledIssueTitle>{item.title}</StyledIssueTitle>
                    <StyledProgressInfo>
                      <p>Progress</p>
                      <p>
                        {item.issueProgressCount
                          ? Number(item.issueProgressCount)
                          : 0}
                        /8
                      </p>
                    </StyledProgressInfo>
                    <StyledProgressMini
                      width={(Number(item.issueProgressCount) / 8) * 100}
                      borderRadius={4}
                    />
                  </div>
                </StyledTile>
                <>
                  {item &&
                    item?.subIssue?.map((subItem: any, subIndex: any) => (
                      <>
                        <StyledTile
                          title={subItem.title}
                          style={{
                            background: "#12356B",
                            borderLeft: `2.5px solid ${
                              index % 2 === 0 ? "#FF1744" : "#FF9800"
                            }`,
                          }}
                          onClick={(e) => {
                            if (
                              (e.target as HTMLElement).tagName !== "BUTTON" &&
                              (e.target as HTMLElement).tagName !== "IMG"
                            ) {
                              navigate(
                                `${ROUTE_QUESTIONNAIRE}/${id}?issueId=${item.id}&subIssueId=${subItem.id}`,
                                {
                                  state: {
                                    data: subItem,
                                    visaType: item?.issue?.visaType,
                                    issueData: item,
                                    index: subIndex + 1,
                                    id: id,
                                    subIssue: true,
                                    currentStep: subItem?.subIssueAnswerCount,
                                  },
                                }
                              );
                            }
                          }}
                        >
                          {/* <button
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              border: "none",
                              background: "none",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={PenIcon}
                              alt={"edit"}
                              style={{ width: "16px" }}
                              onClick={() => editItem(subItem.id)}
                            />
                          </button> */}
                          <StyledIssueNumber>
                            SUBISSUE #{subIndex + 1}
                          </StyledIssueNumber>
                          <StyledIssueTitle>{subItem.title}</StyledIssueTitle>
                          {subItem.description && (
                            <StyledIssueText>
                              {subItem.description}
                            </StyledIssueText>
                          )}
                          <StyledProgressInfo>
                            <p>Progress</p>
                            <p>{subItem.subIssueProgerssCount}/8</p>
                          </StyledProgressInfo>
                          <StyledProgressMini
                            width={
                              (Number(subItem.subIssueProgerssCount) / 8) * 100
                            }
                            borderRadius={4}
                          />
                        </StyledTile>
                      </>
                    ))}
                </>
                <StyledButton
                  fontWeight={600}
                  color={"#0080FF"}
                  onClick={() => {
                    setActivation(true);
                    setIssueId(item.id);
                  }}
                  active={activationRef}
                >
                  Add Sub Issue
                </StyledButton>
              </>
            );
          })}
        <div style={{ height: "120px" }}></div>
      </StyledIssueScrollcontainer>
    </StyledPanel>
  );
};

export default Panel;
