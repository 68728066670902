import Icons from "../Icons";
import Community from "../Community";
import ChatArea from "../ChatArea";

export const TabsBarData = [
  {
    icon: Icons.ChatIcon,
    component: <ChatArea/>
  },
  {
    icon: Icons.CommunityIcon,
    component: <Community/>
  },
]