import Icons from "../../../components/Icons";
import { StyledBtn } from "../styled";
import { Dispatch, FC, SetStateAction } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_RESPONDER } from "../../../routes";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { addNewData } from "src/services/commonServices";
import { COMMON_ENDPOINT } from "src/utils/Constant";
import { clearAnswer } from "src/slices/answersSlice";

interface INextBtn {
  currentStep: number;
  dataLength: number;
  showForm: boolean;
  isFilesLength: boolean;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

export const NextBtn: FC<INextBtn> = ({
  currentStep,
  dataLength,
  showForm,
  isFilesLength,
  setShowForm,
  setCurrentStep,
}) => {
  const answer: any = useAppSelector((state) => state.answers.value);
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams]: any = useSearchParams();
  const submitHandular = () => {
    const formData = new FormData();
    if (location?.state?.subIssue) {
      formData.append("subIssueId", location?.state?.data?.id);
      formData.append("issueId", location.state.data.issueId);
    }
    if (!location?.state?.subIssue) {
      formData.append("issueId", location.state.data.id);
    }
    formData.append("uploadId", location?.state?.id);
    formData.append(
      "id",
      [0, 1, 2, 3].includes(currentStep)
        ? answer[currentStep].questions[1].sectionMappingId
          ? answer[currentStep].questions[1].sectionMappingId
          : ""
        : answer[currentStep].questions[0].sectionMappingId
        ? answer[currentStep].questions[0].sectionMappingId
        : ""
    );
    if (currentStep === 0) {
      const answerData = answer && answer[0]?.questions[1].answer;
      const fileData: any = [];
      answerData.map((el: any) => {
        const uniqueId = Math.floor(
          Math.random() * Math.floor(Math.random() * Date.now())
        );
        formData.append(`file_${uniqueId}`, el.file);
        fileData.push({
          fileType: "document",
          description: el.description,
          fileUniqueId: `file_${uniqueId}`,
        });
        return true;
      });
      const body: any = [
        {
          issueQuestionId: "1",
          answer: answer[0]?.questions[0].answer,
          type: "boolean",
          files: [],
        },
        {
          issueQuestionId: "2",
          answer: "true",
          type: "file",
          files: fileData,
        },
      ];

      formData.append("sectionId", "1");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 1) {
      const answerData = answer && answer[1];
      const body: any = [
        {
          issueQuestionId: "3",
          answer: answerData.questions[0].answer,
          type: "boolean",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "4",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
        {
          issueQuestionId: "5",
          answer: answerData.questions[2].answer,
          type: "textField",
          files: [],
          id: answerData.questions[2].beId ? answerData.questions[2].beId : "",
        },
        {
          issueQuestionId: "6",
          answer: answerData.questions[3].answer,
          type: "textField",
          files: [],
          id: answerData.questions[3].beId ? answerData.questions[3].beId : "",
        },
        {
          issueQuestionId: "7",
          answer: answerData.questions[4].answer,
          type: "textField",
          files: [],
          id: answerData.questions[4].beId ? answerData.questions[4].beId : "",
        },
        {
          issueQuestionId: "8",
          answer: answerData.questions[5].answer,
          type: "textField",
          files: [],
          id: answerData.questions[5].beId ? answerData.questions[5].beId : "",
        },
        {
          issueQuestionId: "9",
          answer: answerData.questions[6].answer,
          type: "textField",
          files: [],
          id: answerData.questions[6].beId ? answerData.questions[6].beId : "",
        },
      ];
      formData.append("sectionId", "2");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 2) {
      const answerData = answer && answer[2];
      const body: any = [
        {
          issueQuestionId: "10",
          answer: answerData.questions[0].answer,
          type: "boolean",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "11",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
        {
          issueQuestionId: "12",
          answer: answerData.questions[2].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
        {
          issueQuestionId: "13",
          answer: answerData.questions[3].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
        {
          issueQuestionId: "14",
          answer: answerData.questions[4].answer,
          type: "textField",
          files: [],
          id: answerData.questions[4].beId ? answerData.questions[4].beId : "",
        },
        {
          issueQuestionId: "15",
          answer: answerData.questions[5].answer,
          type: "textField",
          files: [],
          id: answerData.questions[5].beId ? answerData.questions[5].beId : "",
        },
        {
          issueQuestionId: "16",
          answer: answerData.questions[6].answer,
          type: "textField",
          files: [],
          id: answerData.questions[6].beId ? answerData.questions[6].beId : "",
        },
      ];
      formData.append("sectionId", "3");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 3) {
      const answerData = answer && answer[3];
      const body: any = [
        {
          issueQuestionId: "17",
          answer: answerData.questions[0].answer,
          type: "textField",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "18",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
      ];
      formData.append("sectionId", "4");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 4) {
      const answerData = answer && answer[4];
      const body: any = [
        {
          issueQuestionId: "19",
          answer: answerData.questions[0].answer,
          type: "textField",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "20",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
      ];
      formData.append("sectionId", "5");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 5) {
      const answerData = answer && answer[5];
      const body: any = [
        {
          issueQuestionId: "21",
          answer: answerData.questions[0].answer,
          type: "textField",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "22",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
      ];
      formData.append("sectionId", "6");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 6) {
      const answerData = answer && answer[6];
      const body: any = [
        {
          issueQuestionId: "23",
          answer: answerData.questions[0].answer,
          type: "textField",
          files: [],
          id: answerData.questions[0].beId ? answerData.questions[0].beId : "",
        },
        {
          issueQuestionId: "24",
          answer: answerData.questions[1].answer,
          type: "textField",
          files: [],
          id: answerData.questions[1].beId ? answerData.questions[1].beId : "",
        },
      ];
      formData.append("sectionId", "7");
      formData.append("answer", JSON.stringify(body));
    } else if (currentStep === 7) {
      const answerData = answer && answer[7];
      const body: any = [
        {
          issueQuestionId: "25",
          answer: answerData.questions[0].answer,
          type: "textField",
          files: [],
          id: answerData?.questions[0]?.beId,
        },
      ];
      formData.append("sectionId", "8");
      formData.append("answer", JSON.stringify(body));
    }

    addNewData(formData, COMMON_ENDPOINT.question, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      if (currentStep === dataLength - 1 && res) {
        dispatch(clearAnswer());
      }
    });
  };
  const handleOnClick = () => {
    submitHandular();
    if (isFilesLength && currentStep !== dataLength - 1) {
      setShowForm(false);
      setCurrentStep((prevState) => prevState + 1);

      return;
    }

    if (currentStep === dataLength - 1) {
      navigate(
        `${ROUTE_RESPONDER}/${location?.state?.id}?issueId=${
          searchParams?.get("issueId") ? searchParams?.get("issueId") : ""
        }${
          searchParams?.get("subIssueId")
            ? `&subIssueId=${searchParams?.get("subIssueId")}`
            : ""
        }`
      );
    }

    if (isFilesLength || currentStep > 0) {
      setShowForm(false);
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  const btnContent = () => {
    if (currentStep === dataLength - 1) {
      return "Submit";
    }

    return (
      <>
        Next
        <Icons.BlackArrowRight />
      </>
    );
  };

  if (showForm || currentStep > 2) {
    return (
      <StyledBtn
        hasBg
        disabled={currentStep === 0 ? !isFilesLength : false}
        onClick={handleOnClick}
      >
        {btnContent()}
      </StyledBtn>
    );
  }

  return <></>;
};
