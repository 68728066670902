/* eslint-disable react-hooks/rules-of-hooks */
import { FC, FormEvent } from "react";
import {
  StyledCheckbox,
  StyledError,
  StyledInputContainer,
} from "./../Input/styled";

interface Icheckbox {
  id: string;
  value?: boolean;
  type?: string;
  error?: boolean | string;
  handleBlur: (e: FormEvent<HTMLInputElement>) => void;
  handleValue: (e: FormEvent<HTMLInputElement>) => void;
}

const checkBox: FC<Icheckbox> = ({
  id,
  type = "checkbox",
  error,
  value,
  handleBlur,
  handleValue,
}) => {
  return (
    <StyledInputContainer>
      <StyledCheckbox
        id={id}
        name={id}
        defaultChecked={value}
        onChange={handleValue}
      />
      {error && <StyledError>{error}</StyledError>}
    </StyledInputContainer>
  );
};

export default checkBox;
