import React, { FC } from "react";
import { Select, SelectContainer, SelectError, SelectLabel } from "./styled";
interface customSelect {
  placeholder: string;
  id?: string;
  value: string;
  error?: string | boolean;
  handleValue: (e: any) => void;
  options: any;
  labelKey: string;
  valueKey: string;
  style?: any;
}

const CustomSelect: FC<customSelect> = ({
  placeholder,
  id,
  error,
  value,
  labelKey,
  valueKey,
  handleValue,
  options,
  style,
}) => {
  return (
    <>
      <SelectContainer>
        <SelectLabel>
          <Select
            id={id}
            // placeholder={placeholder}
            value={value}
            onChange={handleValue}
          >
            <option value={""}>{placeholder}</option>
            {options.map((option: any) => (
              <option key={option[labelKey]} value={option[valueKey]}>
                {option[labelKey]}
              </option>
            ))}
          </Select>
        </SelectLabel>
      </SelectContainer>
      {error && <SelectError>{error}</SelectError>}
    </>
  );
};

export default CustomSelect;
