import { StyledTab } from "./styled";
import { Dispatch, FC, SetStateAction } from "react";

interface ITab {
  icon: JSX.Element;
  active: boolean;
  index: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

export const Tab: FC<ITab> = ({ icon, index, active, setActiveTab }) => {
  return (
    <StyledTab active={active} onClick={() => setActiveTab(index)}>
      {icon}
    </StyledTab>
  );
};
