import * as Yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  createPassword: "",
  repeatPassword: "",
  firmName: "",
  lawyer: false,
};

export const validationSchema = Yup.object({
  firstName: Yup.string().required("Input your first name"),
  lastName: Yup.string().required("Input your last name"),
  email: Yup.string().required("Input your email").email("Invalid email"),
  lawyer: Yup.boolean().optional(),
  firmName: Yup.string().required("Input your firm name"),
  createPassword: Yup.string()
    .required("Input your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must have 8+ characters, including at least one number, lowercase and uppercase letter, and special character"
    ),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("createPassword")], "Passwords do not match")
    .required("Repeat your password"),
});
