import styled, { css } from "styled-components";

import WavesBg from "src/assets/images/WavesBg.svg";

export const StyledContainer = styled.div<{ hasBg: boolean }>`
  height: 100%;
  overflow: auto;

  ${({ hasBg }) =>
    hasBg
      ? css`
          background: #00050d url(${WavesBg}) no-repeat center / 100% 70%;
        `
      : css`
          background: #070d12;
        `};

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;
