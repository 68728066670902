import styled, { css, keyframes } from "styled-components";

const slideOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const StyledAccordionWrapper = styled.div`
  border-radius: 5px;
  margin: 0 auto;
  width: 95%;
`;

export const StyledAccordionHeader = styled.div<{ isOpen: boolean }>`
  background: ${({ isOpen }) => (isOpen ? "#edfdf5" : "none")};
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;

  &::before {
    content: "${({ isOpen }) => (isOpen ? "-" : "+")}";
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid black;
    text-align: center;
    line-height: ${({ isOpen }) => (isOpen ? "9px" : "12px")};
  }
`;

export const StyledAccordionContent = styled.div<{ isOpen: boolean }>`
  padding: ${({ isOpen }) => (isOpen ? "10px 20px" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  height: ${({ isOpen }) => (isOpen ? "auto" : "0")};
  margin-left: 17px;
  border-left: 1px dashed #111;
  transition: all 0.1s ease-in-out;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      animation: ${slideOutRight} 0.2s ease-in-out forwards;
    `}

  .cross_icon {
    &::before {
      content: "✖";
      color: #ccc;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 14px;
      margin-right: 6px;
    }
  }
`;
