import styled from "styled-components";

export const StyledText = styled.span<{
  size?: number;
  color?: string;
  padding?: string;
  family?: string;
  weight?: number;
  textAlign?: string;
  cursor?: string;
  lineHeight?: number;
  margin?: string;
}>`
  display: block;
  font-size: ${({ size }) => `${size || 16}px`};
  padding: ${({ padding }) => padding ?? 0};
  color: ${({ color }) => color || "#fff"};
  font-family: ${({ family }) => family || "LibreFranklin"};
  font-weight: ${({ weight }) => weight || "400"};
  text-align: ${({ textAlign }) => textAlign || "center"};
  cursor: ${({ cursor }) => cursor || "default"};
  margin: ${({ margin }: any) => margin ?? 0};
  line-height: ${({ lineHeight }) =>
    lineHeight ? `${lineHeight}px` : "normal"};

  & > a {
    color: #0080ff;
  }
`;

