import { FC, ReactNode, useState } from "react";

import {
  StyledCheckbox,
  StyledFormWrap2,
  StyledLabelWrap,
} from "../Input/styled";
import { StyledButton } from "../Button/styled";
import { StyledText } from "../Text/styled";
import { useNavigate } from "react-router-dom";
import { ROUTE_FORGOT, ROUTE_SIGN_IN, ROUTE_SIGN_UP } from "../../routes";
// import { getAllListData } from "src/services/commonServices";
// import { COMMON_ENDPOINT } from "src/utils/Constant";
// import axios from "axios";

interface IForm {
  type: "sign-in" | "sign-up";
  isFixed?: boolean;
  onSubmit?: () => void;
  children: ReactNode;
}

interface Icheckbox {
  agree: boolean;
  remember: boolean;
}

const Form: FC<IForm> = ({ type, isFixed, onSubmit, children }) => {
  const isSignIn = type === "sign-in";
  const text = `Sign ${isSignIn ? "in" : "up"}`;
  const navigate = useNavigate();
  const [CheckBoxVal, setCheckBoxVal] = useState<Icheckbox>({
    agree: false,
    remember: false,
  });

  const handleClick = () => {
    if (isSignIn) {
      return navigate(ROUTE_SIGN_UP);
    }

    return navigate(ROUTE_SIGN_IN);
  };
  const Checkboxclick: () => void = () => {
    !isSignIn
      ? setCheckBoxVal((p: Icheckbox) => ({
          ...p,
          agree: !p.agree,
        }))
      : setCheckBoxVal((p: Icheckbox) => ({
          ...p,
          remember: !p.remember,
        }));
  };
  // const apiCheck = async () => {
  //   getAllListData(`${COMMON_ENDPOINT.UploadedDoc}/external-api-check`)
  //     .then((resp) => {
  //       console.log(resp);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const mlApi = async () => {
  //   await axios
  //     .get(
  //       "http://ec2-3-86-99-75.compute-1.amazonaws.com/search_engine/health",
  //       {
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((resp) => {
  //       console.log(resp);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <StyledFormWrap2 isSignin={isSignIn}>
      <form onSubmit={onSubmit}>
        <StyledText size={36} weight={600} family="DMSans" padding="0 0 48px">
          {text}
        </StyledText>
        {children}
        <label>
          {isSignIn ? (
            <StyledLabelWrap>
              <div>""</div>
              {/* <StyledText family="Inter">Remember me</StyledText> */}
              <StyledButton
                weight={700}
                type="button"
                onClick={() => navigate(ROUTE_FORGOT)}
              >
                Forgot password?
              </StyledButton>
            </StyledLabelWrap>
          ) : (
            <>
              <StyledCheckbox onClick={Checkboxclick} />
              <StyledText family="Inter" weight={500}>
                You agree to our friendly{" "}
                <a href="/privacy-policy">Privacy Policy.</a>
              </StyledText>
            </>
          )}
        </label>
        {/* <button onClick={apiCheck}>RFE external check</button>
        <div>
          <button onClick={mlApi}>ml status api</button>
        </div> */}

        <StyledButton
          type="submit"
          hasBg
          className=""
          disabled={!isSignIn ? !CheckBoxVal.agree : false}
        >
          {text}
        </StyledButton>
        <span>
          <StyledText padding="16px 0" family="Inter">
            {isSignIn ? "Don’t have an account?" : "Already have an account?"}{" "}
            <StyledButton weight={700} onClick={handleClick} label={true}>
              {`Sign ${isSignIn ? "Up" : "In"}`}
            </StyledButton>
          </StyledText>
        </span>
      </form>
    </StyledFormWrap2>
  );
};

export default Form;
