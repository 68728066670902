import { FC, useState } from "react";
import Modal from "../Modal";
// import { useAppDispatch } from "src/hooks";
// import { handleEdit, handleRemove } from "./helpers";
import { DocType } from "src/pages/Responder/Document/Document";
import { StyledText } from "../Text/styled";
import { StyledBtnsWrap, StyledInput } from "src/pages/Responder/styled";
import { toast } from "react-toastify";
import { deleteData, updateData } from "src/services/commonServices";
import { alertOptions } from "src/utils/helper";
import { useParams } from "react-router";
import { COMMON_ENDPOINT } from "src/utils/Constant";

interface IHandleDocModal {
  operationType?: "edit" | "remove";
  docType: DocType;
  name: string;
  ids: string;
  handleClose: () => void;
  getData?: any;
  extension?: string;
  tabType?: string;
  isHeader?: boolean;
  isChild?: boolean;
  exhibitChildId?: string;
  documentType?: string;
}

const HandleDocModal: FC<IHandleDocModal> = ({
  operationType,
  documentType,
  name,
  ids,
  handleClose,
  getData,
  extension,
  tabType,
  isHeader,
  isChild,
  exhibitChildId,
}) => {
  // const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);
  const { id } = useParams();
  const isEdit = operationType === "edit";
  const handleEdit = (ids: any, value: any, exe: any) => {
    updateData(
      "",
      {
        fileId: ids,
        uploadId: id,
        fileName: value.concat(`.${exe}`),
      },
      COMMON_ENDPOINT.assembleReName
    )
      .then((res: any) => {
        getData();
        toast.success("File name updated successfully", alertOptions);
      })
      .catch((e) => {
        // setLoading(false);
      });
  };
  const handleRemove = () => {
    deleteData(
      "",
      `${COMMON_ENDPOINT.assembleGet}?id=${
        isHeader ? exhibitChildId : ids
      }&type=${tabType?.toLowerCase()}&documentType=${
        documentType === "header" ? "header" : "doc"
      }&isHeader=${isHeader}&isChild=${isChild ? isChild : false}`
    )
      .then((res: any) => {
        getData();
        toast.success("File deleted successfully", alertOptions);
      })
      .catch((e) => {
        // setLoading(false);
      });
  };
  return (
    <Modal handleClose={handleClose}>
      <StyledText size={18} textAlign="left" padding="0 0 24px">{`${
        isEdit
          ? "Edit file name"
          : `Are you sure do you want to delete this file ?`
      }`}</StyledText>
      {isEdit && (
        <StyledInput
          value={value.split(".")[0]}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      )}
      <StyledBtnsWrap justifyContent="center">
        <button className="btn_cancel" onClick={handleClose}>
          <StyledText color="#111" cursor="pointer">
            Cancel
          </StyledText>
        </button>
        <button
          className="btn_edit"
          onClick={() => {
            handleClose();
            return isEdit ? handleEdit(ids, value, extension) : handleRemove();
          }}
        >
          <StyledText color="#111" cursor="pointer">
            {isEdit ? "Save" : "Delete"}
          </StyledText>
        </button>
      </StyledBtnsWrap>
    </Modal>
  );
};

export default HandleDocModal;
