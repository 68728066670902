import styled, { css } from "styled-components";


export const StyledCommunityBar = styled.div`
  width: 13.5vw;
  padding: 24px 16px;
  // position: fixed;
  // top: 88px;
  // right: 2.1vw;
  background: #162632;
  border-top: 1px solid #1F323F;
  border-left: 1px solid #1F323F;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 88px);
  overflow-y: scroll;
  z-index: 1;
`;

export const StyledCommunityItem = styled.div`
  position: relative;
  width: 100%;
  padding: 8px 14px;
  background: #1F323F;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
`;

export const StyledCopy = styled.div`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: #2B404F;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s linear;

  &:hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
`;

export const StyledAuthor = styled.div`
  font-family: LibreFranklin, sans-serif;
  max-width: 150px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`;

export const StyledCompany = styled.div`
  font-family: LibreFranklin, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0080FF;
`;

export const StyledContent = styled.div`
  font-family: LibreFranklin, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #FFFFFF;
  margin-top: 8px;
`;

export const StyledPreview = styled.div<{ hide?: boolean }>`
  ${({ hide }) =>
    hide ? css`
      display: none;
    ` : css`
      display: block;`
  };
`;

export const StyledFullContent = styled.div<{ active?: boolean }>`
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  ${({ active }) =>
    active ? css`
      display: block;
      max-height: max-content;
      //transition: max-height 1s ease-in-out;
    ` : css`
      display: none;
      max-height: 0;`
  };
`;

export const StyledShowMore = styled.div<{ active?: boolean }>`
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  svg {
    margin-top: 2px;
    transition: all .3s ease;
  }

  ${({ active }) =>
    active ? css`
      svg {
          transform: rotate(180deg);
        }
      `
      :
      css`
        svg {
          transform: rotate(0deg);
        }
      `
  };
`;

export const StyledRating = styled.div`
  margin-top: 28px;
  display: flex;
  gap: 6px;

  div {
    cursor: pointer;
    font-family: LibreFranklin, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    display: flex;
    gap: 9px;
    align-items: center;
    padding: 6px 8px;
    background: #2B404F;
    border-radius: 4px;
    transition: all .3s ease;

    svg {
      path {
        transition: all .3s ease;
      }
    }
  }
`;

export const StyledPositive = styled.div<{ liked?: boolean }>`
  border: 1px solid #4CAF50;
  ${({ liked }) =>
    liked ? css`
      background: #00C853 !important;

      svg {
        path {
          fill: #fff !important;
        }
      }
      `
      :
      css`
        svg {
          path {
            fill: #4CAF50 !important;
          }
        }
      `
  };
`;

export const StyledNegative = styled.div<{ disliked?: boolean }>`
  border: 1px solid #D32F2F;
  ${({ disliked }) =>
    disliked ? css`
      background: #FF1744 !important;
      svg {
        path {
          fill: #fff !important;
        }
      }
      `
      :
      css`
        svg {
          path {
            fill: #FF1744 !important;
          }
        }
      `
  };
`;

